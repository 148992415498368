export default {
    VE001: 'Checksum Failed',
    VE002: 'Card ID value out of range',
    VE003: 'Manufacture Time Offset value out of range',
    VE004: 'Software Version value out of range',
    VE005: 'Device On Time value out of range',
    VE006: 'Sensor 1 Strip Pulloff Timestamp value out of range',
    VE007: 'Sensor 1 Data value out of range',
    VE008: 'Sensor 1 Temperature value out of range',
    VE009: "Test 1 Battery Voltage value out of range'",
    VE010: 'Test 1 Timestamp value out of range',
    VE011: 'Confirm Drink Timestamp value out of range',
    VE012: 'Sensor 2 Stip Pulloff Timestamp value out of range',
    VE013: 'Sensor 2 Data value out of range',
    VE014: 'Sensor 2 Temperature value out of range',
    VE015: 'Test 2 Battery Voltage value out of range',
    VE016: 'Test 2 Timestamp value out of range',
    VE017: 'Maximum Temperature value out of range',
    VE018: 'Minimum Temperature value out of range',
    VE019: 'Minutes Above Minimum Temperature value out of range',
    VE020: 'Minutes Below Maximum Temperature value out of range',
    VE021: 'Current Timestamp value out of range',
    VE022: 'Current Battery Voltage value out of range',
    VE023: 'Error Flags value out of range',
    VE024: 'Finished key invalid',
    VE025: 'Test 1 Cap Time and Test 1 Strip Time Less than 3 Seconds',
    VE026: 'Test 2 Cap Time and Test 2 Strip Time Less than 3 Seconds'
}
