import axios from 'axios'
export default {
    namespaced: true,

    state: {
        practitioners: []
    },

    getters: {
        GetAllPractitioners (state) {
            return state.practitioners
        }
    },

    mutations: {
        PUSH_PRACTITIONERS (state, data) {
            state.practitioners = data
        }
    },

    actions: {
        async GetPractitionersByOrganisation ({ commit }, id) {
            try {
                const response = await axios.get(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/practitioners/${id}`)

                commit('PUSH_PRACTITIONERS', response.data)
            } catch (err) {
                throw new Error(err)
            }
        }
    }
}
