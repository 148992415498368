<template>
    <div>
        <Loader v-if="loading" />

        <div v-else>
            <div class="row align-items-center">
                <div class="col-md-6">
                    <h2 class="mb-0 mt-2">Fulfilment | Overview</h2>
                </div>
                <div class="col-md-6 d-flex flex-column flex-md-row justify-content-end align-items-center">

                    <ToggleScanningComponent
                            buttonTitle="Check package"
                            :uniqueCode="ScanningCodeType.BCP"
                    />

                </div>
            </div>

            <div class="card mt-4">
                <div class="card-body">
                    <h3>Statistics</h3>

                    <p class="mb-0 font-weight-bold mt-4">Today</p>
                    <p class="text-sm">({{ testsStatsToday.dateFrom | moment("MMM D, YYYY") }})</p>

                    <div class="row text-center">
                        <div class="col-lg col-md-4 mb-1 mb-lg-0">
                            <div class="dashboard-card test-complete">
                                <p class="h3 mb-0 font-weight-bold">{{ testsStatsToday.dispatchedOrdersCount }}</p>
                                <p class="mb-0">Orders to dispatch</p>
                            </div>
                        </div>

                        <div class="col-lg col-md-4 mb-1 mb-lg-0">
                            <div class="dashboard-card test-open">
                                <p class="h3 mb-0 font-weight-bold">{{ testsStatsToday.openOrdersCount }}</p>
                                <p class="mb-0">Orders open</p>
                            </div>
                        </div>

                        <div class="col-lg col-md-4 mb-1 mb-lg-0">
                            <div class="dashboard-card test-alt">
                                <p class="h3 mb-0 font-weight-bold">{{ testsStatsToday.dueOrdersCount }}</p>
                                <p class="mb-0">Orders due</p>
                            </div>
                        </div>

                        <div class="col-lg col-md-4 mb-1 mb-lg-0">
                            <div class="dashboard-card test-alt">
                                <p class="h3 mb-0 font-weight-bold">{{ humanizeDuration(testsStatsToday.aveProcessingTimeInMillis) }}</p>
                                <p class="mb-0">Average processing time</p>
                            </div>
                        </div>

                        <div class="col-lg col-md-4 mb-1 mb-lg-0"></div>
                    </div>

                    <p class="mb-0 font-weight-bold mt-4">This week</p>
                    <p class="text-sm">({{ testsStatsThisWeek.dateFrom | moment("MMM D, YYYY")}} - {{ testsStatsThisWeek.dateTo | moment("MMM D, YYYY")}})</p>

                    <div class="row text-center">
                        <div class="col-lg col-md-4 mb-1 mb-lg-0">
                            <div class="dashboard-card test-complete">
                                <p class="h3 mb-0 font-weight-bold">{{ testsStatsThisWeek.dispatchedOrdersCount }}</p>
                                <p class="mb-0">Orders to dispatch</p>
                            </div>
                        </div>

                        <div class="col-lg col-md-4 mb-1 mb-lg-0">
                            <div class="dashboard-card test-open">
                                <p class="h3 mb-0 font-weight-bold">{{ testsStatsThisWeek.openOrdersCount }}</p>
                                <p class="mb-0">Orders open</p>
                            </div>
                        </div>

                        <div class="col-lg col-md-4 mb-1 mb-lg-0">
                            <div class="dashboard-card test-alt">
                                <p class="h3 mb-0 font-weight-bold">{{ testsStatsThisWeek.dueOrdersCount }}</p>
                                <p class="mb-0">Orders due</p>
                            </div>
                        </div>

                        <div class="col-lg col-md-4 mb-1 mb-lg-0">
                            <div class="dashboard-card test-alt">
                                <p class="h3 mb-0 font-weight-bold">{{ humanizeDuration(testsStatsThisWeek.aveProcessingTimeInMillis) }}</p>
                                <p class="mb-0">Average processing time</p>
                            </div>
                        </div>

                        <div class="col-lg col-md-4 mb-1 mb-lg-0"></div>
                    </div>

                    <p class="mb-0 font-weight-bold mt-4">This month</p>
                    <p class="text-sm">({{ testsStatsThisMonth.dateFrom | moment("MMM D, YYYY")}} - {{ testsStatsThisMonth.dateTo | moment("MMM D, YYYY")}})</p>

                    <div class="row text-center">
                        <div class="col-lg col-md-4 mb-1 mb-lg-0">
                            <div class="dashboard-card test-complete">
                                <p class="h3 mb-0 font-weight-bold">{{ testsStatsThisMonth.dispatchedOrdersCount }}</p>
                                <p class="mb-0">Orders to dispatch</p>
                            </div>
                        </div>

                        <div class="col-lg col-md-4 mb-1 mb-lg-0">
                            <div class="dashboard-card test-alt">
                                <p class="h3 mb-0 font-weight-bold">{{ testsStatsThisMonth.openOrdersCount }}</p>
                                <p class="mb-0">Orders open</p>
                            </div>
                        </div>

                        <div class="col-lg col-md-4 mb-1 mb-lg-0">
                            <div class="dashboard-card test-alt">
                                <p class="h3 mb-0 font-weight-bold">{{ testsStatsThisMonth.dueOrdersCount }}</p>
                                <p class="mb-0">Orders due</p>
                            </div>
                        </div>

                        <div class="col-lg col-md-4 mb-1 mb-lg-0">
                            <div class="dashboard-card test-alt">
                                <p class="h3 mb-0 font-weight-bold">{{ humanizeDuration(testsStatsThisMonth.aveProcessingTimeInMillis) }}</p>
                                <p class="mb-0">Average processing time</p>
                            </div>
                        </div>

                        <div class="col-lg col-md-4 mb-1 mb-lg-0"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import axios from 'axios'
import Loader from '@/partials/Loader.vue'
import ToggleScanningComponent from '@/components/ToggleScanningComponent'
import ScanningCodeType from '@/enums/ScanningCodeType'
export default {
    name: 'FulfilmentOverview',

    components: {
        Loader,
        ToggleScanningComponent
    },

    data () {
        const startOfDay = this.$moment().startOf('day').format('YYYY-MM-DDT00:00:00')
        const endOfDay = this.$moment().utc().endOf('day').format('YYYY-MM-DDT23:59:59')
        const startOfWeek = this.$moment().startOf('week').format('YYYY-MM-DDT00:00:00')
        const endOfWeek = this.$moment().utc().endOf('week').format('YYYY-MM-DDT23:59:59')
        const startOfMonth = this.$moment().startOf('month').format('YYYY-MM-DDT00:00:00')
        const endOfMonth = this.$moment().utc().endOf('month').format('YYYY-MM-DDT23:59:59')

        return {
            loading: true,
            startOfDay: startOfDay,
            endOfDay: endOfDay,
            startOfWeek: startOfWeek,
            endOfWeek: endOfWeek,
            startOfMonth: startOfMonth,
            endOfMonth: endOfMonth,
            testsStatsToday: {},
            testsStatsThisWeek: {},
            testsStatsThisMonth: {},
            ScanningCodeType
        }
    },

    mounted () {
        this.initPage()
    },

    methods: {
        ...mapActions({
            setNotification: 'NotificationStore/SetNotification'
        }),

        async initPage () {
            try {
                this.testsStatsToday = await this.getTestsStatsByDateRange(this.startOfDay, this.endOfDay)
                this.testsStatsThisWeek = await this.getTestsStatsByDateRange(this.startOfWeek, this.endOfWeek)
                this.testsStatsThisMonth = await this.getTestsStatsByDateRange(this.startOfMonth, this.endOfMonth)
            } catch {
                this.setNotification({
                    type: 'error',
                    title: 'Error',
                    message: 'Something went wrong while fetching stats. Please try again.'
                })
            } finally {
                this.loading = false
            }
        },

        async getTestsStatsByDateRange (from, to) {
            try {
                const response = await axios.get(`${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/stats/orders?dateFrom=${from.toString()}&dateTo=${to.toString()}`)

                return response.data
            } catch (err) {
                throw Error(err)
            }
        },

        humanizeDuration (timeInMillisecond) {
            // Moment's duration Humanize option doesn't quite give the output we want, so this is used instead
            var result = ''
            if (timeInMillisecond) {
                if ((result = Math.round(timeInMillisecond / (86400000))) > 0) { // days
                    result = result === 1 ? result + ' Day' : result + ' Days'
                } else if ((result = Math.round(timeInMillisecond / (3600000))) > 0) { // Hours
                    result = result === 1 ? result + ' Hours' : result + ' Hours'
                } else if ((result = Math.round(timeInMillisecond / (60000))) > 0) { // minute
                    result = result === 1 ? result + ' Minute' : result + ' Minutes'
                } else if ((result = Math.round(timeInMillisecond / 1000)) > 0) { // second
                    result = result === 1 ? result + ' Second' : result + ' Seconds'
                } else {
                    result = timeInMillisecond + ' Millisec'
                }
            } else {
                return 'No orders processed'
            }
            return result
        }

    }
}
</script>
