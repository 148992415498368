<template>
  <div id="app">
    <Loader
      v-if="!isUserAuthenticated || loading"
    />

    <template v-else>
      <Header/>

      <main role="main" style="position: relative">
        <div class="container-fluid p-0">
          <div class="row no-gutters">
            <div class="col-lg-2" style="position: fixed; top: 60px; left: 0; z-index: 9999">
              <Sidebar />
            </div>

            <div class="col-lg-10 offset-lg-2 pb-7">
              <div class="p-4">
                <router-view/>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Notifications />
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Loader from '@/partials/Loader.vue'
import Header from '@/partials/site-structure/HeaderComponent.vue'
import Sidebar from '@/partials/site-structure/SidebarComponent.vue'
import Notifications from '@/partials/site-structure/NotificationsComponent.vue'
import RoleType from '@/enums/RoleType'
import GeneralErrorModal from '@/components/modals/GeneralErrorModalComponent.vue'
// import axios from 'axios'
import _ from 'lodash'
export default {
  name: 'App',

  components: {
    Loader,
    Header,
    Sidebar,
    Notifications
  },

  watch: {
    isUserAuthenticated: function (val) {
      if (val === true) {
        this.initApp()
      }
    }
  },

  data () {
    return {
      loading: true,
      RoleType
    }
  },

  async mounted () {
    await this.$msal.signIn()
  },

  computed: {
    ...mapGetters({
      userRoleType: 'UserStore/GetUserRoleType',
      isUserAuthenticated: 'UserStore/GetIsUserAuthenticated',
      getUserDomains: 'UserStore/GetUserDomains',
      getCurrentDomain: 'UserStore/GetUserCurrentDomain',
      allDomains: 'DomainsStore/GetDomains'
    })
  },

  methods: {
    ...mapActions({
      getUserDetails: 'UserStore/GetUserDetails',
      setAppInitLoaded: 'UserStore/SetAppInitLoaded',
      signout: 'UserStore/Signout',
      setUserDomainId: 'UserStore/SetCurrentDomainId',
      populateDomains: 'DomainsStore/PopulateDomains',
      populateLanguages: 'LanguageStore/PopulateLanguages'
    }),

    async initApp () {
      try {
        // Get the actual domain objects from the org service so we know the names etc. of the domains. For admin owners, this will be a list of all domains, even if they are only associated with one domain as a user
        await this.populateDomains()
        await this.populateLanguages()

        // Let's see if we know the currently selected domain, if not, check local storage, and if not, then pick the first of the user's list of allowed domains
        let currentDomain = this.getCurrentDomain
        if (currentDomain == null) {
          currentDomain = localStorage.getItem('currentDigosticsDomain')
          const userDomains = this.getUserDomains
          const allDomains = this.allDomains
          if (currentDomain == null || !this.savedDomainIsValid(currentDomain, userDomains, allDomains)) {
            if (userDomains && userDomains.length > 0) {
              this.setUserDomainId(userDomains[0])
            } else if (allDomains && allDomains.length > 0) {
              // default for domain superusers
              this.setUserDomainId(allDomains[0].id)
            }
          } else {
            this.setUserDomainId(currentDomain)
          }
        }

        await this.getUserDetails()

        this.setAppInitLoaded()
      } catch {
        this.$modal.show(GeneralErrorModal, { message: 'Services are currently down.' })
      } finally {
        this.loading = false
      }
    },

    savedDomainIsValid (currentDomain, userDomains, allDomains) {
      if (userDomains && userDomains.includes(currentDomain)) {
        return true
      }
      if (allDomains && _.find(allDomains, (d) => { return d.id === currentDomain })) {
        return true
      }
      return false
    }
  }
}
</script>
