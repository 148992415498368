<template>
    <div>
        <Loader v-if="loading" />

        <div v-if="!loading && Object.keys(user).length">
            <Breadcrumbs />

            <div class="card text-sm mt-3">
                <div class="card-body">
                    <h5 class="mb-0">User details</h5>

                    <div class="row justify-content-between mt-4">
                        <div class="col-xl-5">
                            <div class="form-group row">
                                <label for="first-name" class="col-sm-4 col-form-label">Title <span class="text-danger">*</span></label>
                                <div class="col-sm-8">
                                    <select type="text" class="custom-select" :class="{'border border-danger' : errors && errors.title}" id="title" v-model="user.title">
                                        <option :value="Salutation.MR">Mr</option>
                                        <option :value="Salutation.MRS">Mrs</option>
                                        <option :value="Salutation.MISS">Miss</option>
                                        <option :value="Salutation.MS">Ms</option>
                                        <option :value="Salutation.DR">Dr</option>
                                        <option :value="Salutation.SIR">Sir</option>
                                        <option :value="Salutation.PROFESSOR">Professor</option>
                                    </select>
                                    <small v-if="errors && errors.title" class="text-danger">
                                        {{ errors.title[0] }}
                                    </small>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="first-name" class="col-sm-4 col-form-label">First Name <span class="text-danger">*</span></label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" v-model="user.firstName">
                                    <small v-if="errors && errors.firstName" class="text-danger">
                                        {{ errors.firstName[0] }}
                                    </small>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="surname" class="col-sm-4 col-form-label">Surname <span class="text-danger">*</span></label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" v-model="user.lastName">
                                    <small v-if="errors && errors.lastName" class="text-danger">
                                        {{ errors.lastName[0] }}
                                    </small>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="email" class="col-sm-4 col-form-label">Email address <span class="text-danger">*</span></label>
                                <div class="col-sm-8">
                                    <input type="email" class="form-control" v-model="user.email" disabled>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="role" class="col-sm-4 col-form-label">Role <span class="text-danger">*</span></label>
                                <div class="col-sm-8">
                                    <select :disabled="user.id === userDetails.id" type="text" class="form-control custom-select" v-model="user.role">
                                        <option :value="RoleType.ADMIN">Admin</option>
                                        <option :value="RoleType.OWNER">Owner</option>
                                        <option :value="RoleType.FULFILMENT">Fulfilment</option>
                                    </select>
                                </div>
                            </div>

                            <div v-if="user.id !== userDetails.id" class="form-group row align-items-center">
                                <label for="fulfilment-provider" class="col-sm-4 col-form-label">Status <span class="text-danger">*</span></label>
                                <div class="col-sm-8">
                                    <b-form-checkbox v-model="user.isActive" name="check-button" switch class="custom-switch-md">
                                        {{ user.isActive ? 'Active' : 'Inactive' }}
                                    </b-form-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="userIsDomainAssigner" class="mt-5">
                <h5>Domains <span class="font-italic">({{ allDomains.length }})</span></h5>
                <p class="text-sm">Select at least one domain to assign to your user</p>
                <b-table
                    ref="table"
                    id="table"
                    class="custom-table padding-lg bg-white mt-4"
                    responsive
                    no-sort-reset
                    :items="allDomains"
                    :fields="filteredFields"
                >
                    <template #cell(action)="data">
                        <button v-if="!user.domainIds.includes(data.item.id)" @click="selectDomain(data.item.id)" class="btn btn-primary sm-shadow text-nowrap">Select</button>
                        <button v-if="user.domainIds.includes(data.item.id)" @click="removeDomain(data.item.id)" class="btn btn-danger sm-shadow text-nowrap">Remove</button>
                    </template>
                </b-table>

                <div v-if="errors && errors.domains" class="mt-4 alert alert-danger text-pre-formatted">{{ errors.domains[0] }}</div>
            </div>

            <div class="mt-5"></div>

            <div class="fixed-bottom bg-white text-right p-3">
                <button :disabled="loading" @click="cancel()" class="btn btn-cancel btn-lg lg-shadow" :class="{disabled : loading}">Cancel</button>
                <button :disabled="loading" @click="save()" class="btn btn-success btn-lg lg-shadow pl-55 pr-55 ml-2" :class="{disabled : loading}">Save changes <span v-if="loading" class="btn-loading"></span></button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import Loader from '@/partials/Loader.vue'
import Breadcrumbs from '@/partials/site-structure/BreadcrumbsComponent'
import Salutation from '@/enums/Salutation'
import RoleType from '@/enums/RoleType'
export default {
    name: 'EditUser',

    components: {
        Loader,
        Breadcrumbs
    },

    data () {
        return {
            loading: true,
            user: {},
            errors: {},
            Salutation,
            RoleType,
            domains: {},
            domainsChanged: false
        }
    },

    async mounted () {
        await this.getUser()
    },

    computed: {
        ...mapGetters({
            userDetails: 'UserStore/GetUserDetails',
            isOwner: 'UserStore/GetIsUserOwner',
            allDomains: 'DomainsStore/GetDomains',
            userIsDomainAssigner: 'UserStore/GetIsUserDomainAssigner'
        }),
        filteredFields () {
            return [
                {
                    key: 'name',
                    label: 'Name',
                    sortable: true
                },
                {
                    key: 'action',
                    label: ''
                }
            ]
        }
    },

    methods: {
        ...mapActions({
            setNotification: 'NotificationStore/SetNotification'
        }),

        cancel () {
            return this.$router.push({ name: 'Users' })
        },

        selectDomain (id) {
            if (!this.user.domainIds.includes(id)) {
                this.user.domainIds.push(id)
                this.domainsChanged = true
            }
        },

        removeDomain (id) {
            if (this.user.domainIds.includes(id)) {
                const index = this.user.domainIds.indexOf(id)
                this.user.domainIds.splice(index, 1)
                this.domainsChanged = true
            }
        },

        async getUser () {
            try {
                const response = await axios.get(`${process.env.VUE_APP_AXIOS_ADMIN_BASE_URL}/api/v1/admins/${this.$route.params.id}`)

                this.user = response.data
            } catch {
                this.setNotification({
                    type: 'error',
                    title: 'Error',
                    message: 'Something went wrong while fetching the user. Please try again.'
                })
            } finally {
                this.loading = false
            }
        },

        async save () {
            this.expandManualAddress = true
            this.checkForm()

            if (!Object.keys(this.errors).length) {
                this.loading = true

                const userModel = {
                    title: this.user.title,
                    firstName: this.user.firstName,
                    lastName: this.user.lastName,
                    isActive: this.user.isActive,
                    role: this.user.role
                }

                try {
                    await axios.patch(`${process.env.VUE_APP_AXIOS_ADMIN_BASE_URL}/api/v1/admins/${this.$route.params.id}`, userModel)

                    if (this.domainsChanged && this.userIsDomainAssigner) {
                        const userDomainsModel = {
                            domainIds: this.user.domainIds
                        }
                        await axios.patch(`${process.env.VUE_APP_AXIOS_ADMIN_BASE_URL}/api/v1/admins/${this.$route.params.id}/assign`, userDomainsModel)
                    }

                    this.$router.push({ name: 'Users' })

                    return this.setNotification({
                        type: 'success',
                        title: 'Success',
                        message: 'The user was updated.'
                    })
                } catch {
                    this.setNotification({
                        type: 'error',
                        title: 'Error',
                        message: 'Something went wrong. The user was not updated.'
                    })
                } finally {
                    this.loading = false
                }
            }
        },

        checkForm () {
            this.errors = {}

            if (this.user.title == null || parseInt(this.user.title) < 0) {
                this.errors.title = ['The title field is required']
            }

            if (!this.user.firstName) {
                this.errors.firstName = ['The first name field is required']
            }

            if (!this.user.lastName) {
                this.errors.lastName = ['The last name field is required']
            }

            if (!this.user.email) {
                this.errors.email = ['The email field is required']
            }

            if (!this.user.domainIds.length) {
                this.errors.domains = ['You must select at least one domain']
            }
        }
    }
}
</script>
