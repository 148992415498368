<template>
    <div>
        <div class="row align-items-center">
            <div class="col-md-3">
                <h2 class="mb-0">Organisations</h2>
            </div>

            <div class="col-md-9 text-right">
                <button v-if="showAddTrainingProvisions && this.AddTrainingProvisionsUrl != null" @click="showAddTrainingOrganisationModal()" class="btn btn-primary btn-block-mobile btn-lg lg-shadow mt-2 mt-md-0 mr-2">Add a Training Organisation</button>
                <button @click="$router.push({name: 'CreateOrganisation'})" class="btn btn-success btn-block-mobile btn-lg lg-shadow mt-2 mt-md-0">Add an Organisation</button>
            </div>
        </div>

        <Loader v-if="loading" />

        <div v-else class="mt-45">
            <div class="row justify-content-between align-items-center">
                <div class="col">
                    <h5 class="mb-0">All <span class="font-italic">({{ results.totalCount }})</span></h5>
                </div>

                <div class="col-md-4 mt-2 mt-md-0">
                    <div class="d-flex flex-column flex-md-row justify-content-end">
                        <input class="form-control searchbar-input" type="text" placeholder="Search organisations" v-model="query" />
                        <button onclick="this.blur();" :disabled="isBusy" @click="search()" class="btn btn-success btn-form sm-shadow ml-0 ml-md-2 my-2 my-md-0" :class="{disabled : isBusy}">Search</button>
                        <button onclick="this.blur();" @click="resetSearch()" class="btn btn-yellow btn-form sm-shadow ml-0 ml-md-2" :class="{disabled : isBusy}">Reset</button>
                    </div>
                </div>
            </div>

            <b-table
                v-if="results.results.length"
                ref="table"
                id="table"
                :sort-by.sync="sortBySync"
                :sort-desc.sync="sortDesc"
                @sort-changed="sortingChanged"
                :no-local-sorting="true"
                :busy.sync="isBusy"
                class="custom-table padding-lg bg-white mt-4"
                responsive
                no-sort-reset
                :items="results.results"
                :fields="filteredFields"
            >
                <template #cell(name)="data">
                    <router-link class="font-weight-bold underlined text-capitalize" :to="{name: 'ViewOrganisation', params: {id : data.item.id}}">{{ data.item.name }}</router-link>
                </template>

                <template #cell(isActive)="data">
                    <span :class="{'text-success' : data.item.isActive}">{{ data.item.isActive ? "ACTIVE" : "INACTIVE" }}</span>
                </template>
            </b-table>

            <div class="mt-4 d-flex flex-column flex-md-row align-items-center justify-content-end">
                <div class="form-group d-flex align-items-center mb-4 mb-md-0">
                    <label class="flex-shrink-0 mr-2">Items per page</label>
                    <select class="custom-select" v-model="pageSize" @change="handleChangePageSize()">
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                    </select>
                </div>

                <div>
                    <b-pagination
                        v-if="results.results.length"
                        v-model="currentPage"
                        :current-page="currentPage"
                        :per-page="results.pageSize"
                        :total-rows="results.totalCount"
                        prev-text="< Previous"
                        next-text="Next >"
                        @change="pageChange"
                        aria-controls="tests-table"
                        hide-goto-end-buttons
                        prev-class="prev-page"
                        next-class="next-page"
                        align="right"
                        class="mb-0"
                    ></b-pagination>
                </div>
            </div>
        </div>

        <div v-if="!loading && !results.results.length">
            <div class="row mt-45">
                <div class="col-md-6">
                    <div class="alert alert-info">
                        There are no organisations to show
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import Loader from '@/partials/Loader.vue'
import FormatterMixin from '@/mixins/FormatterMixin'
import AddTrainingOrganisationModalComponent from '@/components/modals/AddTrainingOrganisationModalComponent'

export default {
    name: 'Organisations',

    components: {
        Loader
    },

    mixins: [
        FormatterMixin
    ],

    data () {
        return {
            loading: true,
            results: {},
            query: '',
            isBusy: false,
            currentPage: 1,
            pageSize: process.env.VUE_APP_PAGINATION_PAGE_SIZE,
            offset: 0,
            sortColumn: 'NAME',
            sortType: 'ASCENDING',
            sortDesc: false,
            sortBySync: 'name',

            showAddTrainingProvisions: process.env.VUE_APP_SHOW_TRAINING_PROVISIONING ? JSON.parse(process.env.VUE_APP_SHOW_TRAINING_PROVISIONING.toLowerCase()) : false,
            AddTrainingProvisionsUrl: process.env.VUE_APP_AXIOS_PROVISIONING_BASE_URL ?? null
        }
    },

    async mounted () {
        await this.getOrganisations()
    },

    computed: {
        ...mapGetters({
            userIsOwner: 'UserStore/GetIsUserOwner'
        }),

        filteredFields () {
            return [
                {
                    key: 'name',
                    label: 'Name',
                    sortable: true
                },

                {
                    key: 'facilitiesCount',
                    label: 'Facilities',
                    formatter: 'convertNullToInt',
                    sortable: true
                },

                {
                    key: 'practitionersCount',
                    label: 'HCPS',
                    formatter: 'convertNullToInt',
                    sortable: true
                },

                {
                    key: 'patientsCount',
                    label: 'Patients',
                    formatter: 'convertNullToInt',
                    sortable: true
                },

                {
                    key: 'testsIssuedCount',
                    label: 'Tests Issued',
                    formatter: 'convertNullToInt',
                    sortable: true
                },

                {
                    key: 'testsCompleteCount',
                    label: 'Tests Complete',
                    formatter: 'convertNullToInt',
                    sortable: true
                },

                {
                    key: 'isActive',
                    label: 'Status',
                    sortable: true
                }
            ]
        }
    },

    methods: {
        ...mapActions({
            setNotification: 'NotificationStore/SetNotification'
        }),

        async getOrganisations () {
            this.isBusy = true

            try {
                var response
                if (this.query) {
                    response = await axios.get(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/organisations/${this.query}/${this.pageSize}/${this.offset}/${this.sortColumn}/${this.sortType}`)
                } else {
                    response = await axios.get(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/organisations/${this.pageSize}/${this.offset}/${this.sortColumn}/${this.sortType}`)
                }

                this.results = response.data
            } catch {
                this.setNotification({
                    type: 'error',
                    title: 'Error',
                    message: 'Something went wrong while fetching the organisations. Please try again.'
                })
            } finally {
                this.loading = false
                this.isBusy = false
            }
        },

        async resetSearch () {
            this.query = ''
            await this.search()
        },

        async search () {
            this.currentPage = 1
            this.offset = 0
            await this.getOrganisations()
        },

        pageChange (value) {
            this.currentPage = value
            this.offset = (value - 1) * this.pageSize
            this.getOrganisations()
        },

        sortingChanged (e) {
            switch (e.sortBy) {
                case 'name':
                    this.sortColumn = 'NAME'
                break

                case 'facilitiesCount':
                    this.sortColumn = 'FACILITIES_COUNT'
                break

                case 'practitionersCount':
                    this.sortColumn = 'PRACTITIONERS_COUNT'
                break

                case 'patientsCount':
                    this.sortColumn = 'PATIENTS_COUNT'
                break

                case 'testsIssuedCount':
                    this.sortColumn = 'TESTS_ISSUED'
                break

                case 'testsCompleteCount':
                    this.sortColumn = 'TESTS_COMPLETE'
                break

                case 'isActive':
                    this.sortColumn = 'STATUS'
                break
            }

            this.sortDesc = e.sortDesc
            this.sortBySync = e.sortBy

            if (this.sortDesc) {
                this.sortType = 'DESCENDING'
            } else {
                this.sortType = 'ASCENDING'
            }

            this.getOrganisations()
        },

        handleChangePageSize () {
            this.currentPage = 1
            this.offset = 0

            this.getOrganisations()
        },

        showAddTrainingOrganisationModal () {
            if (this.showAddTrainingProvisions && this.AddTrainingProvisionsUrl != null) {
                this.$modal.show(AddTrainingOrganisationModalComponent, {}, { width: '440px' })
            }
        }
    }
}
</script>
