<template>
    <div>
        <Loader v-if="loading" />

        <div v-if="!loading && Object.keys(facility).length">
            <Breadcrumbs
                :dynamicTitle="facility.name"
            />

            <div class="card mt-3 text-sm">
                <div class="card-body">
                    <div class="d-flex flex-column flex-md-row justify-content-between">
                        <h5 class="mb-0">Facility details</h5>
                        <div class="d-flex justify-content-end flex-column">
                            <button :disabled="loading" @click="save()" class="btn btn-success sm-shadow mt-4 mt-md-0 px-4" :class="{disabled : loading}">Save changes</button>
                            <span v-if="Object.keys(errors).length" class="text-sm d-block text-danger mt-2">There are errors on the page. Please review them and try again</span>
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col-xl-5">
                            <div class="form-group row">
                                <label for="name" class="col-sm-4 col-form-label">Name <span class="text-danger">*</span></label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" v-model="facility.name">
                                    <small v-if="errors && errors.facilityName" class="text-danger">
                                        {{ errors.facilityName[0] }}
                                    </small>
                                </div>
                            </div>

                            <div v-if="facility.countryCode == 'GB'" class="form-group row">
                                <label for="autocomplete" class="col-sm-4 col-form-label">Find Address</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" @input="findAddress()" v-model="addressQueryString" placeholder="Search for address" autocomplete="new-password" />
                                    <ul v-if="autocompleteSuggestions.length" class="list-group autocomplete-suggestions">
                                        <li v-for="(suggestion, index) in autocompleteSuggestions" :key="index" class="list-group-item" @click="chooseSuggestion(suggestion.id)">{{ suggestion.address }}</li>
                                    </ul>
                                </div>
                            </div>

                            <div v-if="expandManualAddress">
                                <div class="form-group row">
                                    <label for="address1" class="col-sm-4 col-form-label">Address 1 <span class="text-danger">*</span></label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" :class="{'border border-danger' : errors && errors.facilityAddress1}" id="address1" v-model="facility.address1">
                                        <small v-if="errors && errors.facilityAddress1" class="text-danger">
                                            {{ errors.facilityAddress1[0] }}
                                        </small>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="address2" class="col-sm-4 col-form-label">Address 2 <span class="text-danger">*</span></label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" :class="{'border border-danger' : errors && errors.facilityAddress2}" id="address2" v-model="facility.address2">
                                        <small v-if="errors && errors.facilityAddress2" class="text-danger">
                                            {{ errors.facilityAddress2[0] }}
                                        </small>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="address3" class="col-sm-4 col-form-label">Address 3</label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" :class="{'border border-danger' : errors && errors.facilityAddress3}" id="address3" v-model="facility.address3">
                                        <small v-if="errors && errors.facilityAddress3" class="text-danger">
                                            {{ errors.facilityAddress3[0] }}
                                        </small>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label v-if="facility.countryCode == 'GB'" for="address4" class="col-sm-4 col-form-label">Address 4</label>
                                    <label v-if="facility.countryCode == 'US'" for="address4" class="col-sm-4 col-form-label">State </label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" :class="{'border border-danger' : errors && errors.facilityAddress4}" id="address4" v-model="facility.address4">
                                        <small v-if="errors && errors.facilityAddress4" class="text-danger">
                                            {{ errors.facilityAddress4[0] }}
                                        </small>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label v-if="facility.countryCode == 'GB'" for="postalCode" class="col-sm-4 col-form-label">Postcode <span class="text-danger">*</span></label>
                                    <label v-if="facility.countryCode == 'US'" for="postalCode" class="col-sm-4 col-form-label">Zip Code <span class="text-danger">*</span></label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" :class="{'border border-danger' : errors && errors.facilityPostalCode}" id="postalCode" v-model="facility.postalCode">
                                        <small v-if="errors && errors.facilityPostalCode" class="text-danger">
                                            {{ errors.facilityPostalCode[0] }}
                                        </small>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="country" class="col-sm-4 col-form-label">Country <span class="text-danger">*</span></label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" :class="{'border border-danger' : errors && errors.facilityCountry}" id="country" v-model="facility.country">
                                        <small v-if="errors && errors.facilityCountry" class="text-danger">
                                            {{ errors.facilityCountry[0] }}
                                        </small>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="country-code" class="col-sm-4 col-form-label">Country Code <span class="text-danger">*</span></label>
                                    <div class="col-sm-8">
                                        <input disabled type="text" class="form-control" :class="{'border border-danger' : errors && errors.facilityCountryCode}" id="country-code" v-model="facility.countryCode">
                                        <small v-if="errors && errors.facilityCountryCode" class="text-danger">
                                            {{ errors.facilityCountryCode[0] }}
                                        </small>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="fulfilment-provider" class="col-sm-4 col-form-label">Fulfilment provider <span class="text-danger">*</span></label>
                                <div class="col-sm-8">
                                    <select type="text" class="custom-select" v-model="facility.fulfilmentProvider">
                                        <option value="0">Digostics</option>
                                    </select>
                                    <small v-if="errors && errors.facilityFulfilmentProvider" class="text-danger">
                                        {{ errors.facilityFulfilmentProvider[0] }}
                                    </small>
                                </div>
                            </div>

                            <div class="form-group row align-items-center">
                                <label for="status" class="col-sm-4 col-form-label">Status <span class="text-danger">*</span></label>
                                <div class="col-sm-8">
                                    <b-form-checkbox v-model="facility.isActive" name="check-button" switch class="custom-switch-md">
                                        {{ facility.isActive ? 'Active' : 'Inactive' }}
                                    </b-form-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h5 class="mb-0 mt-5">Primary contact</h5>

                    <div class="row mt-4">
                        <div class="col-xl-5">
                            <div class="form-group row">
                                <label for="title" class="col-sm-4 col-form-label">Title <span class="text-danger">*</span></label>
                                <div class="col-sm-8">
                                    <select type="text" class="custom-select" v-model="facility.title">
                                        <option :value="Salutation.MR">Mr</option>
                                        <option :value="Salutation.MRS">Mrs</option>
                                        <option :value="Salutation.MISS">Miss</option>
                                        <option :value="Salutation.MS">Ms</option>
                                        <option :value="Salutation.DR">Dr</option>
                                        <option :value="Salutation.SIR">Sir</option>
                                        <option :value="Salutation.PROFESSOR">Professor</option>
                                    </select>
                                    <small v-if="errors && errors.title" class="text-danger">
                                        {{ errors.title[0] }}
                                    </small>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="first-name" class="col-sm-4 col-form-label">First name <span class="text-danger">*</span></label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" v-model="facility.firstName">
                                    <small v-if="errors && errors.firstName" class="text-danger">
                                        {{ errors.firstName[0] }}
                                    </small>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="middle-names" class="col-sm-4 col-form-label">Middle names</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" v-model="facility.middleNames">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="surname" class="col-sm-4 col-form-label">Surname <span class="text-danger">*</span></label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" v-model="facility.surname">
                                    <small v-if="errors && errors.surname" class="text-danger">
                                        {{ errors.surname[0] }}
                                    </small>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="phone" class="col-sm-4 col-form-label">Phone number <span class="text-danger">*</span></label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" v-model="facility.phone">
                                    <small v-if="errors && errors.phone" class="text-danger">
                                        {{ errors.phone[0] }}
                                    </small>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="email" class="col-sm-4 col-form-label">Email address <span class="text-danger">*</span></label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" v-model="facility.email">
                                    <small v-if="errors && errors.email" class="text-danger">
                                        {{ errors.email[0] }}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!loading && results.results.length" class="row mt-5">
            <div class="col">
                <h5>Health Care Professionals <span class="font-italic">({{ results.totalCount }})</span></h5>

                <b-table
                    ref="table"
                    id="table"
                    :sort-by.sync="sortBySync"
                    :sort-desc.sync="sortDesc"
                    @sort-changed="sortingChanged"
                    :no-local-sorting="true"
                    :busy.sync="isBusy"
                    class="custom-table padding-lg bg-white mt-4"
                    responsive
                    no-sort-reset
                    :items="results.results"
                    :fields="filteredFields"
                >
                    <template #cell(name)="data">
                        <router-link class="font-weight-bold underlined text-capitalize" :to="{name: 'ViewHcp', params: {oId: $route.params.oId, pId: data.item.id }}">{{ `${data.item.firstName} ${data.item.surname}` }}</router-link>
                    </template>

                    <template #cell(testsIssuedCount)="data">
                        {{ data.item.testsIssuedCount ? data.item.testsIssuedCount : 0 }}
                    </template>

                    <template #cell(testsCompleteCount)="data">
                        {{ data.item.testsCompleteCount ? data.item.testsCompleteCount : 0 }}
                    </template>

                    <template #cell(isActive)="data">
                        <span :class="{'text-success' : data.item.isActive, 'text-danger' : !data.item.isActive}">{{ data.item.isActive ? 'ACTIVE' : 'INACTIVE' }}</span>
                    </template>

                    <template #cell(setTraining)="data">
                            <button :disabled="loading" @click="setTraining(data.item.id)" class="btn btn-success sm-shadow mt-4 mt-md-0 px-4" :class="{disabled : loading}">Set Training</button>
                        </template>

                </b-table>

                <div class="mt-4">
                    <b-pagination
                        v-if="results.results.length"
                        v-model="currentPage"
                        :current-page="currentPage"
                        :per-page="results.pageSize"
                        :total-rows="results.totalCount"
                        prev-text="< Previous"
                        next-text="Next >"
                        @change="pageChange"
                        aria-controls="tests-table"
                        hide-goto-end-buttons
                        prev-class="prev-page"
                        next-class="next-page"
                        align="right"
                    ></b-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mapActions } from 'vuex'
import Breadcrumbs from '@/partials/site-structure/BreadcrumbsComponent'
import Loader from '@/partials/Loader'
import Salutation from '@/enums/Salutation'
import FormatterMixin from '@/mixins/FormatterMixin'
import TrainingVersion from '@/enums/TrainingVersion'
export default {
    name: 'EditFacility',

    components: {
        Loader,
        Breadcrumbs
    },

    mixins: [
        FormatterMixin
    ],

    props: {
        organisationName: String
    },

    data () {
        return {
            loading: true,
            expandManualUkAddress: false,
            expandManualUsAddress: false,
            addressQueryString: null,
            autocompleteSuggestions: [],
            facility: {},
            expandManualAddress: true,
            errors: {},
            results: {},
            isBusy: false,
            currentPage: 1,
            pageSize: process.env.VUE_APP_PAGINATION_PAGE_SIZE,
            offset: 0,
            sortColumn: 'NAME',
            sortType: 'ASCENDING',
            sortDesc: false,
            sortBySync: 'name',
            Salutation
        }
    },

    async mounted () {
        try {
            await this.getFacility()
            await this.getHcps()
        } catch {
            this.setNotification({
                type: 'error',
                title: 'Error',
                message: 'Something went wrong while fetching the data. Please try again.'
            })
        } finally {
            this.loading = false
        }
    },

    computed: {
        filteredFields () {
            return [
                {
                    key: 'name',
                    label: 'Name',
                    sortable: true
                },
                {
                    key: 'testsIssuedCount',
                    label: 'Tests Issued',
                    variant: 'text-wrap w-15',
                    sortable: true
                },
                {
                    key: 'testsCompleteCount',
                    label: 'Tests Complete',
                    variant: 'text-wrap w-15',
                    sortable: true
                },
                {
                    key: 'isActive',
                    label: 'Status',
                    sortable: true
                },
                {
                    key: 'trainingVersionCompleted',
                    label: 'Training Version',
                    variant: 'text-wrap w-10',
                    sortable: true
                },
                {
                    key: 'trainingCompletedDateTime',
                    label: 'Training Completed Date',
                    variant: 'text-wrap w-15',
                    formatter: 'formatDateTime',
                    sortable: true
                },
                {
                    key: 'setTraining',
                    variant: 'text-wrap w-15',
                    label: ''
                }
            ]
        }
    },

    methods: {
        ...mapActions({
            setNotification: 'NotificationStore/SetNotification'
        }),

        async getFacility () {
            try {
                const response = await axios.get(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/facilities/${this.$route.params.oId}/${this.$route.params.fId}`)

                this.facility = response.data
            } catch (err) {
                throw new Error(err)
            }
        },

        async getHcps () {
            this.isBusy = true

            try {
                const response = await axios.get(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/practitioners/facility/${this.$route.params.oId}/${this.$route.params.fId}/${this.pageSize}/${this.offset}/${this.sortColumn}/${this.sortType}`)

                this.results = response.data
            } catch (err) {
                throw new Error(err)
            } finally {
                this.isBusy = false
            }
        },

        async findAddress () {
            const response = await axios.get(`https://api.getAddress.io/autocomplete/${this.addressQueryString}?api-key=${process.env.VUE_APP_GET_ADDRESS_API_KEY}`)

            this.autocompleteSuggestions = response.data.suggestions
        },

        async chooseSuggestion (id) {
            this.clearAddress()

            const response = await axios.get(`https://api.getAddress.io/get/${id}?api-key=${process.env.VUE_APP_GET_ADDRESS_API_KEY}`)

            if (response.data) {
                this.autocompleteSuggestions = []
                this.addressQueryString = null
                this.expandManualUkAddress = true
                this.facility.address1 = response.data.line_1
                this.facility.address2 = response.data.town_or_city
                this.facility.address3 = response.data.county
                this.facility.postalCode = response.data.postcode
                this.facility.country = response.data.country
                this.facility.latitude = response.data.latitude
                this.facility.longitude = response.data.longitude
            }
        },

        clearAddress () {
            this.facility.address1 = null
            this.facility.address2 = null
            this.facility.address3 = null
            this.facility.postalCode = null
            this.facility.country = null
            this.facility.latitude = null
            this.facility.longitude = null
        },

        pageChange (value) {
            this.currentPage = value
            this.offset = (value - 1) * this.pageSize
            this.getHcps()
        },

        sortingChanged (e) {
            switch (e.sortBy) {
                case 'name':
                    this.sortColumn = 'NAME'
                break

                case 'testsIssuedCount':
                    this.sortColumn = 'TESTS_ISSUED'
                break

                case 'testsCompleteCount':
                    this.sortColumn = 'TESTS_COMPLETE'
                break

                case 'isActive':
                    this.sortColumn = 'STATUS'
                break

                case 'trainingVersionCompleted':
                    this.sortColumn = 'TRAININGVERSIONCOMPLETED'
                break

                case 'trainingCompletedDateTime':
                    this.sortColumn = 'TRAININGCOMPLETEDDATETIME'
                break
            }

            this.sortDesc = e.sortDesc
            this.sortBySync = e.sortBy

            if (this.sortDesc) {
                this.sortType = 'DESCENDING'
            } else {
                this.sortType = 'ASCENDING'
            }

            this.getHcps()
        },

        async save () {
            this.expandManualAddress = true
            this.checkForm()

            if (!Object.keys(this.errors).length) {
                this.loading = true

                const facilityModel = {
                    organisationName: this.facility.organisationName,
                    name: this.facility.name,
                    fulfilmentProvider: this.facility.fulfimentProvider,
                    isActive: this.facility.isActive,
                    address1: this.facility.address1,
                    address2: this.facility.address2,
                    ddress3: this.facility.address3 ?? null,
                    address4: this.facility.address4 ?? null,
                    postalCode: this.facility.postalCode,
                    country: this.facility.country,
                    countryCode: this.facility.countryCode,
                    latitude: this.facility.latitude ?? 0,
                    longitude: this.facility.longitude ?? 0,
                    title: this.facility.title,
                    firstName: this.facility.firstName,
                    middleNames: this.facility.middleNames,
                    surname: this.facility.surname,
                    phone: this.facility.phone,
                    email: this.facility.email
                }

                try {
                    await axios.patch(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/facilities/${this.$route.params.oId}/${this.$route.params.fId}`, facilityModel)

                    this.$router.push({ name: 'ViewFacility', params: { oId: this.$route.params.oId, fId: this.$route.params.fId } })

                    return this.setNotification({
                        type: 'success',
                        title: 'Success',
                        message: 'The facility was updated.'
                    })
                } catch (err) {
                    this.setNotification({
                        type: 'error',
                        title: 'Error',
                        message: err.data.detail ? err.data.detail : 'There was an error. The device was not assigned.'
                    })
                } finally {
                    this.loading = false
                }
            }
        },

        checkForm () {
            this.errors = {}

            if (!this.facility.name) {
                this.errors.facilityName = ['The facility name field is required']
            }

            if (this.facility.fulfilmentProvider == null || parseInt(this.facility.fulfilmentProvider) < 0) {
                this.errors.facilityFulfilmentProvider = ['The facility fulfilment provider field is required']
            }

            if (!this.facility.address1) {
                this.errors.facilityAddress1 = ['The facility address 1 field is required']
            }

            if (!this.facility.address2) {
                this.errors.facilityAddress2 = ['The facility address 2 field is required']
            }

            if (!this.facility.postalCode) {
                this.errors.facilityPostalCode = ['The facility postal code field is required']
            }

            if (!this.facility.country) {
                this.errors.facilityCountry = ['The facility country field is required']
            }

            if (!this.facility.countryCode) {
                this.errors.facilityCountryCode = ['The facility country code field is required']
            }

            if (this.facility.title == null || parseInt(this.facility.title) < 0) {
                this.errors.title = ['The user title field is required']
            }

            if (!this.facility.firstName) {
                this.errors.firstName = ['The user first name field is required']
            }

            if (!this.facility.surname) {
                this.errors.surname = ['The user surname field is required']
            }

            if (!this.facility.phone) {
                this.errors.phone = ['The user phone number field is required']
            }

            if (this.facility.phone != null && !this.facility.phone.match(/[0-9]{9,20}/)) {
                this.errors.phone = ['The phone number must be a minimum of 9 digits']
            }

            if (!this.facility.email) {
                this.errors.email = ['The user email field is required']
            }

            if (this.facility.email != null && !this.facility.email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                this.errors.email = ['This not a valid email address']
            }
        },

        async setTraining (id) {
            this.isBusy = true
            try {
                await axios.patch(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/practitioners/complete-training/${this.$route.params.oId}/${id}`, {
                    trainingVersion: TrainingVersion.CURRENT,
                    trainingCompletedDateTime: new Date().toISOString() // UTC
                })

                this.getHcps()
            } catch (err) {
                throw new Error(err)
            } finally {
                this.isBusy = false
            }
        }
    }
}
</script>
