<template>
    <div class="container my-7">
        <div class="row justify-content-center">
            <div class="col-md-6 d-flex justify-content-center">
                <div class="loading"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Loader'
}
</script>
