<template>
    <div class="header py-1 bg-white fixed-top">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-6">
                    <div class="logo">
                        <router-link :to="{name: 'Dashboard'}">
                            <img width="95" height="50" src="@/assets/images/logos/logo.svg" alt="digostics">
                        </router-link>
                    </div>
                </div>

                <div class="col-6">
                    <div class="d-flex justify-content-end align-items-center">
                        <div class="my-account">
                            <span v-if="userInitials != null" class="initials">{{ userInitials }}</span>
                            <div class="d-flex flex-column">
                            <b-dropdown right :text="`${formatTitle(userTitle)} ${userFullName}`" variant="header-dropdown" class="text-capitalize" :class="{withDomainSwitcher: showDomainSwitcher}">
                                <b-dropdown-item :to="{name: 'MyAccount'}" class="dropdown-item">My account</b-dropdown-item>
                                <b-dropdown-item class="dropdown-item" @click="logout">Sign out</b-dropdown-item>
                                <b-dropdown-item v-if="showDomainSwitcher">
                                    <div class="my-domains">
                                        <ul>
                                            <li v-for="(domain, index) in orderedDomains" :key="index" :value="domain.id" @click="changeDomain(domain.id)"><span :class="{selected: domain.id === userCurrentDomain}">{{ domain.name }}</span><img v-if="domain.id === userCurrentDomain" src="@/assets/images/icons/green-tick.svg" width="14" class="domainSelected" /></li>
                                        </ul>
                                    </div>
                                </b-dropdown-item>
                            </b-dropdown>
                            <span class="domainName" v-if="showDomainSwitcher">{{ currentDomainName }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FormatterMixin from '@/mixins/FormatterMixin'
import _ from 'lodash'
export default {
    name: 'HeaderComponent',

    mixins: [
        FormatterMixin
    ],

    computed: {
        ...mapGetters({
            userInitials: 'UserStore/GetUserInitials',
            userTitle: 'UserStore/GetUserTitle',
            userFullName: 'UserStore/GetUserFullName',
            allDomains: 'DomainsStore/GetDomains',
            userCurrentDomain: 'UserStore/GetUserCurrentDomain'
        }),
        showDomainSwitcher () {
            return this.allDomains != null && this.allDomains.length > 1
        },
        orderedDomains: function () {
            return _.orderBy(this.allDomains, 'name')
        },
        currentDomainName () {
            const domain = _.find(this.allDomains, (d) => {
                return d.id === this.userCurrentDomain
            })
            return domain.name
        }
    },

    methods: {
        ...mapActions({
            signout: 'UserStore/Signout',
            setDomain: 'UserStore/SetCurrentDomainId'
        }),

        async logout () {
            await this.signout()
        },

        changeDomain (domainId) {
            this.setDomain(domainId)
            this.$router.go()
        }
    }
}
</script>
