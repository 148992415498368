<template>
    <div>
        <Loader v-if="loading" />

        <div v-else>
            <div class="row">
                <div class="col-md-6">
                    <h2 class="mb-0 mt-2">Results</h2>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-md-8 col-lg-5">
                    <hr class="d-md-none mb-3">
                    <form @submit.prevent="search()" class="form-inline">
                        <label class="sr-only" for="search">Search</label>
                        <input type="text" class="form-control searchbar-input mr-sm-2 w-60 w-mobile-100" :class="{'border border-danger' : queryError}" id="search" v-model="searchParams.queryText" placeholder="Search for results">
                        <button onclick="this.blur();" :disabled="loading" type="submit" class="btn btn-success btn-form sm-shadow btn-block-mobile mt-2 mt-sm-0" :class="{disabled : loading}">Search <span v-if="loading" class="btn-loading"></span></button>
                        <button onclick="this.blur();" @click="reset()" :disabled="loading" role="button" class="btn btn-yellow btn-form sm-shadow btn-block-mobile mt-2 mt-sm-0 ml-0 ml-md-2" :class="{disabled : loading}">Reset <span v-if="loading" class="btn-loading"></span></button>
                    </form>

                    <small v-if="queryError" class="d-block text-danger">
                        Please enter a search query
                    </small>

                    <router-link :to="{name: 'AdvancedSearch'}" class="underlined font-weight-bold text-sm d-inline-block mt-1">Advanced search</router-link>

                    <hr class="d-md-none mt-3 mb-0">
                </div>
            </div>

            <div class="row mt-3 mt-md-45">
                <div class="col">
                    <p class="h4 font-weight-medium">Results <small v-if="results.results && results.results.length" class="font-italic text-sm">{{ `Showing ${showingComputed} of ${results.totalCount}` }}</small></p>
                </div>
            </div>

            <div class="row justify-content-between mt-3">
                <div class="col-lg-7 col-md-9">
                    <div class="row">
                        <div class="col-md-4">
                            <select class="custom-select" v-model="searchParams.chosenOrganisationId" @change="chooseOrganisation()">
                                <option value="">All Organisations</option>
                                <option v-for="(organisation, index) in allOrganisations" :key="index" :value="organisation.id">{{ organisation.name }}</option>
                            </select>
                        </div>
                        <div class="col-md-4 pl-md-0 mt-2 mt-md-0">
                            <select :disabled="facilityListDisabled" class="custom-select" v-model="searchParams.chosenFacilityId" @change="search()">
                                <option value="">All Facilities</option>
                                <option v-for="(facility, index) in allFacilities" :key="index" :value="facility.id">{{ facility.name }}</option>
                            </select>
                        </div>
                        <div class="col-md-4 pl-md-0 mt-2 mt-md-0">
                            <select :disabled="practitionerListDisabled" class="custom-select" v-model="searchParams.chosenPractitionerId" @change="search()">
                                <option value="">All HCPs</option>
                                <option v-for="(practitioner, index) in allPractitioners" :key="index" :value="practitioner.id">{{ practitioner.name }}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="col-xl-2 col-md-3 mt-2 mt-md-0">
                    <div class="d-flex align-items-center">
                        <label class="font-weight-medium d-none d-md-block text-sm mr-3 mb-0">Status</label>
                        <select class="custom-select" v-model="searchParams.chosenStatus" @change="search()">
                            <option value="">Any</option>
                            <option value="NEW">New</option>
                            <option value="ISSUED">Issued</option>
                            <option value="RETURNED">Returned</option>
                            <option value="COMPLETE">Complete</option>
                            <option value="CANCELLED">Cancelled</option>
                            <option value="REPORTED">Reported</option>
                            <option value="FAILED">Failed</option>
                            <option value="ERROR">Errored</option>
                        </select>
                    </div>
                </div>
            </div>

            <div v-if="!results.results.length">
                <div class="row mt-5">
                    <div class="col-md-6">
                        <div class="alert alert-info">
                            Your search query returned no results. Please try amending your search criteria
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="results.results && results.results.length" class="row mt-4">
                <div class="col">
                    <b-table
                        ref="table"
                        id="table"
                        :sort-by.sync="sortBySync"
                        :sort-desc.sync="sortDesc"
                        @sort-changed="sortingChanged"
                        :no-local-sorting="true"
                        :busy.sync="isBusy"
                        class="custom-table bg-white"
                        responsive
                        no-sort-reset
                        :fields="filteredTestsFields"
                        :items="results.results"
                    >
                        <template #cell(patientName)="data">
                            <router-link class="font-weight-bold underlined text-capitalize" :to="{name: 'ViewTest', params: {oId : data.item.organisationId, tId: data.item.testId}}">{{ data.item.patientName }}</router-link>
                        </template>

                        <template #cell(testNumber)="data">
                            <router-link class="font-weight-bold underlined text-capitalize" :to="{name: 'ViewTest', params: {oId : data.item.organisationId, tId: data.item.testId}}">{{ data.item.testNumber }}</router-link>
                            <div class="test-history-icons" v-if="data.item.hasBeenPreviouslyReordered || data.item.previouslyBeenSetAsReturned">
                                <img src="@/assets/images/icons/set-return-icon.svg" :class="{invisible: !data.item.previouslyBeenSetAsReturned}" v-b-tooltip.hover title="Test has previously been set as Returned" />
                                <img v-if="data.item.replacedByTestId" src="@/assets/images/icons/reorder-old-icon.svg" v-b-tooltip.hover title="This test has been reordered"/>
                                <img v-else-if="data.item.replacesTestId" src="@/assets/images/icons/reorder-new-icon.svg" v-b-tooltip.hover title="This test replaces a previous test"/>
                                <img v-else-if="data.item.hasBeenPreviouslyReordered" src="@/assets/images/icons/reorder-old-icon.svg" v-b-tooltip.hover title="Test has been previously reordered"/>
                            </div>
                        </template>

                        <template #cell(status)="data">
                            <testStatuses :status="data.item.status" />
                        </template>

                        <template #cell(practitionerName)="data">
                            <router-link class="font-weight-bold underlined text-capitalize" :to="{name: 'ViewHcp', params: {oId : data.item.organisationId, pId: data.item.practitionerId}}">{{ data.item.practitionerName }}</router-link>
                        </template>

                        <template #cell(facilityName)="data">
                            <router-link class="font-weight-bold underlined text-capitalize" :to="{name: 'ViewFacility', params: {oId : data.item.organisationId, fId: data.item.facilityId}}">{{ data.item.facilityName }}</router-link>
                        </template>

                        <template #cell(organisationName)="data">
                            <router-link class="font-weight-bold underlined text-capitalize" :to="{name: 'ViewOrganisation', params: {id : data.item.organisationId}}">{{ data.item.organisationName }}</router-link>
                        </template>

                        <template #cell(action)="data">
                            <router-link :to="{name: 'ViewTest', params: {oId : data.item.organisationId, tId: data.item.testId}}" class="btn btn-primary sm-shadow text-nowrap">View Test</router-link>
                        </template>
                    </b-table>

                    <div class="mt-4 d-flex flex-column flex-md-row align-items-center justify-content-end">
                        <div class="form-group d-flex align-items-center mb-4 mb-md-0">
                            <label class="flex-shrink-0 mr-2">Items per page</label>
                            <select class="custom-select" v-model="pageSize" @change="handleChangePageSize()">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                            </select>
                        </div>

                        <div>
                            <b-pagination
                                v-model="currentPage"
                                :current-page="currentPage"
                                :per-page="results.pageSize"
                                :total-rows="results.totalCount"
                                prev-text="< Previous"
                                next-text="Next >"
                                @change="pageChange"
                                aria-controls="table"
                                hide-goto-end-buttons
                                prev-class="prev-page"
                                next-class="next-page"
                                align="right"
                                class="mb-0"
                            ></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import Loader from '@/partials/Loader.vue'
import TestStatuses from '@/components/TestStatusesComponent.vue'
import TotalResultsMixin from '@/mixins/TotalResultsMixin'
import FormatterMixin from '@/mixins/FormatterMixin'
export default {
    name: 'Results',

    components: {
        Loader,
        TestStatuses
    },

    mixins: [
        TotalResultsMixin,
        FormatterMixin
    ],

    data () {
        return {
            loading: true,
            facilityListDisabled: true,
            practitionerListDisabled: true,
            query: '',
            queryError: false,
            isBusy: false,
            currentPage: 1,
            offset: 0,
            pageSize: process.env.VUE_APP_PAGINATION_PAGE_SIZE,
            sortColumn: 'LAST_UPDATED_DATE',
            sortType: 'DESCENDING',
            sortDesc: false,
            sortBySync: 'patientName',
            results: {
                results: []
            }
        }
    },

    async mounted () {
        await this.getAllOrganisations()

        if (this.searchParams.chosenOrganisationId !== '') {
            await this.getFacilitiesByOrganisation(this.searchParams.chosenOrganisationId)
            await this.getPractitionersByOrganisation(this.searchParams.chosenOrganisationId)
            this.facilityListDisabled = false
            this.practitionerListDisabled = false
        }

        await this.getResults()
    },

    computed: {
        ...mapGetters({
            userIsOwner: 'UserStore/GetIsUserOwner',
            userId: 'UserStore/GetUserId',
            allFacilities: 'FacilityStore/GetAllFacilities',
            allPractitioners: 'PractitionerStore/GetAllPractitioners',
            allOrganisations: 'OrganisationStore/GetAllOrganisations',
            searchParams: 'SearchStore/GetSearchParams'
        }),

        filteredTestsFields () {
            return [
                {
                    key: 'patientName',
                    label: 'Patient',
                    variant: 'text-wrap w-15',
                    sortable: true
                },
                {
                    key: 'testNumber',
                    label: 'Test No.',
                    sortable: true
                },
                {
                    key: 'issuedDate',
                    label: 'Issue Date',
                    sortable: true,
                    formatter: 'formatDate'
                },
                {
                    key: 'returnDate',
                    label: 'Return date',
                    sortable: true,
                    formatter: 'formatDate'
                },
                {
                    key: 'dueDate',
                    label: 'Test date',
                    sortable: true,
                    formatter: 'formatDate'
                },
                {
                    key: 'gdEstimatedDeliveryDate',
                    label: 'EDD',
                    sortable: true,
                    formatter: 'formatDate'
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: true
                },
                {
                    key: 'practitionerName',
                    label: 'Assigned HCP',
                    variant: 'text-wrap w-15',
                    sortable: true
                },
                {
                    key: 'facilityName',
                    label: 'Facility',
                    variant: 'text-wrap w-15',
                    sortable: true
                },
                {
                    key: 'organisationName',
                    label: 'Organisation',
                    variant: 'text-wrap w-15',
                    sortable: true
                },
                {
                    key: 'dateUpdated',
                    label: 'Last Updated',
                    variant: 'text-wrap w-15',
                    formatter: 'formatDate',
                    sortable: true
                },
                {
                    key: 'action',
                    label: ''
                }
            ]
        }
    },

    methods: {
        ...mapActions({
            clearChosenFacilityAndChosenPractitionerInSearchStore: 'SearchStore/ClearChosenFacilityAndChosenPractitioner',
            getFacilitiesByOrganisation: 'FacilityStore/GetFacilitiesByOrganisation',
            getPractitionersByOrganisation: 'PractitionerStore/GetPractitionersByOrganisation',
            getAllOrganisations: 'OrganisationStore/GetAllOrganisations',
            clearSearchStore: 'SearchStore/ClearSearchStore',
            setNotification: 'NotificationStore/SetNotification'
        }),

        reset () {
            this.practitionerListDisabled = true
            this.facilityListDisabled = true

            this.clearSearchStore()

            this.search()
        },

        async search () {
            this.currentPage = 1
            this.offset = 0
            await this.getResults()
        },

        async getResults () {
            this.isBusy = true
            this.searchParams.hasSearchBeenExecuted = true
            const chosenStatusArray = []
            if (this.searchParams.chosenStatus) {
                chosenStatusArray.push(this.searchParams.chosenStatus)
            }

            const searchModel = {
                searchText: this.searchParams.queryText,
                organisationId: this.searchParams.chosenOrganisationId,
                facilityId: this.searchParams.chosenFacilityId,
                practitionerId: this.searchParams.chosenPractitionerId,
                testStatus: chosenStatusArray,
                pageSize: this.pageSize,
                offset: this.offset,
                sortColumn: this.sortColumn,
                sortType: this.sortType
            }

            try {
                const response = await axios.post(`${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/tests/list`, searchModel)

                this.results = response.data
            } catch (err) {
                this.setNotification({
                    type: 'error',
                    title: 'Error',
                    message: 'Something went wrong. Please try again'
                })
            } finally {
                this.loading = false
                this.isBusy = false
            }
        },

        async chooseOrganisation () {
            await this.clearChosenFacilityAndChosenPractitionerInSearchStore()

            if (this.searchParams.chosenOrganisationId) {
                await this.getFacilitiesByOrganisation(this.searchParams.chosenOrganisationId)
                await this.getPractitionersByOrganisation(this.searchParams.chosenOrganisationId)
                this.facilityListDisabled = false
                this.practitionerListDisabled = false
            } else {
                this.facilityListDisabled = true
                this.practitionerListDisabled = true
            }

            await this.search()
        },

        sortingChanged (e) {
            switch (e.sortBy) {
                case 'patientName':
                    this.sortByValue = 'PATIENT_NAME'
                break

                case 'testNumber':
                    this.sortByValue = 'TEST_NUMBER'
                break

                case 'issuedDate':
                    this.sortByValue = 'ISSUED_DATE'
                break

                case 'returnDate':
                    this.sortByValue = 'RETURN_DATE'
                break

                case 'dueDate':
                    this.sortByValue = 'DUE_DATE'
                break

                case 'gdEstimatedDeliveryDate':
                    this.sortByValue = 'EDD'
                break

                case 'status':
                    this.sortByValue = 'STATUS'
                break

                case 'practitionerName':
                    this.sortByValue = 'PRACTITIONER_NAME'
                break

                case 'facilityName':
                    this.sortByValue = 'FACILITY_NAME'
                break

                case 'organisationName':
                    this.sortByValue = 'ORGANISATION_NAME'
                break

                case 'dateUpdated':
                    this.sortByValue = 'LAST_UPDATED_DATE'
                break
            }

            this.sortColumn = this.sortByValue

            this.sortDesc = e.sortDesc

            if (this.sortDesc) {
                this.sortType = 'DESCENDING'
            } else {
                this.sortType = 'ASCENDING'
            }

            this.getResults()
        },

        pageChange (value) {
            this.currentPage = value
            this.offset = (value - 1) * this.results.pageSize
            this.getResults()
        },

        refreshTable () {
            this.$refs.table.refresh()
        },

        handleChangePageSize () {
            this.currentPage = 1
            this.offset = 0

            this.getResults()
        }
    }
}
</script>
