<template>
    <div>
        <p class="modal-heading">Reorder test {{ test.testNumber }}</p>

        <div class="mt-3">
            <h6>When does the test need to be taken?<span class="text-danger">*</span></h6>
            <div class="mt-3">
                <div class="">
                    <b-form-datepicker id="test-datepicker" :min="testMin" v-model="testDueDate" class="mb-2"></b-form-datepicker>
                </div>
            </div>
            <small v-if="errors && errors.testDueDate" class="text-danger mt-1">
                {{ errors.testDueDate[0] }}
            </small>
        </div>

        <textarea class="form-control note mt-4" placeholder="Optionally add a note to the test" v-model="note"></textarea>

        <div class="d-flex align-items-center mt-45">
            <button :disabled="loading" @click="close()" class="btn btn-cancel btn-lg lg-shadow d-flex flex-grow-1 w-45 justify-content-center align-items-center" :class="{disabled : loading}">Cancel</button>
            <button :disabled="loading" @click="reorderTest()" class="btn btn-success btn-lg lg-shadow d-flex flex-grow-1 w-55 ml-3 justify-content-center align-items-center" :class="{disabled : loading}">Reorder<span v-if="loading" class="btn-loading"></span></button>
        </div>

    </div>
</template>

<script>
import axios from 'axios'
import { mapActions } from 'vuex'
export default {
    name: 'ReorderTestModalComponent',

    props: {
        organisationId: String,
        test: {}
    },

    data () {
        const now = new Date()
        return {
            loading: false,
            errors: {},
            note: null,
            testMin: now,
            testDueDate: null
        }
    },

    methods: {
        ...mapActions({
            setNotification: 'NotificationStore/SetNotification',
            getStatusCountsAction: 'FulfilmentStatusCountsStore/GetStatusCounts'
        }),

        async reorderTest () {
            this.checkForm()
            if (!Object.keys(this.errors).length) {
                this.loading = true

                try {
                    await axios.post(`${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/tests/${this.organisationId}/${this.test.id}/reorder`, {
                        testDueDateTime: this.testDueDate,
                        note: this.note
                    })

                    this.setNotification({
                        type: 'success',
                        title: 'Success',
                        message: 'The test has been successfully reordered'
                    })
                    await this.getStatusCountsAction()
                } catch (err) {
                    console.log(err)
                    this.setNotification({
                        type: 'error',
                        title: 'Error',
                        message: 'Sorry, something went wrong when reordering the test. Please try again'
                    })
                } finally {
                    this.loading = false
                }
                this.$emit('close')
            }
        },
        checkForm () {
            this.errors = {}
            if (!this.testDueDate) {
                this.errors.testDueDate = ['The test date field is required']
            }
        },
        close () {
            this.$emit('close')
        }
    }
}
</script>
