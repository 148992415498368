const Salutation = Object.freeze({
    MR: 0,
    MRS: 1,
    MISS: 2,
    MS: 3,
    DR: 4,
    SIR: 5,
    PROFESSOR: 6
})

export default Salutation
