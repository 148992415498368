<template>
    <div>
        <span v-if="status == TestStatus.NEW" class="badge badge-pill badge-new">New</span>
        <span v-if="status == TestStatus.ISSUED" class="badge badge-pill badge-issued">Issued</span>
        <span v-if="status == TestStatus.RETURNED" class="badge badge-pill badge-returned">Returned</span>
        <span v-if="status == TestStatus.COMPLETE" class="badge badge-pill badge-complete">Complete</span>
        <span v-if="status == TestStatus.CANCELLED" class="badge badge-pill badge-cancelled">Cancelled</span>
        <span v-if="status == TestStatus.REPORTED" class="badge badge-pill badge-reported">Reported</span>
        <span v-if="status == TestStatus.FAILED" class="badge badge-pill badge-failed">Failed</span>
        <span v-if="status == TestStatus.ERROR" class="badge badge-pill badge-error">Errored</span>
    </div>
</template>

<script>
import TestStatus from '@/enums/TestStatus'
export default {
    name: 'TestStatusesComponent',

    props: {
        status: Number
    },

    data () {
        return {
            TestStatus
        }
    }
}
</script>
