<template>
    <div>
        <p v-if="!formSubmitted" class="modal-heading">Add a Training Organisation</p>
        <p v-if="formSubmitted && practitionerResults.length" class="modal-heading mb-4">The following practitioners were created</p>
        <div class="text-sm mt-3">
            <div v-if="!formSubmitted">
                <div class="form-group">
                    <label>Organisation Name *</label>
                    <input :disabled="loading" v-model="organisationName" class="form-control" />
                </div>

                <div class="form-group">
                    <label>Data Store *</label>
                    <select :disabled="loading" class="custom-select" v-model="dataStore">
                        <option selected disabled value="">Please select an option</option>
                        <option v-for="(dataStore, index) in dataStores.results" :key="index" :value="dataStore.id">{{ dataStore.name }}</option>
                    </select>
                </div>

                <div class="form-group">
                    <label>Number of Practitioners *</label>
                    <input :disabled="loading" type="number" min="1" v-model="numberOfPractitioners" class="form-control" />
                </div>

                <div class="d-flex align-items-center mt-45">
                    <button :disabled="loading" @click="$emit('close')" class="btn btn-cancel btn-lg lg-shadow d-flex flex-grow-1 w-45 justify-content-center align-items-center" :class="{disabled : loading}">Cancel</button>
                    <button :disabled="loading || !formValid" @click="confirm()" class="btn btn-success btn-lg lg-shadow d-flex flex-grow-1 w-55 ml-3 justify-content-center align-items-center" :class="{disabled : loading || !formValid}">Add <span v-if="loading" class="btn-loading"></span></button>
                </div>

                <div v-if="error" class="mt-2">
                    <p class="text-danger mb-0">{{ this.errorText }}</p>
                </div>
            </div>

            <div v-if="formSubmitted && practitionerResults.length">
                <div v-for="(practitioner, index) in practitionerResults" :key="index" class="mb-4">
                    <p class="mb-0">Email: {{ practitioner.email }}</p>
                    <p class="mb-0">Password: {{ practitioner.password }}</p>
                </div>

                <div class="mt-45">
                    <button :disabled="loading" @click="$emit('close')" class="btn btn-cancel btn-lg lg-shadow d-flex flex-grow-1 w-45 justify-content-center align-items-center" :class="{disabled : loading}">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
export default {
    name: 'AddTrainingOrganisationModalComponent',
    data () {
        return {
            loading: false,
            organisationName: null,
            dataStores: [],
            dataStore: '',
            numberOfPractitioners: null,
            results: {},
            practitionerResults: [],
            formSubmitted: false,
            error: false,
            errorText: 'Sorry, something went wrong. Please try again'
        }
    },
    mounted () {
        if (process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL == null) {
            this.error = true
            return
        }

        try {
            this.getDataStores()
        } catch {
            this.error = true
        }
    },
    computed: {
        ...mapGetters({
            userCurrentDomain: 'UserStore/GetUserCurrentDomain'
        }),

        formValid: function () {
            if (this.organisationName == null ||
                this.dataStore == null ||
                this.numberOfPractitioners == null ||
                this.numberOfPractitioners < 1 ||
                this.organisationName === '' ||
                this.dataStore === '' ||
                this.numberOfPractitioners === ''
            ) return false

            return true
        }
    },
    methods: {
        async getDataStores () {
            try {
                const response = await axios.get(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/datastores/100/0`)
                this.dataStores = response.data
            } catch {
                throw new Exception()
            }
        },

        async confirm () {
            const model = {
                domainId: this.userCurrentDomain,
                dataStoreId: this.dataStore,
                organisationName: this.organisationName,
                practitionerCount: this.numberOfPractitioners
            }

            this.error = false
            this.errorText = 'Sorry, something went wrong. Please try again'
            this.loading = true

            try {
                const response = await axios.post(`${process.env.VUE_APP_AXIOS_PROVISIONING_BASE_URL}/api/v1/provisioning`, model)
                this.results = response.data
                this.practitionerResults = response.data.practitioners
                this.formSubmitted = true
            } catch (err) {
                if (err.data.status === 409) this.errorText = 'An organisation with this name already exists'
                this.loading = false
                this.error = true
            } finally {
                this.loading = false
            }
        }
    }
}
</script>
