<template>
    <div>
        <Loader v-if="loading" />

        <div v-else>
            <div class="row align-items-center">
                <div class="col-md-6">
                    <h2 class="mb-0 mt-2">Fulfilment | Packing</h2>
                </div>

                <div class="col-md-6 d-flex justify-content-end">
                    <ToggleScanningComponent
                        v-if="results.results.length"
                        :uniqueCode="ScanningCodeType.BPM"
                        @deviceAssigned="deviceAssigned"
                    />
                </div>
            </div>

            <div v-if="!results.results.length" class="row mt-4">
                <div class="col-md-6" role="alert">
                    <div class="alert alert-info">
                        There are no orders to show at the moment
                    </div>
                </div>
            </div>

            <div v-else>
                <div class="row mt-4">
                    <div class="col d-flex flex-column flex-md-row align-items-stretch align-items-md-center">
                        <h5 class="mb-0">All <span class="font-italic">({{ results.totalCount }})</span></h5>
                        <button :disabled="isBusy || !selected.length" @click="moveOrdersBack()" role="button" class="btn btn-fulfilment-open sm-shadow mt-2 mt-md-0 ml-0 ml-md-3" :class="{disabled : isBusy || !selected.length}">Move to Open</button>
                        <button :disabled="isBusy || !selected.length" @click="moveOrders()" role="button" class="btn btn-fulfilment-qa sm-shadow mt-2 mt-md-0 ml-0 ml-md-3" :class="{disabled : isBusy || !selected.length}">Move to QA</button>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col">
                        <b-table
                            ref="table"
                            :sort-by.sync="sortBySync"
                            :sort-desc.sync="sortDesc"
                            @sort-changed="sortingChanged"
                            :no-local-sorting="true"
                            :busy.sync="isBusy"
                            class="custom-table bg-white"
                            responsive
                            no-sort-reset
                            :fields="filteredFields"
                            :items="results.results"
                            @row-selected="onRowSelected"
                            selectable
                            :select-mode="selectMode"
                        >
                            <template #cell(orderNumber)="data">
                                <router-link class="font-weight-bold underlined text-capitalize" :to="{name: 'ViewOrder', params: {id: data.item.id}}">{{ data.item.orderNumber }}</router-link>
                            </template>

                            <template #cell(select)="data">
                                <b-form-checkbox
                                    v-if="data.rowSelected"
                                    v-model="data.rowSelected"
                                    @change="data.unselectRow()"
                                >
                                </b-form-checkbox>

                                <b-form-checkbox
                                    v-if="!data.rowSelected"
                                    v-model="data.rowSelected"
                                    @change="data.selectRow()"
                                >
                                </b-form-checkbox>
                            </template>

                            <template #cell(assigned)="data">
                                <img v-if="data.item.deviceNumber || data.item.serialNumber" src="@/assets/images/icons/green-tick.svg" width="20" />
                                <img v-else src="@/assets/images/icons/error.svg" width="20" />
                            </template>

                            <template #cell(alternativeLanguage)="data">
                                {{ languageCode(data.item.alternativeLanguage) }}
                            </template>

                            <template #cell(action)="data">
                                <router-link :to="{name: 'ViewOrder', params: {id: data.item.id}}" class="btn btn-primary px-4 sm-shadow text-nowrap">View</router-link>
                            </template>
                        </b-table>

                        <div class="mt-4 d-flex flex-column flex-md-row align-items-center justify-content-end">
                            <div class="form-group d-flex align-items-center mb-4 mb-md-0">
                                <label class="flex-shrink-0 mr-2">Items per page</label>
                                <select class="custom-select" v-model="pageSize" @change="handleChangePageSize()">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                </select>
                            </div>

                            <div>
                                <b-pagination
                                    v-model="currentPage"
                                    :current-page="currentPage"
                                    :per-page="results.pageSize"
                                    :total-rows="results.totalCount"
                                    prev-text="< Previous"
                                    next-text="Next >"
                                    @change="pageChange"
                                    aria-controls="tests-table"
                                    hide-goto-end-buttons
                                    prev-class="prev-page"
                                    next-class="next-page"
                                    align="right"
                                    class="mb-0"
                                >
                                </b-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Loader from '@/partials/Loader.vue'
import FulfilmentTableMixin from '@/mixins/FulfilmentTableMixin'
import ToggleScanningComponent from '@/components/ToggleScanningComponent'
import ScanningCodeType from '@/enums/ScanningCodeType'

export default {
    name: 'FulfilmentPacking',

    components: {
        Loader,
        ToggleScanningComponent
    },

    mixins: [
        FulfilmentTableMixin
    ],

    data () {
        return {
            loading: true,
            orderType: 'PACKING',
            ScanningCodeType
        }
    },

    async mounted () {
        try {
            await this.getOrders()
        } catch {
            this.setNotification({
                type: 'error',
                title: 'Error',
                message: 'Something went wrong while fetching the orders. Please try again.'
            })
        } finally {
            this.loading = false
        }
    },

    beforeDestroy () {
        if (this.scanning) {
            this.disconnect()
        }
    },

    computed: {
        ...mapGetters({
            alternativeLanguages: 'LanguageStore/GetLanguages'
        }),
        filteredFields () {
            return [
                {
                    key: 'select',
                    label: 'Select'
                },
                {
                    key: 'orderNumber',
                    label: 'Order Number',
                    sortable: 'true'
                },

                {
                    key: 'dateCreated',
                    label: 'Order Date',
                    sortable: 'true',
                    formatter: 'formatDate'
                },

                {
                    key: 'patientName',
                    label: 'Patient Name',
                    variant: 'text-wrap w-15',
                    sortable: 'true'
                },

                {
                    key: 'assigned',
                    label: 'Assigned'
                },

                {
                    key: 'alternativeLanguage',
                    label: 'Language',
                    sortable: 'true'
                },

                {
                    key: 'action',
                    label: ''
                }
            ]
        }
    },

    methods: {
        async moveOrdersBack () {
            try {
                await this.changeOrderStatus('OPEN')
                await this.getOrders()
            } catch (err) {
                this.setNotification({
                    type: 'error',
                    title: 'Error',
                    message: err ?? 'Something went wrong while updating the order status. Please try again.'
                })
            }
        },

        async moveOrders () {
            try {
                var unassignedSelectedOrders = this.selected.filter(order => !order.deviceNumber && !order.serialNumber)
                if (unassignedSelectedOrders.length > 0) {
                    this.setNotification({
                        type: 'error',
                        title: 'Error moving orders to QA',
                        message: `The following orders do not have devices assigned: ${unassignedSelectedOrders.map(o => o.orderNumber).join(', ')}`
                    })
                    return
                }

                await this.changeOrderStatus('QA')
                await this.getOrders()
            } catch (err) {
                this.setNotification({
                    type: 'error',
                    title: 'Error',
                    message: err ?? 'Something went wrong while updating the order status. Please try again.'
                })
            }
        },

        deviceAssigned (response) {
            this.results.results.map(order => {
                if (order.id === response.data.id) {
                    order.deviceNumber = response.data.deviceNumber
                    order.serialNumber = response.data.serialNumber
                }
                return order
            })
        }
    }
}
</script>
