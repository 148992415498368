<template>
    <div>
        <Loader v-if="loading" />

        <div v-if="!loading && Object.keys(organisation).length">
            <div class="row align-items-center">
                <div class="col-md-5">
                    <Breadcrumbs
                        :dynamicTitle="organisation.name"
                    />
                </div>

                <div class="col-md-7 text-right mt-2 mt-md-0">
                    <div class="d-flex flex-column flex-xl-row justify-content-end">
                        <button @click="viewResults()" class="btn btn-yellow btn-lg lg-shadow px-55 xl-2 mt-xl-0">View results</button>
                        <button @click="createFacility()" class="btn btn-success btn-lg lg-shadow px-55 ml-0 ml-xl-3 my-2 my-xl-0">Add a facility</button>
                        <button @click="createHcp()" class="btn btn-success btn-lg lg-shadow px-55 ml-0 ml-xl-3">Add a HCP</button>
                    </div>
                </div>
            </div>

            <div class="card mt-3">
                <div class="card-body">
                    <div class="d-flex flex-column flex-md-row justify-content-between">
                        <h5 class="mb-0">Organisation details</h5>
                        <router-link :to="{name: 'EditOrganisation', params: {id: $route.params.id}}" class="btn btn-primary sm-shadow mt-4 mt-md-0 px-4">Edit</router-link>
                    </div>

                    <div class="row mt-4">
                        <div class="col-lg-6">
                            <div class="row">
                                <div class="col-sm-4 font-weight-medium">Address</div>
                                <div class="col-sm-8">
                                    <span v-if="organisation.address1">{{ `${organisation.address1}, ` }}</span>
                                    <span v-if="organisation.address2">{{ `${organisation.address2}, ` }}</span>
                                    <span v-if="organisation.address3">{{ `${organisation.address3}, ` }}</span>
                                    <span v-if="organisation.address4">{{ `${organisation.address4}, ` }}</span>
                                    <span v-if="organisation.postalCode" class="text-uppercase">{{ `${organisation.postalCode}, ` }}</span>
                                    <span v-if="organisation.country">{{ `${organisation.country}` }}</span>
                                </div>
                            </div>

                            <hr>

                            <div class="row">
                                <div class="col-sm-4 font-weight-medium">Fulfilment provider</div>
                                <div class="col-sm-8">
                                    <span v-if="organisation.fulfilmentProvider == 0">Digostics</span>
                                </div>
                            </div>

                            <hr>

                            <div v-if="organisation.dataStoreName">
                                <div class="row">
                                    <div class="col-sm-4 font-weight-medium">Data store</div>
                                    <div class="col-sm-8">{{ organisation.dataStoreName }}</div>
                                </div>

                                <hr>
                            </div>

                            <div v-if="domain.name">
                                <div class="row">
                                    <div class="col-sm-4 font-weight-medium">Domain</div>
                                    <div class="col-sm-8">{{ domain.name }}</div>
                                </div>

                                <hr>
                            </div>

                            <div class="row">
                                <div class="col-sm-4 font-weight-medium">Status</div>
                                <div class="col-sm-8" :class="{'text-success' : organisation.isActive, 'text-danger' : !organisation.isActive }">{{ organisation.isActive ? "ACTIVE" : "INACTIVE" }}</div>
                            </div>
                        </div>
                    </div>

                    <h5 class="mt-5">Primary contact</h5>

                    <div class="row mt-4">
                        <div class="col-lg-6">
                            <div class="row">
                                <div class="col-sm-4 font-weight-medium">Name</div>
                                <div class="col-sm-8">{{ organisation.firstName + ' ' + organisation.surname }}</div>
                            </div>

                            <hr>

                            <div class="row">
                                <div class="col-sm-4 font-weight-medium">Phone number</div>
                                <div class="col-sm-8">{{ organisation.phone }}</div>
                            </div>

                            <hr>

                             <div class="row">
                                <div class="col-sm-4 font-weight-medium">Email</div>
                                <div class="col-sm-8">{{ organisation.email }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!loading" class="mt-5">
            <h5>Facilities <span class="font-italic">({{ results.totalCount }})</span></h5>

            <b-table
                v-if="results.results.length"
                ref="table"
                id="table"
                :sort-by.sync="sortBySync"
                :sort-desc.sync="sortDesc"
                @sort-changed="sortingChanged"
                :no-local-sorting="true"
                :busy.sync="isBusy"
                class="custom-table padding-lg bg-white mt-4"
                responsive
                no-sort-reset
                :items="results.results"
                :fields="filteredFields"
            >
                <template #cell(name)="data">
                    <router-link class="font-weight-bold underlined text-capitalize" :to="{name: 'ViewFacility', params: {oId : $route.params.id, fId : data.item.id}}">{{ data.item.name }}</router-link>
                </template>

                <template #cell(isActive)="data">
                    <span :class="{'text-success' : data.item.isActive}">{{ data.item.isActive ? "ACTIVE" : "INACTIVE" }}</span>
                </template>
            </b-table>

            <div class="mt-4">
                <b-pagination
                    v-if="results.results.length"
                    v-model="currentPage"
                    :current-page="currentPage"
                    :per-page="results.pageSize"
                    :total-rows="results.totalCount"
                    prev-text="< Previous"
                    next-text="Next >"
                    @change="pageChange"
                    aria-controls="tests-table"
                    hide-goto-end-buttons
                    prev-class="prev-page"
                    next-class="next-page"
                    align="right"
                ></b-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import Breadcrumbs from '@/partials/site-structure/BreadcrumbsComponent'
import Loader from '@/partials/Loader'
import FormatterMixin from '@/mixins/FormatterMixin'
export default {
    name: 'ViewOrganisation',

    components: {
        Loader,
        Breadcrumbs
    },

    mixins: [
        FormatterMixin
    ],

    data () {
        return {
            loading: true,
            organisation: {},
            domain: {},
            results: {},
            isBusy: false,
            currentPage: 1,
            pageSize: process.env.VUE_APP_PAGINATION_PAGE_SIZE,
            offset: 0,
            sortColumn: 'NAME',
            sortType: 'ASCENDING',
            sortDesc: false,
            sortBySync: 'name'
        }
    },

    computed: {
        ...mapGetters({
            userIsOwner: 'UserStore/GetIsUserOwner',
            userDomains: 'DomainsStore/GetDomains'
        }),

        filteredFields () {
            return [
                {
                    key: 'name',
                    label: 'Name',
                    sortable: true
                },
                                {
                    key: 'testsIssuedCount',
                    label: 'Tests Issued',
                    formatter: 'convertNullToInt',
                    sortable: true
                },

                {
                    key: 'testsCompleteCount',
                    label: 'Tests Complete',
                    formatter: 'convertNullToInt',
                    sortable: true
                },

                {
                    key: 'isActive',
                    label: 'Status',
                    sortable: true
                }
            ]
        }
    },

    async mounted () {
        try {
            await this.getOrganisation()
            await this.getDomain()
            await this.getFacilities()
        } catch {
            this.setNotification({
                type: 'error',
                title: 'Error',
                message: 'Something went wrong while fetching the data. Please try again.'
            })
        } finally {
            this.loading = false
        }
    },

    methods: {
        ...mapActions({
            clearSearchStore: 'SearchStore/ClearSearchStore',
            setOrganisationIdInSearchStore: 'SearchStore/SetChosenOrganisationId',
            setNotification: 'NotificationStore/SetNotification'
        }),

        createFacility () {
            return this.$router.push({ name: 'CreateFacility', params: { id: this.organisation.id, organisation: this.organisation } })
        },

        createHcp () {
            return this.$router.push({ name: 'CreateHcp', params: { oId: this.organisation.id, organisation: this.organisation } })
        },

        async viewResults () {
            await this.clearSearchStore()

            await this.setOrganisationIdInSearchStore(this.$route.params.id)

            return this.$router.push({ name: 'Results' })
        },

        async getOrganisation () {
            try {
                const response = await axios.get(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/organisations/${this.$route.params.id}`)

                this.organisation = response.data
            } catch (err) {
                throw new Error(err)
            }
        },

        async getDomain () {
            // Only bother showing the domain if the user has more than one they are associated with (or have no domains because they are an adminowner user)
            if (this.organisation.domainId && (this.userDomains == null || this.userDomains.length > 1)) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/domains/${this.organisation.domainId}`)

                    this.domain = response.data
                } catch (err) {
                    throw new Error(err)
                }
            }
        },

        async getFacilities () {
            this.isBusy = true

            try {
                const response = await axios.get(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/facilities/${this.$route.params.id}/${this.pageSize}/${this.offset}/${this.sortColumn}/${this.sortType}`)

                this.results = response.data
            } catch (err) {
                throw new Error(err)
            } finally {
                this.isBusy = false
            }
        },

        pageChange (value) {
            this.currentPage = value
            this.offset = (value - 1) * this.pageSize
            this.getFacilities()
        },

        sortingChanged (e) {
            switch (e.sortBy) {
                case 'name':
                    this.sortColumn = 'NAME'
                break

                case 'testsIssuedCount':
                    this.sortColumn = 'TESTS_ISSUED'
                break

                case 'testsCompleteCount':
                    this.sortColumn = 'TESTS_COMPLETE'
                break

                case 'isActive':
                    this.sortColumn = 'STATUS'
                break
            }

            this.sortDesc = e.sortDesc
            this.sortBySync = e.sortBy

            if (this.sortDesc) {
                this.sortType = 'DESCENDING'
            } else {
                this.sortType = 'ASCENDING'
            }

            this.getFacilities()
        }
    }
}
</script>
