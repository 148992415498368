<template>
  <div>
    <Loader v-if="loading" />
    <div v-else>
        <Breadcrumbs />

        <div class="row mt-3">
            <div class="col">
                <div class="card text-sm">
                    <div class="card-body">
                        <h5 class="mb-0">Profile details</h5>

                        <div class="row justify-content-between mt-4">
                            <div class="col-xl-5">
                                <div class="form-group row">
                                    <label for="first-name" class="col-sm-5 col-form-label">First name <span class="text-danger">*</span></label>
                                    <div class="col-sm-7">
                                        <input name="first-name" type="text" class="form-control" v-model="userDetails.firstName" />
                                        <small v-if="errors && errors.firstName" class="text-danger">
                                            {{ errors.firstName[0] }}
                                        </small>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="surname" class="col-sm-5 col-form-label">Surname <span class="text-danger">*</span></label>
                                    <div class="col-sm-7">
                                        <input name="surname" type="text" class="form-control" v-model="userDetails.lastName" />
                                        <small v-if="errors && errors.lastName" class="text-danger">
                                            {{ errors.lastName[0] }}
                                        </small>
                                    </div>
                                </div>

                                <div class="form-group row align-items-center">
                                    <label for="email" class="col-sm-5 col-form-label">Email</label>
                                    <div class="col-sm-7">
                                        {{ userDetails.email }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col">
                <div class="card text-sm">
                    <div class="card-body">
                        <h5 class="mb-0">User details</h5>

                        <div class="row justify-content-between mt-4">
                            <div class="col-xl-5">
                                <div class="row">
                                    <div class="col-sm-5">Status</div>
                                    <div class="col-sm-7">{{ userDetails.isActive ? 'Active' : 'Inactive' }}</div>
                                </div>

                                <hr>

                                <div class="row">
                                    <div class="col-sm-5">User created</div>
                                    <div class="col-sm-7">{{ userDetails.dateCreated | moment("dddd, MMMM Do YYYY HH:mm") }}</div>
                                </div>

                                <hr>

                                <div class="row">
                                    <div class="col-sm-5">Role</div>
                                    <div class="col-sm-7">
                                      <span v-if="userRole == RoleType.ADMIN">Admin</span>
                                      <span v-if="userRole == RoleType.OWNER">Owner</span>
                                      <span v-if="userRole == RoleType.FULFILMENT">Fulfilment</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="fixed-bottom bg-white py-25">
            <div class="container-fluid">
                <div class="row">
                    <div class="col text-right center-mobile">
                        <router-link :to="{name: 'Dashboard'}" class="btn btn-cancel btn-lg px-4 px-sm-5 lg-shadow">Cancel</router-link>
                        <button @click="updateUser()" class="btn btn-success btn-lg lg-shadow pr-55 pl-55 ml-2">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Loader from '@/partials/Loader.vue'
import Breadcrumbs from '@/partials/site-structure/BreadcrumbsComponent'
import RoleType from '@/enums/RoleType'
export default {
  name: 'MyAccount',

  components: {
    Loader,
    Breadcrumbs
  },

  data () {
    return {
      loading: false,
      errors: {},
      RoleType
    }
  },

  computed: {
    ...mapGetters({
      userDetails: 'UserStore/GetUserDetails',
      organisationId: 'UserStore/GetUserOrganisationId',
      userRoleType: 'UserStore/GetUserRoleType',
      userRole: 'UserStore/GetUserRole'
    })
  },

  methods: {
    ...mapActions({
      getUserDetails: 'UserStore/GetUserDetails',
      updateUserDetails: 'UserStore/UpdateUserDetails',
      updateNameInUserStore: 'UserStore/SetUserName',
      setNotification: 'NotificationStore/SetNotification'
    }),

    async updateUser () {
      this.checkForm()

      if (!Object.keys(this.errors).length) {
        this.loading = true

        const userModel = {
            title: this.userDetails.title,
            firstName: this.userDetails.firstName,
            middleName: this.userDetails.middleName,
            lastName: this.userDetails.lastName
        }

        try {
          await this.updateUserDetails({
            userDetails: userModel
          })

          await this.getUserDetails()

          this.updateNameInUserStore({
            title: this.userDetails.title,
            firstName: this.userDetails.firstName,
            surname: this.userDetails.lastName
          })

          this.setNotification({
            type: 'success',
            title: 'Success',
            message: 'Your account details were updated'
          })
        } catch (err) {
          this.setNotification({
            type: 'error',
            title: 'Error',
            message: 'Something went wrong. Please try again'
          })
        } finally {
          this.loading = false
        }
      }
    },

    checkForm () {
      this.errors = []

      if (!this.userDetails.firstName) {
        this.errors.firstName = ['First name field is required']
      }

      if (!this.userDetails.lastName) {
        this.errors.lastName = ['Surname field is required']
      }
    }
  }
}
</script>
