const ScannerCommandType = Object.freeze({
    PMR: 'PMR', // Packing Mode Result (followed by ":NFC Data,ShippingData")
    QMR: 'QMR', // QA Mode Result (followed by ":NFC Data,ShippingData")
    QSR: 'QSR', // QA Single Result
    ASR: 'ASR', // Assign Single Device Result
    BSO: 'BSO', // Barcode Scanner Offline
    BSR: 'BSR', // Barcode Scanner Ready
    NRO: 'NRO', // NFC Reader Offline
    NRR: 'NRR', // NFC Reader Ready
    CMT: 'CMT', // Current Moded Terminated
    ERR: 'ERR', // ERROR
    CPR: 'CPR' // Check Package Mode Result
})

export default ScannerCommandType
