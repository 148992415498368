<template>
    <div>
        <div v-if="!cancelled && !future && status == OrderStatus.OPEN"><span class="status-circle open"></span> Open</div>
        <div v-if="!cancelled && !future && status == OrderStatus.PACKING"><span class="status-circle packing"></span> Packing</div>
        <div v-if="!cancelled && !future && status == OrderStatus.QA"><span class="status-circle qa"></span> QA</div>
        <div v-if="!cancelled && !future && status == OrderStatus.PASSED_QA"><span class="status-circle passed-qa"></span> Passed QA</div>
        <div v-if="!cancelled && !future && status == OrderStatus.DISPATCHED"><span class="status-circle dispatched"></span> Dispatched</div>
        <div v-if="!cancelled && future"><span class="status-circle future"></span> Future</div>
        <div v-if="cancelled"><span class="status-circle cancelled"></span> Cancelled</div>
    </div>
</template>

<script>
import OrderStatus from '@/enums/OrderStatus'
export default {
    name: 'OrderStatusesComponent',

    props: {
        status: Number,
        cancelled: Boolean,
        future: Boolean
    },

    data () {
        return {
            OrderStatus
        }
    }
}
</script>
