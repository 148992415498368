<template>
    <div>
        <p class="modal-heading">Raw Data</p>
        <div class="text-sm mt-3">
            <div class="raw-data-container">
                <div v-if="data.validationErrors.length" class="mt-4">
                    <p>Validation Errors</p>
                    <b-list-group>
                        <b-list-group-item v-for="(item, index) in data.validationErrors" :key="index" class="text-danger font-weight-bold">{{ `${item}: ${ValidationErrors[item]}` }}</b-list-group-item>
                    </b-list-group>
                </div>

                <div v-if="data.errorFlags.length" class="mt-4">
                    <p>Error Flags</p>
                    <b-list-group>
                        <b-list-group-item v-for="(item, index) in data.errorFlags" :key="index" class="text-danger font-weight-bold">{{ `${item}: ${ErrorFlags[item]}` }}</b-list-group-item>
                    </b-list-group>
                </div>

                <div class="table-responsive">
                    <table v-if="Object.keys(data.details).length" class="table table-striped table-bordered mt-4">
                        <thead>
                            <tr>
                                <th>Key</th>
                                <th>Value</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="(value, key, index) in data.details" :key="index">
                                <td>{{ key }}</td>
                                <td style="word-break: break-word">{{ value }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="mt-4 text-right">
                <button @click="close()" class="btn btn-cancel btn-lg lg-shadow">Close</button>
            </div>
        </div>
    </div>
</template>

<script>
import ValidationErrors from '@/errors/ValidationErrors'
import ErrorFlags from '@/errors/ErrorFlags'
export default {
    name: 'RawDataModalComponent',

    props: {
        data: Object
    },

    data () {
        return {
            ValidationErrors,
            ErrorFlags
        }
    },

    methods: {
        close () {
            this.$emit('close')
        }
    }
}
</script>
