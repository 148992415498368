require('vue-moment')
export default {
    methods: {
        formatDate (value) {
            if (value == null) return 'N/A'
            if (value === '0001-01-01T00:00:00') return 'Not issued'
            return this.$moment(value).format('DD/MM/YYYY')
        },

        formatDateTime (value) {
            if (value == null) return 'N/A'
            if (value === '0001-01-01T00:00:00') return 'N/A'
            return this.$moment(value).format('DD/MM/YYYY HH:mm:ss')
        },

        formatTitle (value) {
            if (value === 0) return 'Mr'
            if (value === 1) return 'Mrs'
            if (value === 2) return 'Miss'
            if (value === 3) return 'Ms'
            if (value === 4) return 'Dr'
            if (value === 5) return 'Sir'
            if (value === 6) return 'Professor'

            return ''
        },

        convertNullToInt (val) {
            if (val === null) return 0; return val
        },

        formatDistanceInMiles (value) {
            return `${value.toFixed(2)} miles`
        },

        formatAddress (value, forEncoding = false) {
            let addressString = ''
            let seperator = ', '
            if (forEncoding) {
                seperator = ' '
            }

            if (value.address1) addressString += `${value.address1}${seperator}`
            if (value.address2) addressString += `${value.address2}${seperator}`
            if (value.address3) addressString += `${value.address3}${seperator}`
            if (value.address4) addressString += `${value.address4}${seperator}`
            if (value.postalCode) addressString += `${value.postalCode}${seperator}`
            if (value.state) addressString += `${value.state}${seperator}`
            if (value.district) addressString += `${value.district}${seperator}`
            if (value.country) addressString += `${value.country}${seperator}`
            if (value.countryCode) addressString += `${value.countryCode}`

            return addressString
        },

        formatCollectionPointAddress (value) {
            let addressString = ''
            const seperator = ', '

            if (value.line) addressString += `${value.line}${seperator}`
            if (value.city) addressString += `${value.city}${seperator}`
            if (value.district) addressString += `${value.district}${seperator}`
            if (value.state) addressString += `${value.state}${seperator}`
            if (value.postalCode) addressString += `${value.postalCode}${seperator}`
            if (value.country) addressString += `${value.country}`

            return addressString
        },

        formatSerialNumber (value) {
            if (!value) return 'Not issued'

            return value
        },

        formatLanguageCode (value) {
            if (!value) return ''
            return value.displayId + '-' + value.code // + '-' + value.name
        },

        formatGA (value) {
            // lmp = latest menstrual period
            // ga = gestational age

            var current = new Date()
            var lmp = new Date(value)
            lmp.setDate(lmp.getDate() - 280)

            if (lmp > current) return null

            const diff = Math.abs(current - lmp)

            const gaInDays = Math.floor(diff / (1000 * 60 * 60 * 24))
            var gaWeeks = Math.floor(gaInDays / 7)

            return gaWeeks
        }
    }
}
