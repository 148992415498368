export default {
    methods: {
        convertStonesAndPoundsToKilos (stones, pounds, creating) {
            const stoneInKilos = 6.350
            const poundInKilos = 0.4536
            stones = stones * stoneInKilos
            pounds = pounds * poundInKilos

            if (creating) {
                this.form.weight = (stones + pounds).toFixed(2)
            } else {
                this.patient.weight = (stones + pounds).toFixed(2)
            }
        },

        convertKilosToStonesAndPounds (kg) {
            const weightInPounds = kg / 0.4536
            const stones = Math.floor(weightInPounds / 14)
            const pounds = weightInPounds - (14 * stones)

            this.stones = Math.round(stones)
            this.pounds = Math.round(pounds)

            return `${Math.round(stones)}st ${Math.round(pounds)}lbs`
        },

        convertKilosToStones (kg) {
            const weightInPounds = kg / 0.4536
            const stones = Math.floor(weightInPounds / 14)

            return Math.round(stones)
        },

        convertKilosToPounds (kg) {
            const weightInPounds = kg / 0.4536
            const stones = Math.floor(weightInPounds / 14)
            const pounds = weightInPounds - (14 * stones)

            return Math.round(pounds)
        },

        convertFeetAndInchesToCentimetres (feet, inches, creating) {
            const feetInCm = 30.48
            const inchInCm = 2.54
            feet = feet * feetInCm
            inches = inches * inchInCm

            if (creating) {
                this.form.height = (feet + inches).toFixed(2)
            } else {
                this.patient.height = (feet + inches).toFixed(2)
            }
        },

        convertCentimetresToFeetAndInches (cm) {
            const lengthInInches = cm / 2.54
            const feet = Math.floor(lengthInInches / 12)
            const inch = lengthInInches - (12 * feet)

            this.feet = feet
            this.inches = Math.round(inch)

            return `${feet}ft ${Math.round(inch)}in`
        },

        convertCentimetresToFeet (cm) {
            const lengthInInches = cm / 2.54
            const feet = Math.floor(lengthInInches / 12)

            return feet
        },

        convertCentimetresToInches (cm) {
            const lengthInInches = cm / 2.54
            const feet = Math.floor(lengthInInches / 12)
            const inch = lengthInInches - (12 * feet)

            return Math.round(inch)
        }
    }
}
