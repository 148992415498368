<template>
    <div>
        <Loader v-if="loading" />

        <div v-if="!loading && Object.keys(organisation).length">
            <Breadcrumbs
                :dynamicTitle="organisation.name"
            />

            <div class="card mt-3 text-sm">
                <div class="card-body">
                    <div class="d-flex flex-column flex-md-row justify-content-between">
                        <h5 class="mb-0">Organisation details</h5>
                        <div class="d-flex justify-content-end flex-column">
                            <button @click="save()" class="btn btn-success sm-shadow mt-4 mt-md-0 px-4 flex-grow-0">Save changes</button>
                        </div>
                    </div>

                    <span v-if="Object.keys(errors).length" class="text-sm d-block text-danger text-right mt-2">There are errors on the page. Please review them and try again</span>

                    <div class="row mt-4">
                        <div class="col-xl-5">
                            <div class="form-group row">
                                <label for="name" class="col-sm-5 col-form-label">Name <span class="text-danger">*</span></label>
                                <div class="col-sm-7">
                                    <input type="text" class="form-control" v-model="organisation.name">
                                    <small v-if="errors && errors.organisationName" class="text-danger">
                                        {{ errors.organisationName[0] }}
                                    </small>
                                </div>
                            </div>

                            <div v-if="organisation.countryCode == 'GB'" class="form-group row">
                                <label for="autocomplete" class="col-sm-5 col-form-label">Find Address</label>
                                <div class="col-sm-7">
                                    <input type="text" class="form-control" @input="findAddress()" v-model="addressQueryString" placeholder="Search for address" autocomplete="new-password" />
                                    <ul v-if="autocompleteSuggestions.length" class="list-group autocomplete-suggestions">
                                        <li v-for="(suggestion, index) in autocompleteSuggestions" :key="index" class="list-group-item" @click="chooseSuggestion(suggestion.id)">{{ suggestion.address }}</li>
                                    </ul>
                                </div>
                            </div>

                            <div v-if="expandManualAddress">
                                <div class="form-group row">
                                    <label for="address1" class="col-sm-5 col-form-label">Address 1 <span class="text-danger">*</span></label>
                                    <div class="col-sm-7">
                                        <input type="text" class="form-control" :class="{'border border-danger' : errors && errors.organisationAddress1}" id="address1" v-model="organisation.address1">
                                        <small v-if="errors && errors.organisationAddress1" class="text-danger">
                                            {{ errors.organisationAddress1[0] }}
                                        </small>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="address2" class="col-sm-5 col-form-label">Address 2 <span class="text-danger">*</span></label>
                                    <div class="col-sm-7">
                                        <input type="text" class="form-control" :class="{'border border-danger' : errors && errors.organisationAddress2}" id="address2" v-model="organisation.address2">
                                        <small v-if="errors && errors.organisationAddress2" class="text-danger">
                                            {{ errors.organisationAddress2[0] }}
                                        </small>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="address3" class="col-sm-5 col-form-label">Address 3</label>
                                    <div class="col-sm-7">
                                        <input type="text" class="form-control" :class="{'border border-danger' : errors && errors.organisationAddress3}" id="address3" v-model="organisation.address3">
                                        <small v-if="errors && errors.organisationAddress3" class="text-danger">
                                            {{ errors.organisationAddress3[0] }}
                                        </small>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label v-if="organisation.countryCode == 'GB'" for="address4" class="col-sm-5 col-form-label">Address 4</label>
                                    <label v-if="organisation.countryCode == 'US'" for="address4" class="col-sm-5 col-form-label">State </label>
                                    <div class="col-sm-7">
                                        <input type="text" class="form-control" :class="{'border border-danger' : errors && errors.organisationAddress4}" id="address4" v-model="organisation.address4">
                                        <small v-if="errors && errors.organisationAddress4" class="text-danger">
                                            {{ errors.organisationAddress4[0] }}
                                        </small>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label v-if="organisation.countryCode == 'GB'" for="postalCode" class="col-sm-5 col-form-label">Postcode <span class="text-danger">*</span></label>
                                    <label v-if="organisation.countryCode == 'US'" for="postalCode" class="col-sm-5 col-form-label">Zip Code <span class="text-danger">*</span></label>
                                    <div class="col-sm-7">
                                        <input type="text" class="form-control" :class="{'border border-danger' : errors && errors.organisationPostalCode}" id="postalCode" v-model="organisation.postalCode">
                                        <small v-if="errors && errors.organisationPostalCode" class="text-danger">
                                            {{ errors.organisationPostalCode[0] }}
                                        </small>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="country" class="col-sm-5 col-form-label">Country <span class="text-danger">*</span></label>
                                    <div class="col-sm-7">
                                        <input type="text" class="form-control" :class="{'border border-danger' : errors && errors.organisationCountry}" id="country" v-model="organisation.country">
                                        <small v-if="errors && errors.organisationCountry" class="text-danger">
                                            {{ errors.organisationCountry[0] }}
                                        </small>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="country-code" class="col-sm-5 col-form-label">Country Code <span class="text-danger">*</span></label>
                                    <div class="col-sm-7">
                                        <input disabled type="text" class="form-control" :class="{'border border-danger' : errors && errors.organisationCountryCode}" id="country-code" v-model="organisation.countryCode">
                                        <small v-if="errors && errors.organisationCountryCode" class="text-danger">
                                            {{ errors.organisationCountryCode[0] }}
                                        </small>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="fulfilment-provider" class="col-sm-5 col-form-label">Fulfilment provider <span class="text-danger">*</span></label>
                                <div class="col-sm-7">
                                    <select type="text" class="custom-select" v-model="organisation.fulfilmentProvider">
                                        <option value="0">Digostics</option>
                                    </select>
                                    <small v-if="errors && errors.organisationFulfimentProvider" class="text-danger">
                                        {{ errors.organisationFulfimentProvider[0] }}
                                    </small>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="patient-identifier-label" class="col-sm-5 col-form-label">Patient identifier label <span class="text-danger">*</span></label>
                                <div class="col-sm-7">
                                    <input type="text" class="form-control" v-model="organisation.patientIdentifierLabel">
                                    <small v-if="errors && errors.organisationPatientIdentifierLabel" class="text-danger">
                                        {{ errors.organisationPatientIdentifierLabel[0] }}
                                    </small>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="patient-identifier-validation-regex" class="col-sm-5 col-form-label">Patient identifier validation regex <span class="text-danger">*</span></label>
                                <div class="col-sm-7">
                                    <input type="text" class="form-control" v-model="organisation.patientIdentifierValidationRegex">
                                    <small v-if="errors && errors.organisationPatientIdentifierValidationRegex" class="text-danger">
                                        {{ errors.organisationPatientIdentifierValidationRegex[0] }}
                                    </small>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="patient-identifier-error-message" class="col-sm-5 col-form-label">Patient identifier error message <span class="text-danger">*</span></label>
                                <div class="col-sm-7">
                                    <input type="text" class="form-control" v-model="organisation.patientIdentifierErrorMessage">
                                    <small v-if="errors && errors.organisationPatientIdentifierErrorMessage" class="text-danger">
                                        {{ errors.organisationPatientIdentifierErrorMessage[0] }}
                                    </small>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="default-phone-country" class="col-sm-5 col-form-label">Default phone country code <span class="text-danger">*</span></label>
                                <div class="col-sm-7">
                                    <input disabled type="text" class="form-control" v-model="organisation.defaultPhoneCountryCode">
                                    <small v-if="errors && errors.organisationDefaultPhoneCountryCode" class="text-danger">
                                        {{ errors.organisationDefaultPhoneCountryCode[0] }}
                                    </small>
                                </div>
                            </div>

                            <div class="form-group row align-items-center">
                                <label for="status" class="col-sm-5 col-form-label">Status <span class="text-danger">*</span></label>
                                <div class="col-sm-7">
                                    <b-form-checkbox v-model="organisation.isActive" name="check-button" switch class="custom-switch-md">
                                        {{ organisation.isActive ? 'Active' : 'Inactive' }}
                                    </b-form-checkbox>
                                </div>
                            </div>

                            <div class="form-group row align-items-center">
                                <label for="is-trial" class="col-sm-5 col-form-label">Is Trial? <span class="text-danger">*</span></label>
                                <div class="col-sm-7">
                                    <b-form-checkbox v-model="organisation.isTrial" name="check-button" switch class="custom-switch-md">
                                        {{ organisation.isTrial ? 'Yes' : 'No' }}
                                    </b-form-checkbox>
                                </div>
                            </div>

                            <div v-if="!organisation.dataStoreId">
                                <div v-if="userIsOwner" class="form-group row align-items-center">
                                    <label for="datastore" class="col-sm-5 col-form-label">Data Store <span class="text-danger">*</span></label>
                                    <div class="col-sm-7">
                                        <select class="custom-select" v-model="form.organisationDataStore">
                                            <option value="">Please select an option</option>
                                            <option v-for="(store, index) in dataStores.results" :key="index" :value="store.id">{{ store.name }}</option>
                                        </select>
                                    </div>
                                </div>

                                <div v-else class="form-group row align-items-center">
                                    <label for="datastore" class="col-sm-5 col-form-label">Data Store <span class="text-danger">*</span></label>
                                    <div class="col-sm-7">
                                        <em>Only an owner can select a data store</em>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <h5 class="mb-0 mt-5">Primary contact</h5>

                    <div class="row mt-4">
                        <div class="col-xl-5">
                            <div class="form-group row">
                                <label for="title" class="col-sm-5 col-form-label">Title <span class="text-danger">*</span></label>
                                <div class="col-sm-7">
                                    <select type="text" class="custom-select" v-model="organisation.title">
                                        <option :value="Salutation.MR">Mr</option>
                                        <option :value="Salutation.MRS">Mrs</option>
                                        <option :value="Salutation.MISS">Miss</option>
                                        <option :value="Salutation.MS">Ms</option>
                                        <option :value="Salutation.DR">Dr</option>
                                        <option :value="Salutation.SIR">Sir</option>
                                        <option :value="Salutation.PROFESSOR">Professor</option>
                                    </select>
                                    <small v-if="errors && errors.title" class="text-danger">
                                        {{ errors.title[0] }}
                                    </small>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="first-name" class="col-sm-5 col-form-label">First name <span class="text-danger">*</span></label>
                                <div class="col-sm-7">
                                    <input type="text" class="form-control" v-model="organisation.firstName">
                                    <small v-if="errors && errors.firstName" class="text-danger">
                                        {{ errors.firstName[0] }}
                                    </small>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="middle-names" class="col-sm-5 col-form-label">Middle names</label>
                                <div class="col-sm-7">
                                    <input type="text" class="form-control" v-model="organisation.middleNames">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="surname" class="col-sm-5 col-form-label">Surname <span class="text-danger">*</span></label>
                                <div class="col-sm-7">
                                    <input type="text" class="form-control" v-model="organisation.surname">
                                    <small v-if="errors && errors.surname" class="text-danger">
                                        {{ errors.surname[0] }}
                                    </small>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="phone-number" class="col-sm-5 col-form-label">Phone number <span class="text-danger">*</span></label>
                                <div class="col-sm-7">
                                    <input type="text" class="form-control" v-model="organisation.phone">
                                    <small v-if="errors && errors.phone" class="text-danger">
                                        {{ errors.phone[0] }}
                                    </small>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="email" class="col-sm-5 col-form-label">Email address <span class="text-danger">*</span></label>
                                <div class="col-sm-7">
                                    <input type="text" class="form-control" v-model="organisation.email">
                                    <small v-if="errors && errors.email" class="text-danger">
                                        {{ errors.email[0] }}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!loading" class="mt-5">
            <h5>Facilities ({{ results.results.length }})</h5>

            <b-table
                v-if="results.results.length"
                ref="table"
                id="table"
                :sort-by.sync="sortBySync"
                :sort-desc.sync="sortDesc"
                @sort-changed="sortingChanged"
                :no-local-sorting="true"
                :busy.sync="isBusy"
                class="custom-table padding-lg bg-white mt-4"
                responsive
                no-sort-reset
                :items="results.results"
                :fields="filteredFields"
            >
                <template #cell(name)="data">
                    <router-link class="font-weight-bold underlined text-capitalize" :to="{name: 'ViewFacility', params: {oId : $route.params.id, fId : data.item.id}}">{{ data.item.name }}</router-link>
                </template>

                <template #cell(isActive)="data">
                    <span :class="{'text-success' : data.item.isActive}">{{ data.item.isActive ? "ACTIVE" : "INACTIVE" }}</span>
                </template>
            </b-table>

            <div class="mt-4">
                <b-pagination
                    v-if="results.results.length"
                    v-model="currentPage"
                    :current-page="currentPage"
                    :per-page="results.pageSize"
                    :total-rows="results.totalCount"
                    prev-text="< Previous"
                    next-text="Next >"
                    @change="pageChange"
                    aria-controls="tests-table"
                    hide-goto-end-buttons
                    prev-class="prev-page"
                    next-class="next-page"
                    align="right"
                ></b-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import Loader from '@/partials/Loader'
import FormatterMixin from '@/mixins/FormatterMixin'
import Breadcrumbs from '@/partials/site-structure/BreadcrumbsComponent'
import Salutation from '@/enums/Salutation'
export default {
    name: 'EditOrganisation',

    components: {
        Loader,
        Breadcrumbs
    },

    mixins: [
        FormatterMixin
    ],

    data () {
        return {
            loading: true,
            addressQueryString: null,
            autocompleteSuggestions: [],
            organisation: {},
            results: {},
            expandManualAddress: true,
            isBusy: false,
            currentPage: 1,
            pageSize: process.env.VUE_APP_PAGINATION_PAGE_SIZE,
            offset: 0,
            sortColumn: 'NAME',
            sortType: 'ASCENDING',
            sortDesc: false,
            sortBySync: 'name',
            errors: {},
            Salutation,
            dataStores: [],
            form: {
                organisationDataStore: ''
            }
        }
    },

    async mounted () {
        try {
            await this.getOrganisation()
            await this.getFacilities()
            const dataStoresResponse = await axios.get(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/datastores/100/0`)
            this.dataStores = dataStoresResponse.data
        } catch {
            this.setNotification({
                type: 'error',
                title: 'Error',
                message: 'Something went wrong while fetching the data. Please try again.'
            })
        } finally {
            this.loading = false
        }
    },

    computed: {
        ...mapGetters({
            userIsOwner: 'UserStore/GetIsUserOwner'
        }),
        filteredFields () {
            return [
                {
                    key: 'name',
                    label: 'Name',
                    sortable: true
                },

                {
                    key: 'testsIssuedCount',
                    label: 'Tests Issued',
                    formatter: 'convertNullToInt',
                    sortable: true
                },

                {
                    key: 'testsCompleteCount',
                    label: 'Tests Complete',
                    formatter: 'convertNullToInt',
                    sortable: true
                },

                {
                    key: 'isActive',
                    label: 'Status',
                    sortable: true
                }
            ]
        }
    },

    methods: {
        ...mapActions({
            setNotification: 'NotificationStore/SetNotification'
        }),

        async getOrganisation () {
            try {
                const response = await axios.get(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/organisations/${this.$route.params.id}`)

                this.organisation = response.data
            } catch {
                throw new Error('Something went wrong while fetching the organisation. Please try again.')
            }
        },

        async getFacilities () {
            this.isBusy = true

            try {
                const response = await axios.get(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/facilities/${this.$route.params.id}/${this.pageSize}/${this.offset}/${this.sortColumn}/${this.sortType}`)

                this.results = response.data
            } catch {
                throw new Error('Something wentr wrong while fetching the facilities. Please try again.')
            } finally {
                this.isBusy = false
            }
        },

        async findAddress () {
            const response = await axios.get(`https://api.getAddress.io/autocomplete/${this.addressQueryString}?api-key=${process.env.VUE_APP_GET_ADDRESS_API_KEY}`)

            this.autocompleteSuggestions = response.data.suggestions
        },

        async chooseSuggestion (id) {
            this.clearAddress()

            const response = await axios.get(`https://api.getAddress.io/get/${id}?api-key=${process.env.VUE_APP_GET_ADDRESS_API_KEY}`)

            if (response.data) {
                this.autocompleteSuggestions = []
                this.addressQueryString = null
                this.expandManualUkAddress = true
                this.organisation.address1 = response.data.line_1
                this.organisation.address2 = response.data.town_or_city
                this.organisation.address3 = response.data.county
                this.organisation.postalCode = response.data.postcode
                this.organisation.country = response.data.country
                this.organisation.latitude = response.data.latitude
                this.organisation.longitude = response.data.longitude
            }
        },

        clearAddress () {
            this.fullAddress = null
            this.organisation.address1 = null
            this.organisation.address2 = null
            this.organisation.address3 = null
            this.organisation.postalCode = null
            this.organisation.country = null
            this.organisation.latitude = null
            this.organisation.longitude = null
        },

        async save () {
            this.checkForm()

            if (!Object.keys(this.errors).length) {
                this.loading = true

                try {
                    await axios.patch(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/organisations/${this.$route.params.id}`, this.organisation)

                    if (this.userIsOwner && this.form.organisationDataStore) {
                        await axios.patch(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/datastores/${this.organisation.id}/${this.form.organisationDataStore}`)
                    }

                    this.$router.push({ name: 'ViewOrganisation', params: { id: this.$route.params.id } })

                    return this.setNotification({
                        type: 'success',
                        title: 'Success',
                        message: 'The organisation was updated.'
                    })
                } catch (err) {
                    this.setNotification({
                        type: 'error',
                        title: 'Error',
                        message: err.data.detail ? err.data.detail : 'There was an error. The device was not assigned.'
                    })
                } finally {
                    this.loading = false
                }
            }
        },

        pageChange (value) {
            this.currentPage = value
            this.offset = (value - 1) * this.pageSize
            this.getFacilities()
        },

        sortingChanged (e) {
            switch (e.sortBy) {
                case 'name':
                    this.sortColumn = 'NAME'
                break

                case 'testsIssuedCount':
                    this.sortColumn = 'TESTS_ISSUED'
                break

                case 'testsCompleteCount':
                    this.sortColumn = 'TESTS_COMPLETE'
                break

                case 'isActive':
                    this.sortColumn = 'STATUS'
                break
            }

            this.sortDesc = e.sortDesc
            this.sortBySync = e.sortBy

            if (this.sortDesc) {
                this.sortType = 'DESCENDING'
            } else {
                this.sortType = 'ASCENDING'
            }

            this.getFacilities()
        },

        checkForm () {
            this.errors = {}

            if (!this.organisation.name) {
                this.errors.organisationName = ['The organisation name field is required']
            }

            if (this.organisation.fulfilmentProvider == null || parseInt(this.organisation.fulfilmentProvider) < 0) {
                this.errors.organisationFulfimentProvider = ['The organisation fulfilment provider field is required']
            }

            if (this.organisation.title == null || parseInt(this.organisation.title) < 0) {
                this.errors.title = ['The contact title field is required']
            }

            if (!this.organisation.firstName) {
                this.errors.firstName = ['The contact first name field is required']
            }

            if (!this.organisation.surname) {
                this.errors.surname = ['The contact surname field is required']
            }

            if (!this.organisation.phone) {
                this.errors.phone = ['The contact phone number field is required']
            }

            if (this.organisation.phone != null && !this.organisation.phone.match(/[0-9]{9,20}/)) {
                this.errors.phone = ['The phone number must be a minimum of 9 digits']
            }

            if (!this.organisation.email) {
                this.errors.email = ['The contact email field is required']
            }

            if (this.organisation.email != null && !this.organisation.email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                this.errors.email = ['This not a valid email address']
            }

            if (!this.organisation.address1) {
                this.errors.organisationAddress1 = ['The organisation address 1 field is required']
            }

            if (!this.organisation.address2) {
                this.errors.organisationAddress2 = ['The organisation address 2 field is required']
            }

            if (!this.organisation.postalCode) {
                this.errors.organisationPostalCode = ['The organisation postal code field is required']
            }

            if (!this.organisation.country) {
                this.errors.organisationCountry = ['The organisation country field is required']
            }

            if (!this.organisation.countryCode) {
                this.errors.organisationCountryCode = ['The organisation country code field is required']
            }

            if (!this.organisation.patientIdentifierLabel) {
                this.errors.organisationPatientIdentifierLabel = ['The organisation patient identifier label field is required']
            }

            if (!this.organisation.patientIdentifierValidationRegex) {
                this.errors.organisationPatientIdentifierValidationRegex = ['The organisation patient identifier validation regex field is required']
            }

            if (!this.organisation.patientIdentifierErrorMessage) {
                this.errors.organisationPatientIdentifierErrorMessage = ['The organisation patient identifier error message field is required']
            }

            if (!this.organisation.defaultPhoneCountryCode) {
                this.errors.organisationDefaultPhoneCountryCode = ['The organisation default phone country code field is required']
            }
        }
    }
}
</script>
