<template>
    <div class="row mt-4">
        <div class="col">
            <div class="card pb-5">
                <div class="card-body">
                    <h5 class="mb-0">Timeline</h5>

                    <div v-if="!events.length" class="alert alert-info my-4">
                        There are no test events to show
                    </div>

                    <div v-else>
                        <div v-for="(event, index) in events" :key="index">
                            <div v-if="showPill(event)" class="timeline my-2">
                                <div class="timeline-item w-mobile-100"
                                    :class="{
                                        'created' : event.eventType == TestEventType.CREATED,
                                        'assigned' : event.eventType == TestEventType.ASSIGNED,
                                        'issued' : event.eventType == TestEventType.ISSUED,
                                        'fasting-completed' : event.eventType == TestEventType.TEST_A_COMPLETED,
                                        'two-hour-completed' : event.eventType == TestEventType.TEST_B_COMPLETED,
                                        'results-received' : event.eventType == TestEventType.RECEIVED,
                                        'marked-complete' : event.eventType == TestEventType.COMPLETED,
                                        'marked-cancelled' : event.eventType == TestEventType.CANCELLED,
                                        'marked-reported' : event.eventType == TestEventType.REPORTED,
                                        'marked-errored' : event.eventType == TestEventType.ERROR,
                                        'marked-failed' : event.eventType == TestEventType.FAILED,
                                        'notification' : event.eventType == TestEventType.NOTIFICATION,
                                        'overridden' : event.eventType == TestEventType.OVERRIDDEN,
                                        'reordered' : event.eventType == TestEventType.REORDERED,
                                        'accepted' : event.eventType == TestEventType.ACCEPTED,
                                        'has-link-line' : index > 0
                                    }"
                                >
                                    <p class="font-weight-bold text-black mb-1" v-if="event.eventType == TestEventType.CREATED">Test created</p>
                                    <p class="font-weight-bold text-black mb-1" v-if="event.eventType == TestEventType.ASSIGNED">Device assigned</p>
                                    <p class="font-weight-bold text-black mb-1" v-if="event.eventType == TestEventType.ISSUED">Issued</p>
                                    <p class="font-weight-bold text-black mb-1" v-if="event.eventType == TestEventType.TEST_A_COMPLETED">Fasting test completed</p>
                                    <p class="font-weight-bold text-black mb-1" v-if="event.eventType == TestEventType.TEST_B_COMPLETED">2 hour test completed</p>
                                    <p class="font-weight-bold text-black mb-1" v-if="event.eventType == TestEventType.RECEIVED">Results received</p>
                                    <p class="font-weight-bold text-black mb-1" v-if="event.eventType == TestEventType.COMPLETED">Complete</p>
                                    <p class="font-weight-bold mb-1" v-if="event.eventType == TestEventType.CANCELLED">Cancelled</p>
                                    <p class="font-weight-bold text-black mb-1" v-if="event.eventType == TestEventType.REPORTED">Reported</p>
                                    <p class="font-weight-bold mb-1" v-if="event.eventType == TestEventType.ERROR">Errored</p>
                                    <p class="font-weight-bold mb-1" v-if="event.eventType == TestEventType.FAILED">Failed</p>
                                    <p class="font-weight-bold mb-1" v-if="event.eventType == TestEventType.OVERRIDDEN">Overridden</p>
                                    <p class="font-weight-bold mb-1" v-if="event.eventType == TestEventType.REORDERED">Reordered</p>
                                    <p class="font-weight-bold mb-1" v-if="event.eventType == TestEventType.ACCEPTED">Accepted</p>
                                    <p class="font-weight-bold text-black mb-1" v-if="event.eventType == TestEventType.NOTIFICATION">Notification sent</p>
                                    <p class="text-sm mb-1">{{ event.eventDate | moment("DD/MM/YYYY HH:mm") }}</p>
                                    <p v-if="event.details == 'IN_PERSON'" class="font-italic text-sm mb-0">In person</p>
                                    <p v-else class="font-italic text-sm mb-0">
                                        <span v-if="event.testLinkId != null" @click="viewTest(event.testLinkId)" class="underlined text-capitalize cursor-pointer">{{ event.testLinkText }} </span>{{ event.details }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TestEventType from '@/enums/TestEventType'
import { mapActions } from 'vuex'
import TestStatus from '@/enums/TestStatus'
export default {
    name: 'TestEventsComponent',

    props: {
        events: Array,
        results: {}
    },

    data () {
        return {
            TestEventType,
            TestStatus
        }
    },

    methods: {
        ...mapActions({
            setShouldRefreshViewTestComponent: 'AppStore/SetShouldRefreshViewTestComponent'
        }),
        async viewTest (testId) {
            await this.$router.push({ name: 'ViewTest', params: { oId: this.$route.params.oId, tId: testId } })
            this.setShouldRefreshViewTestComponent(true)
        },
        showPill (event) {
            // if ((event.eventType === TestEventType.RECEIVED || event.eventType === TestEventType.ERROR || event.eventType === TestEventType.FAILED) && this.results.resultsReviewed === false) {
            //     return false
            // }
            return true
        }
    }
}
</script>
