<template>
    <div>
        <div class="row align-items-center">
            <div class="col-md-6">
                <h2 class="mb-0">Domains</h2>
            </div>

            <div class="col-md-6 text-right">
                <button @click="addDomain()" class="btn btn-success btn-block-mobile btn-lg lg-shadow mt-4 mt-md-0">Add a domain</button>
            </div>
        </div>

        <Loader v-if="loading" />

        <div v-else class="mt-45">
            <div class="row justify-content-between align-items-center">
                <div class="col">
                    <h5 class="mb-0">All <span class="font-italic">({{ results.totalCount }})</span></h5>
                </div>

                <div class="col-md-4 mt-2 mt-md-0">
                    <div class="d-flex flex-column flex-md-row justify-content-end">
                        <input class="form-control searchbar-input" type="text" placeholder="Search domains" v-model="query" />
                        <button onclick="this.blur();" :disabled="isBusy" @click="search()" class="btn btn-success btn-form sm-shadow ml-0 ml-md-2 my-2 my-md-0" :class="{disabled : isBusy}">Search</button>
                        <button onclick="this.blur();" @click="resetSearch()" class="btn btn-yellow btn-form sm-shadow ml-0 ml-md-2" :class="{disabled : isBusy}">Reset</button>
                    </div>
                </div>
            </div>

            <b-table
                v-if="results.results.length"
                ref="table"
                id="table"
                :sort-by.sync="sortBySync"
                :sort-desc.sync="sortDesc"
                @sort-changed="sortingChanged"
                :no-local-sorting="true"
                :busy.sync="isBusy"
                class="custom-table padding-lg bg-white mt-4"
                responsive
                no-sort-reset
                :items="results.results"
                :fields="filteredFields"
            >
                <template #cell(isActive)="data">
                    <span :class="{'text-success' : data.item.isActive}">{{ data.item.isActive ? "ACTIVE" : "INACTIVE" }}</span>
                </template>

                <template #cell(action)="data">
                    <button @click="editDomain(data.item.id, data.item.name)" class="btn btn-primary px-4 sm-shadow text-nowrap">Edit</button>
                </template>
            </b-table>

            <div class="mt-4 d-flex flex-column flex-md-row align-items-center justify-content-end">
                <div class="form-group d-flex align-items-center mb-4 mb-md-0">
                    <label class="flex-shrink-0 mr-2">Items per page</label>
                    <select class="custom-select" v-model="pageSize" @change="handleChangePageSize()">
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                    </select>
                </div>

                <div>
                    <b-pagination
                        v-if="results.results.length"
                        v-model="currentPage"
                        :current-page="currentPage"
                        :per-page="results.pageSize"
                        :total-rows="results.totalCount"
                        prev-text="< Previous"
                        next-text="Next >"
                        @change="pageChange"
                        aria-controls="tests-table"
                        hide-goto-end-buttons
                        prev-class="prev-page"
                        next-class="next-page"
                        align="right"
                        class="mb-0"
                    ></b-pagination>
                </div>
            </div>
        </div>

        <div v-if="!loading && !results.results.length">
            <div class="row mt-45">
                <div class="col-md-6">
                    <div class="alert alert-info">
                        There are no domains to show
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import Loader from '@/partials/Loader.vue'
import FormatterMixin from '@/mixins/FormatterMixin'
import AddEditDomainModalComponent from '@/components/modals/AddEditDomainModalComponent'
export default {
    name: 'Domains',

    components: {
        Loader
    },

    mixins: [
        FormatterMixin
    ],

    data () {
        return {
            loading: true,
            results: {},
            query: '',
            isBusy: false,
            currentPage: 1,
            pageSize: process.env.VUE_APP_PAGINATION_PAGE_SIZE,
            offset: 0,
            sortColumn: 'NAME',
            sortType: 'ASCENDING',
            sortDesc: false,
            sortBySync: 'name'
        }
    },

    async mounted () {
        await this.getDomains()
    },

    computed: {
        ...mapGetters({
            userIsOwner: 'UserStore/GetIsUserOwner'
        }),

        filteredFields () {
            return [
                {
                    key: 'name',
                    label: 'Name',
                    sortable: true
                },

                {
                    key: 'dateCreated',
                    label: 'Date Created',
                    formatter: 'formatDate',
                    sortable: false
                },

                {
                    key: 'dateUpdated',
                    label: 'Date Updated',
                    formatter: 'formatDate',
                    sortable: false
                },

                {
                    key: 'isActive',
                    label: 'Status',
                    sortable: false
                },

                {
                    key: 'action',
                    label: ''
                }
            ]
        }
    },

    methods: {
        ...mapActions({
            setNotification: 'NotificationStore/SetNotification'
        }),

        addDomain () {
            this.$modal.hideAll()
            this.$modal.show(AddEditDomainModalComponent, {}, { width: '440px' }, { 'before-close': this.closingDomainModal })
        },

        editDomain (id, name) {
            this.$modal.hideAll()
            this.$modal.show(AddEditDomainModalComponent, { initialDomainId: id, initialDomainName: name }, { width: '440px' }, { 'before-close': this.closingDomainModal })
        },

        async closingDomainModal () {
            await this.getDomains()
        },

        async getDomains () {
            this.isBusy = true

            try {
                var response
                if (this.query) {
                    response = await axios.get(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/domains/${this.query}/${this.pageSize}/${this.offset}/${this.sortColumn}/${this.sortType}`)
                } else {
                    response = await axios.get(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/domains/${this.pageSize}/${this.offset}/${this.sortColumn}/${this.sortType}`)
                }

                this.results = response.data
            } catch {
                this.setNotification({
                    type: 'error',
                    title: 'Error',
                    message: 'Something went wrong while fetching the domains. Please try again.'
                })
            } finally {
                this.loading = false
                this.isBusy = false
            }
        },

        async resetSearch () {
            this.query = ''
            await this.search()
        },

        async search () {
            this.currentPage = 1
            this.offset = 0
            await this.getDomains()
        },

        pageChange (value) {
            this.currentPage = value
            this.offset = (value - 1) * this.pageSize
            this.getDomains()
        },

        sortingChanged (e) {
            switch (e.sortBy) {
                case 'name':
                    this.sortColumn = 'NAME'
                break

                case 'dateCreated':
                    this.sortColumn = 'DATE_CREATED'
                break

                case 'dateUpdated':
                    this.sortColumn = 'DATE_UPDATED'
                break

                case 'isActive':
                    this.sortColumn = 'STATUS'
                break
            }

            this.sortDesc = e.sortDesc
            this.sortBySync = e.sortBy

            if (this.sortDesc) {
                this.sortType = 'DESCENDING'
            } else {
                this.sortType = 'ASCENDING'
            }

            this.getDomains()
        },

        handleChangePageSize () {
            this.currentPage = 1
            this.offset = 0

            this.getDomains()
        }
    }
}
</script>
