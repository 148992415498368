import Vue from 'vue'
import VueRouter from 'vue-router'
import Error404 from '../views/Error404.vue'
import Dashboard from '../views/Dashboard.vue'
import MyAccount from '../views/MyAccount.vue'
import Domains from '../views/Domains.vue'
import Organisations from '../views/Organisations.vue'
import CreateOrganisation from '../views/CreateOrganisation.vue'
import ViewOrganisation from '../views/ViewOrganisation.vue'
import EditOrganisation from '../views/EditOrganisation.vue'
import CreateFacility from '../views/CreateFacility.vue'
import ViewFacility from '../views/ViewFacility.vue'
import EditFacility from '../views/EditFacility.vue'
import Results from '../views/Results.vue'
import AdvancedSearch from '../views/AdvancedSearch.vue'
import Users from '../views/Users.vue'
import EditUser from '../views/EditUser.vue'
import CreateUser from '../views/CreateUser.vue'
import CreateHcp from '../views/CreateHcp.vue'
import ViewHcp from '../views/ViewHcp.vue'
import EditHcp from '../views/EditHcp.vue'
import CollectionPoints from '../views/CollectionPoints.vue'
import CreateCollectionPoint from '../views/CreateCollectionPoint.vue'
import EditCollectionPoint from '../views/EditCollectionPoint.vue'
import ViewTest from '../views/ViewTest.vue'
import FulfilmentOverview from '../views/fulfilment/Overview.vue'
import FulfilmentOpen from '../views/fulfilment/Open.vue'
import FulfilmentPacking from '../views/fulfilment/Packing.vue'
import FulfilmentQa from '../views/fulfilment/QA.vue'
import FulfilmentPassedQa from '../views/fulfilment/PassedQa.vue'
import FulfilmentDispatched from '../views/fulfilment/Dispatched.vue'
import FulfilmentFutureOrders from '../views/fulfilment/FutureOrders.vue'
import FulfilmentCancelled from '../views/fulfilment/Cancelled.vue'
import FulfilmentSearch from '../views/fulfilment/AdvancedSearch.vue'
import ViewOrder from '../views/ViewOrder.vue'
import ReviewResults from '../views/ReviewResults.vue'

import store from '@/store'
import MiddlewarePipeline from './kernel/MiddlewarePipeline'
import UserMustBeOwner from './middleware/UserMustBeOwner'
import UserMustBeAdminOrOwner from './middleware/UserMustBeAdminOrOwner'
import RedirectIfUserIsFulfilment from './middleware/RedirectIfUserIsFulfilment'
import UserMustBeDomainAdmin from './middleware/UserMustBeDomainAdmin'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'Error404',
    component: Error404
  },
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      middleware: [
        RedirectIfUserIsFulfilment
      ]
    }
  },
  {
    path: '/my-account',
    name: 'MyAccount',
    component: MyAccount,
    meta: {
      breadcrumbTitle: 'My Account'
    }
  },
  {
    path: '/domains',
    name: 'Domains',
    component: Domains,
    meta: {
      middleware: [
        UserMustBeDomainAdmin
      ]
    }
  },
  {
    path: '/organisations',
    name: 'Organisations',
    component: Organisations,
    meta: {
      middleware: [
        UserMustBeAdminOrOwner
      ]
    }
  },
  {
    path: '/organisations/create',
    name: 'CreateOrganisation',
    component: CreateOrganisation,
    meta: {
      breadcrumbTitle: 'Add an organisation',
      breadcrumbs: [
        {
          title: 'Organisations',
          link: 'Organisations'
        }
      ],
      middleware: [
        UserMustBeAdminOrOwner
      ]
    }
  },
  {
    path: '/organisations/:id',
    name: 'ViewOrganisation',
    component: ViewOrganisation,
    meta: {
      middleware: [
        UserMustBeAdminOrOwner
      ],
      breadcrumbs: [
        {
          title: 'Organisations',
          link: 'Organisations'
        }
      ]
    }
  },
  {
    path: '/organisations/:id/edit',
    name: 'EditOrganisation',
    component: EditOrganisation,
    meta: {
      middleware: [
        UserMustBeAdminOrOwner
      ],
      breadcrumbs: [
        {
          title: 'Organisations',
          link: 'Organisations'
        }
      ]
    }
  },
  {
    path: '/organisations/:id/create-facility',
    name: 'CreateFacility',
    component: CreateFacility,
    props: true,
    meta: {
      breadcrumbTitle: 'Add a facility',
      breadcrumbs: [
        {
          title: 'Organisations',
          link: 'Organisations'
        }
      ],
      middleware: [
        UserMustBeAdminOrOwner
      ]
    }
  },
  {
    path: '/facilities/:oId/:fId',
    name: 'ViewFacility',
    component: ViewFacility,
    meta: {
      breadcrumbs: [
        {
          title: 'Organisations',
          link: 'Organisations'
        }
      ],
      middleware: [
        UserMustBeAdminOrOwner
      ]
    }
  },
  {
    path: '/facilities/:oId/:fId/edit',
    name: 'EditFacility',
    component: EditFacility,
    breadcrumbs: [
      {
        title: 'Organisations',
        link: 'Organisations'
      }
    ],
    meta: {
      middleware: [
        UserMustBeAdminOrOwner
      ]
    }
  },
  {
    path: '/results',
    name: 'Results',
    component: Results,
    meta: {
      middleware: [
        UserMustBeAdminOrOwner
      ]
    }
  },
  {
    path: '/results/advanced-search',
    name: 'AdvancedSearch',
    component: AdvancedSearch,
    meta: {
      breadcrumbs: [
        {
          title: 'Results',
          link: 'Results'
        }
      ],
      middleware: [
        UserMustBeAdminOrOwner
      ]
    }
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    meta: {
      breadcrumbs: [
        {
          title: 'Users',
          link: 'Users'
        }
      ],
      middleware: [
        UserMustBeOwner
      ]
    }
  },
  {
    path: '/users/create',
    name: 'CreateUser',
    component: CreateUser,
    meta: {
      breadcrumbTitle: 'Add a user',
      breadcrumbs: [
        {
          title: 'Users',
          link: 'Users'
        }
      ],
      middleware: [
        UserMustBeOwner
      ]
    }
  },
  {
    path: '/users/:id/edit',
    name: 'EditUser',
    component: EditUser,
    props: true,
    meta: {
      breadcrumbTitle: 'Edit a user',
      breadcrumbs: [
        {
          title: 'Users',
          link: 'Users'
        }
      ],
      middleware: [
        UserMustBeOwner
      ]
    }
  },
  {
    path: '/organisations/:oId/practitioners/create',
    name: 'CreateHcp',
    component: CreateHcp,
    props: true,
    meta: {
      breadcrumbs: [
        {
          title: 'Organisations',
          link: 'Organisations'
        }
      ],
      middleware: [
        UserMustBeAdminOrOwner
      ]
    }
  },
  {
    path: '/organisations/:oId/practitioners/:pId',
    name: 'ViewHcp',
    component: ViewHcp,
    props: true,
    meta: {
      breadcrumbs: [
        {
          title: 'Organisations',
          link: 'Organisations'
        }
      ],
      middleware: [
        UserMustBeAdminOrOwner
      ]
    }
  },
  {
    path: '/organisations/:oId/practitioners/:pId/edit',
    name: 'EditHcp',
    component: EditHcp,
    props: true,
    meta: {
      breadcrumbs: [
        {
          title: 'Organisations',
          link: 'Organisations'
        }
      ],
      middleware: [
        UserMustBeAdminOrOwner
      ]
    }
  },
  {
    path: '/collection-points',
    name: 'CollectionPoints',
    component: CollectionPoints,
    meta: {
      middleware: [
        UserMustBeAdminOrOwner
      ]
    }
  },
  {
    path: '/collection-points/create',
    name: 'CreateCollectionPoint',
    component: CreateCollectionPoint,
    meta: {
      breadcrumbTitle: 'Add a collection point',
      breadcrumbs: [
        {
          title: 'Collection Points',
          link: 'CollectionPoints'
        }
      ],
      middleware: [
        UserMustBeAdminOrOwner
      ]
    }
  },
  {
    path: '/collection-points/:id/edit',
    name: 'EditCollectionPoint',
    component: EditCollectionPoint,
    meta: {
      breadcrumbTitle: 'Edit a collection point',
      breadcrumbs: [
        {
          title: 'Collection Points',
          link: 'CollectionPoints'
        }
      ],
      middleware: [
        UserMustBeAdminOrOwner
      ]
    }
  },
  {
    path: '/results/tests/:oId/:tId',
    name: 'ViewTest',
    component: ViewTest,
    meta: {
      breadcrumbs: [
        {
          title: 'Results',
          link: 'Results'
        }
      ],
      middleware: [
        UserMustBeAdminOrOwner
      ]
    }
  },
  {
    path: '/fulfilment/overview',
    name: 'FulfilmentOverview',
    component: FulfilmentOverview
  },
  {
    path: '/fulfilment/open',
    name: 'FulfilmentOpen',
    component: FulfilmentOpen
  },
  {
    path: '/fulfilment/packing',
    name: 'FulfilmentPacking',
    component: FulfilmentPacking,
    props: true
  },
  {
    path: '/fulfilment/dispatched',
    name: 'FulfilmentDispatched',
    component: FulfilmentDispatched
  },
  {
    path: '/fulfilment/qa',
    name: 'FulfilmentQa',
    component: FulfilmentQa
  },
  {
    path: '/fulfilment/passedQa',
    name: 'FulfilmentPassedQa',
    component: FulfilmentPassedQa
  },
  {
    path: '/fulfilment/futureOrders',
    name: 'FulfilmentFutureOrders',
    component: FulfilmentFutureOrders
  },
  {
    path: '/fulfilment/cancelled',
    name: 'FulfilmentCancelled',
    component: FulfilmentCancelled
  },
  {
    path: '/fulfilment/orders/:id',
    name: 'ViewOrder',
    component: ViewOrder,
    props: true,
    meta: {
      breadcrumbs: [
        {
          title: 'Orders',
          link: 'FulfilmentOverview'
        }
      ]
    }
  },
  {
    path: '/fulfilment/advanced-search',
    name: 'FulfilmentSearch',
    component: FulfilmentSearch
  },
  {
    path: '/review-results',
    name: 'ReviewResults',
    component: ReviewResults,
    meta: {
      middleware: [
        UserMustBeAdminOrOwner
      ]
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
    if (!to.meta.middleware) {
      return next()
    }

    if (to.name === 'ViewTest' && from.name === 'ViewTest') {
      store.dispatch('AppStore/SetShouldRefreshViewTestComponent', true)
    }

    const middleware = to.meta.middleware

    const context = {
      to,
      from,
      next,
      router,
      store
    }

    return middleware[0]({ ...context, next: MiddlewarePipeline(context, middleware, 1) })
})

export default router
