const TestStatus = Object.freeze({
    NEW: 0,
    ISSUED: 1,
    RETURNED: 2,
    COMPLETE: 3,
    CANCELLED: 4,
    REPORTED: 5,
    FAILED: 6,
    ERROR: 7
})

export default TestStatus
