<template>
    <div>
        <p class="modal-heading">Error</p>
        <div class="text-sm mt-3">
            <div class="general-error-container">
                <p>{{message}}</p>
                <P>You will be signed out upon closing this dialog.</P>
            </div>

            <div class="mt-4 text-right">
                <button @click="close()" class="btn btn-cancel btn-lg lg-shadow">Close</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    name: 'GeneralErrorModalComponent',

    props: {
        message: String
    },

    data () {
        return {

        }
    },

    methods: {
        ...mapActions({
            signout: 'UserStore/Signout'
        }),

        async close () {
            this.$emit('close')
            await this.signout()
        }
    }
}
</script>
