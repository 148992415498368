const TestEventType = Object.freeze({
    CREATED: 0,
    ISSUED: 1,
    TEST_A_COMPLETED: 2,
    TEST_B_COMPLETED: 3,
    RECEIVED: 4,
    COMPLETED: 5,
    CANCELLED: 6,
    REPORTED: 7,
    ERROR: 8,
    FAILED: 9,
    ASSIGNED: 10,
    NOTIFICATION: 11,
    OVERRIDDEN: 12,
    REORDERED: 13,
    ACCEPTED: 14
})

export default TestEventType
