<template>
    <div>
        <Breadcrumbs />

        <div class="card text-sm mt-3">
            <div class="card-body">
                <h5 class="mb-0">Collection point details</h5>

                <div class="row mt-4">
                    <div class="col-xl-5">
                        <div class="form-group row">
                            <label for="name" class="col-sm-4 col-form-label">Name <span class="text-danger">*</span></label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" v-model="form.collectionPointName">
                                <small v-if="errors && errors.collectionPointName" class="text-danger">
                                    {{ errors.collectionPointName[0] }}
                                </small>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="country-code" class="col-sm-4 col-form-label">Select your region <span class="text-danger">*</span></label>
                            <div class="col-sm-8">
                                <select class="custom-select" name="country-code" v-model="form.collectionPointCountryCode" @change="chooseCountry()">
                                    <option value="">Please select an option</option>
                                    <option value="GB">United Kingdom</option>
                                    <option value="US">United States</option>
                                </select>
                                <small v-if="errors && errors.collectionPointCountryCode" class="text-danger">
                                    {{ errors.collectionPointCountryCode[0] }}
                                </small>
                            </div>
                        </div>

                        <div v-if="form.collectionPointCountryCode == 'GB'" class="form-group row">
                            <label for="autocomplete" class="col-sm-4 col-form-label">Find Address</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" @input="findAddress()" v-model="addressQueryString" placeholder="Search for address" autocomplete="new-password" />
                                <ul v-if="autocompleteSuggestions.length" class="list-group autocomplete-suggestions">
                                    <li v-for="(suggestion, index) in autocompleteSuggestions" :key="index" class="list-group-item" @click="chooseSuggestion(suggestion.id)">{{ suggestion.address }}</li>
                                </ul>
                                <button class="btn show-manual-address" @click="enterAddressManually()">Enter address manually</button>
                            </div>
                        </div>

                        <div v-if="expandManualUkAddress">
                            <div class="form-group row">
                                <label for="address1" class="col-sm-4 col-form-label">Address 1 <span class="text-danger">*</span></label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" :class="{'border border-danger' : errors && errors.collectionPointAddress1}" id="address1" v-model="form.collectionPointAddress1">
                                    <small v-if="errors && errors.collectionPointAddress1" class="text-danger">
                                        {{ errors.collectionPointAddress1[0] }}
                                    </small>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="address2" class="col-sm-4 col-form-label">Address 2 <span class="text-danger">*</span></label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" :class="{'border border-danger' : errors && errors.collectionPointAddress2}" id="address2" v-model="form.collectionPointAddress2">
                                    <small v-if="errors && errors.collectionPointAddress2" class="text-danger">
                                        {{ errors.collectionPointAddress2[0] }}
                                    </small>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="address3" class="col-sm-4 col-form-label">Address 3</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" :class="{'border border-danger' : errors && errors.collectionPointAddress3}" id="address3" v-model="form.collectionPointAddress3">
                                    <small v-if="errors && errors.collectionPointAddress3" class="text-danger">
                                        {{ errors.collectionPointAddress3[0] }}
                                    </small>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="address4" class="col-sm-4 col-form-label">Address 4</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" :class="{'border border-danger' : errors && errors.collectionPointAddress4}" id="address4" v-model="form.collectionPointAddress4">
                                    <small v-if="errors && errors.collectionPointAddress4" class="text-danger">
                                        {{ errors.collectionPointAddress4[0] }}
                                    </small>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="postalCode" class="col-sm-4 col-form-label">Postcode <span class="text-danger">*</span></label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" :class="{'border border-danger' : errors && errors.collectionPointPostalCode}" id="postalCode" v-model="form.collectionPointPostalCode">
                                    <small v-if="errors && errors.collectionPointPostalCode" class="text-danger">
                                        {{ errors.collectionPointPostalCode[0] }}
                                    </small>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="country" class="col-sm-4 col-form-label">Country <span class="text-danger">*</span></label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" id="country" v-model="form.collectionPointCountry">
                                    <small v-if="errors && errors.collectionPointCountry" class="text-danger">
                                        {{ errors.collectionPointCountry[0] }}
                                    </small>
                                </div>
                            </div>

                        </div>

                        <div v-if="expandManualUsAddress">
                            <div class="form-group row">
                                <label for="address1" class="col-sm-4 col-form-label">Address 1 <span class="text-danger">*</span></label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" :class="{'border border-danger' : errors && errors.collectionPointAddress1}" id="address1" v-model="form.collectionPointAddress1">
                                    <small v-if="errors && errors.collectionPointAddress1" class="text-danger">
                                        {{ errors.collectionPointAddress1[0] }}
                                    </small>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="address2" class="col-sm-4 col-form-label">Address 2 <span class="text-danger">*</span></label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" :class="{'border border-danger' : errors && errors.collectionPointAddress2}" id="address2" v-model="form.collectionPointAddress2">
                                    <small v-if="errors && errors.collectionPointAddress2" class="text-danger">
                                        {{ errors.collectionPointAddress2[0] }}
                                    </small>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="address3" class="col-sm-4 col-form-label">City</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" :class="{'border border-danger' : errors && errors.collectionPointAddress3}" id="address3" v-model="form.collectionPointAddress3">
                                    <small v-if="errors && errors.collectionPointAddress3" class="text-danger">
                                        {{ errors.collectionPointAddress3[0] }}
                                    </small>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="address4" class="col-sm-4 col-form-label">State</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" :class="{'border border-danger' : errors && errors.collectionPointAddress4}" id="address4" v-model="form.collectionPointAddress4">
                                    <small v-if="errors && errors.collectionPointAddress4" class="text-danger">
                                        {{ errors.collectionPointAddress4[0] }}
                                    </small>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="postalCode" class="col-sm-4 col-form-label">Zip Code <span class="text-danger">*</span></label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" :class="{'border border-danger' : errors && errors.collectionPointPostalCode}" id="postalCode" v-model="form.collectionPointPostalCode">
                                    <small v-if="errors && errors.collectionPointPostalCode" class="text-danger">
                                        {{ errors.collectionPointPostalCode[0] }}
                                    </small>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="fulfilment-provider" class="col-sm-4 col-form-label">Type <span class="text-danger">*</span></label>
                            <div class="col-sm-8">
                                <select type="text" class="custom-select" v-model="form.collectionPointType">
                                    <option :value="CollectionPointType.PHARMACY">Pharmacy</option>
                                    <option :value="CollectionPointType.PLACE_OF_WORSHIP">Place of worship</option>
                                    <option :value="CollectionPointType.RETAIL">Retail</option>
                                    <option :value="CollectionPointType.COMMUNITY_CENTRE">Community centre</option>
                                </select>
                                <small v-if="errors && errors.collectionPointType" class="text-danger">
                                    {{ errors.collectionPointType[0] }}
                                </small>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="website-address" class="col-sm-4 col-form-label">Website address</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" v-model="form.collectionPointWebsiteAddress">
                                <small v-if="errors && errors.collectionPointWebsiteAddress" class="text-danger">
                                    {{ errors.collectionPointWebsiteAddress[0] }}
                                </small>
                            </div>
                        </div>

                        <div class="form-group row align-items-center">
                            <label for="status" class="col-sm-4 col-form-label">Status <span class="text-danger">*</span></label>
                            <div class="col-sm-8">
                                <b-form-checkbox v-model="form.collectionPointStatusChecked" name="check-button" switch class="custom-switch-md">
                                    {{ form.collectionPointStatusChecked ? 'Active' : 'Inactive' }}
                                </b-form-checkbox>
                                <small v-if="errors && errors.collectionPointStatusChecked" class="text-danger">
                                    {{ errors.collectionPointStatusChecked[0] }}
                                </small>
                            </div>
                        </div>
                    </div>
                </div>

                <h5 class="my-4">Primary contact</h5>

                <div class="row mt-4">
                    <div class="col-xl-5">
                        <div class="form-group row">
                            <label for="title" class="col-sm-4 col-form-label">Title <span class="text-danger">*</span></label>
                            <div class="col-sm-8">
                                <select type="text" class="custom-select" v-model="form.title">
                                    <option :value="Salutation.MR">Mr</option>
                                    <option :value="Salutation.MRS">Mrs</option>
                                    <option :value="Salutation.MISS">Miss</option>
                                    <option :value="Salutation.MS">Ms</option>
                                    <option :value="Salutation.DR">Dr</option>
                                    <option :value="Salutation.SIR">Sir</option>
                                    <option :value="Salutation.PROFESSOR">Professor</option>
                                </select>
                                <small v-if="errors && errors.title" class="text-danger">
                                    {{ errors.title[0] }}
                                </small>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="address" class="col-sm-4 col-form-label">First name <span class="text-danger">*</span></label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" v-model="form.firstName">
                                <small v-if="errors && errors.firstName" class="text-danger">
                                    {{ errors.firstName[0] }}
                                </small>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="address" class="col-sm-4 col-form-label">Surname <span class="text-danger">*</span></label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" v-model="form.surname">
                                <small v-if="errors && errors.surname" class="text-danger">
                                    {{ errors.surname[0] }}
                                </small>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="address" class="col-sm-4 col-form-label">Phone number <span class="text-danger">*</span></label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" v-model="form.phone">
                                <small v-if="errors && errors.phone" class="text-danger">
                                    {{ errors.phone[0] }}
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="fixed-bottom bg-white text-right center-mobile p-3">
            <button :disabled="loading" @click="cancel()" class="btn btn-cancel btn-lg lg-shadow" :class="{disabled : loading}">Cancel</button>
            <button :disabled="loading" @click="create()" class="btn btn-success btn-lg lg-shadow ml-2 px-55" :class="{disabled : loading}">Create <span v-if="loading" class="btn-loading"></span></button>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import Breadcrumbs from '@/partials/site-structure/BreadcrumbsComponent'
import axios from 'axios'
import CollectionPointType from '@/enums/CollectionPointType'
import Salutation from '@/enums/Salutation'
export default {
    name: 'CreateCollectionPoint',

    components: {
        Breadcrumbs
    },

    data () {
        return {
            loading: false,
            expandManualUkAddress: false,
            expandManualUsAddress: false,
            addressQueryString: null,
            autocompleteSuggestions: [],
            expandManualAddress: false,
            form: {
                collectionPointAddress1: null,
                collectionPointAddress2: null,
                collectionPointAddress3: null,
                collectionPointAddress4: null,
                collectionPointPostalCode: '',
                collectionPointCountry: null,
                collectionPointLatitude: null,
                collectionPointLongitude: null,
                collectionPointCountryCode: null,
                collectionPointStatusChecked: true
            },
            errors: {},
            CollectionPointType,
            Salutation
        }
    },

    methods: {
        ...mapActions({
            setNotification: 'NotificationStore/SetNotification'
        }),

        async chooseCountry () {
            this.clearAddress()

            if (this.form.collectionPointCountryCode === 'GB') {
                this.expandManualUsAddress = false
                return
            }

            if (this.form.collectionPointCountryCode === 'US') {
                this.expandManualUkAddress = false
                this.expandManualUsAddress = true
                this.form.collectionPointCountry = 'US'
                return
            }

            this.expandManualUkAddress = false
            this.expandManualUsAddress = false
        },

        async findAddress () {
            const response = await axios.get(`https://api.getAddress.io/autocomplete/${this.addressQueryString}?api-key=${process.env.VUE_APP_GET_ADDRESS_API_KEY}`)

            this.autocompleteSuggestions = response.data.suggestions
        },

        async chooseSuggestion (id) {
            this.clearAddress()

            const response = await axios.get(`https://api.getAddress.io/get/${id}?api-key=${process.env.VUE_APP_GET_ADDRESS_API_KEY}`)

            if (response.data) {
                this.autocompleteSuggestions = []
                this.addressQueryString = null
                this.expandManualUkAddress = true
                this.form.collectionPointAddress1 = response.data.line_1
                this.form.collectionPointAddress2 = response.data.town_or_city
                this.form.collectionPointAddress3 = response.data.county
                this.form.collectionPointPostalCode = response.data.postcode
                this.form.collectionPointCountry = response.data.country
                this.form.collectionPointLatitude = response.data.latitude
                this.form.collectionPointLongitude = response.data.longitude
            }
        },

        clearAddress () {
            this.fullAddress = null
            this.form.collectionPointAddress1 = null
            this.form.collectionPointAddress2 = null
            this.form.collectionPointAddress3 = null
            this.form.collectionPointPostalCode = null
            this.form.collectionPointCountry = null
            this.form.collectionPointLatitude = null
            this.form.collectionPointLongitude = null
        },

        enterAddressManually () {
            this.expandManualUkAddress = true
        },

        cancel () {
            return this.$router.push({ name: 'CollectionPoints' })
        },

        async create () {
            this.expandManualAddress = true
            this.checkForm()

            if (!Object.keys(this.errors).length) {
                this.loading = true

                try {
                    const collectionPointModel = {
                        name: this.form.collectionPointName,
                        type: this.form.collectionPointType,
                        isActive: this.form.collectionPointStatusChecked,
                        websiteAddress: this.form.collectionPointWebsiteAddress,
                        address1: this.form.collectionPointAddress1,
                        address2: this.form.collectionPointAddress2,
                        address3: this.form.collectionPointAddress3,
                        address4: this.form.collectionPointAddress4,
                        postalCode: this.form.collectionPointPostalCode,
                        country: this.form.collectionPointCountry,
                        countryCode: this.form.collectionPointCountryCode,
                        latitude: this.form.collectionPointLatitude ?? 0,
                        longitude: this.form.collectionPointLongitude ?? 0,
                        title: this.form.title,
                        firstName: this.form.firstName,
                        surname: this.form.surname,
                        phone: this.form.phone
                    }

                    await axios.post(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/collectionpoints`, collectionPointModel)

                    this.$router.push({ name: 'CollectionPoints' })

                    return this.setNotification({
                        type: 'success',
                        title: 'Success',
                        message: 'The collection point was added.'
                    })
                } catch (err) {
                    this.setNotification({
                        type: 'error',
                        title: 'Error',
                        message: 'Something went wrong. The collection point was not added.'
                    })
                } finally {
                    this.loading = false
                }
            }
        },

        checkForm () {
            this.errors = {}

            if (!this.form.collectionPointName) {
                this.errors.collectionPointName = ['The collection point name field is required']
            }

            if (!this.form.collectionPointAddress1) {
                this.errors.collectionPointAddress1 = ['The collection point address 1 field is required']
            }

            if (!this.form.collectionPointAddress2) {
                this.errors.collectionPointAddress2 = ['The collection point address 2 field is required']
            }

            if (!this.form.collectionPointPostalCode) {
                this.errors.collectionPointPostalCode = ['The collection point postal code field is required']
            }

            if (!this.form.collectionPointCountry) {
                this.errors.collectionPointCountry = ['The collection point country field is required']
            }

            if (!this.form.collectionPointCountryCode) {
                this.errors.collectionPointCountryCode = ['The collection point country code field is required']
            }

            if (this.form.collectionPointType == null || parseInt(this.form.collectionPointType) < 0) {
                this.errors.collectionPointType = ['Collection point type field is required']
            }

            if (this.form.title == null || parseInt(this.form.title) < 0) {
                this.errors.title = ['The primary contact title field is required']
            }

            if (!this.form.firstName) {
                this.errors.firstName = ['The primary contact first name field is required']
            }

            if (!this.form.surname) {
                this.errors.surname = ['The primary contact surname field is required']
            }

            if (!this.form.phone) {
                this.errors.phone = ['The primary contact phone number field is required']
            }

            if (this.form.phone != null && !this.form.phone.match(/[0-9]{9,20}/)) {
                this.errors.phone = ['The phone number must be a minimum of 9 digits']
            }
        }
    }
}
</script>
