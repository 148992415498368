import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import FormatterMixin from '@/mixins/FormatterMixin'
export default {
    mixins: [
        FormatterMixin
    ],

    data () {
        return {
            results: {},
            isBusy: false,
            currentPage: 1,
            pageSize: process.env.VUE_APP_PAGINATION_PAGE_SIZE,
            offset: 0,
            sortColumn: 'ORDER_NUMBER',
            sortType: 'ASCENDING',
            sortDesc: false,
            sortBySync: 'orderNumber',
            selectMode: 'multi',
            selected: [],
            selectAllRows: false
        }
    },

    watch: {
        selected () {
            if (this.selected.length === this.results.results.length) {
                this.selectAll = true
            } else {
                this.selectAll = false
            }
        },
        verifyOrder: function (val) {
            if (val != null) {
                this.deviceVerifiedByOrderNumber(val)
                this.setVerifyOrderNumber(null)
            }
        }
    },

    computed: {
        ...mapGetters({
            verifyOrder: 'AppStore/GetVerifyOrder'
        })
    },

    methods: {
        ...mapActions({
            getStatusCountsAction: 'FulfilmentStatusCountsStore/GetStatusCounts',
            setNotification: 'NotificationStore/SetNotification',
            setVerifyOrderNumber: 'AppStore/SetVerifyOrder'
        }),

        async getOrders () {
            this.isBusy = true

            try {
                const response = await axios.get(`${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/orders/${this.pageSize}/${this.offset}/${this.sortColumn}/${this.sortType}/${this.orderType}`)

                var results = response.data

                results.results = results.results.map(item => {
                    item._rowVariant = this.getTableVariant(item)
                    return item
                })

                this.results = results
            } catch {
                throw Error('Something went wrong while fetching the orders.')
            } finally {
                this.isBusy = false
            }
        },

        getTableVariant (item) {
            if (item.alternativeLanguage != null) return 'warning'
            if (item.organisationName === 'University of Oxford') return 'info'
        },

        pageChange (value) {
            this.currentPage = value
            this.offset = (value - 1) * this.pageSize
            this.getOrders()
        },

        sortingChanged (e) {
            switch (e.sortBy) {
                case 'patientName':
                    this.sortColumn = 'PATIENT_NAME'
                break

                case 'shippingAddress':
                    this.sortColumn = 'SHIPPING_ADDRESS'
                break

                case 'organisationName':
                    this.sortColumn = 'ORGANISATION_NAME'
                break

                case 'practitionerName':
                    this.sortColumn = 'PRACTITIONER_NAME'
                break

                case 'facilityName':
                    this.sortColumn = 'FACILITY_NAME'
                break

                case 'deviceNumber':
                    this.sortColumn = 'DEVICE_NUMBER'
                break

                case 'orderNumber':
                    this.sortColumn = 'ORDER_NUMBER'
                break

                case 'issuedDate':
                    this.sortColumn = 'ISSUED_DATE'
                break

                case 'dueDate':
                    this.sortColumn = 'DUE_DATE'
                break

                case 'status':
                    this.sortColumn = 'STATUS'
                break

                case 'dateCreated':
                    this.sortColumn = 'DATE_CREATED'
                break

                case 'cancelledDate':
                    this.sortColumn = 'DATE_CANCELLED'
                break

                case 'alternativeLanguage':
                    this.sortColumn = 'ALTERNATIVE_LANGUAGE'
                break
            }

            this.sortDesc = e.sortDesc
            this.sortBySync = e.sortBy

            if (this.sortDesc) {
                this.sortType = 'DESCENDING'
            } else {
                this.sortType = 'ASCENDING'
            }

            this.getOrders()
        },

        toggleSelectAll () {
            if (this.selectAllRows) {
                this.$refs.table.clearSelected()
                this.selectAllRows = false
                return
            }

            if (this.selected.length === this.results.results.length) {
                this.$refs.table.clearSelected()
                this.selected = []
                this.selectAllRows = false
            } else {
                this.$refs.table.selectAllRows()
                this.selectAllRows = true
            }
        },

        onRowSelected (items) {
            this.selected = items
        },

        async changeOrderStatus (status) {
            this.isBusy = true

            const orders = this.selected.map(function (selected) {
                return selected.id
            })

            if (orders.length) {
                try {
                    await axios.post(`${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/orders/status?status=${status}`, orders)
                    await this.getOrders()
                    await this.getStatusCountsAction()
                    this.selected = []
                } catch (err) {
                    throw Error(err.data)
                } finally {
                    this.isBusy = false
                }
            }
        },

        async createLabels () {
            const orders = this.selected.map(function (selected) {
                return selected.id
            })

            try {
                await axios.post(`${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/orders/label`, orders)
            } catch {
                throw Error('Could not create labels')
            }
        },

        handleChangePageSize () {
            this.currentPage = 1
            this.offset = 0

            this.getOrders()
        },

        deviceVerified (response) {
            this.results.results.map(order => {
                if (order.id === response.data.orderId) {
                    order.verified = response.data.isValid
                }
                return order
            })
        },

        deviceVerifiedByOrderNumber (orderNumber) {
            if (orderNumber == null) return
            this.results.results.map(order => {
                if (order.orderNumber === orderNumber) {
                    order.verified = true
                }
                return order
            })
        },

        languageCode (alternativeLanguage) {
            var languageData = this.alternativeLanguages.find(lang => lang.code === alternativeLanguage)
            if (languageData != null) {
                return this.formatLanguageCode(languageData)
            }
            return ''
        }
    }
}
