<template>
    <div>
        <img class="d-block mx-auto" src="@/assets/images/icons/remove-icon.svg" width="80" alt="tick">
        <p class="text-center lead font-weight-bold mt-3">Remove device</p>
        <div class="text-sm mt-4">
            <div class="row">
                <div class="col-4">Device number</div>
                <div class="col-8">{{ deviceNumberOrSerialNumberComputed }}</div>
            </div>

            <hr />

            <div class="row">
                <div class="col-4">Remove from</div>
                <div class="col-8">Order {{ orderNumber }}</div>
            </div>

            <div class="alert alert-warning border border-warning mt-4 d-flex align-items-center" role="alert">
                <img class="mr-2" src="@/assets/images/icons/warning-icon.svg" width="20" /> The order will be moved to <span class="badge badge-pill badge-fulfilment-open ml-2">Open</span>
            </div>

            <div class="d-flex align-items-center mt-45">
                <button :disabled="loading" @click="$emit('close')" class="btn btn-cancel btn-lg lg-shadow d-flex flex-grow-1 w-45 justify-content-center align-items-center" :class="{disabled : loading}">Cancel</button>
                <button :disabled="loading" @click="confirm()" class="btn btn-success btn-lg lg-shadow d-flex flex-grow-1 w-55 ml-3 justify-content-center align-items-center" :class="{disabled : loading}">Remove <span v-if="loading" class="btn-loading"></span></button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import axios from 'axios'
export default {
    name: 'RemoveDeviceModalComponent',

    props: {
        id: String,
        deviceNumber: String,
        serialNumber: String,
        orderNumber: Number
    },

    data () {
        return {
            loading: false
        }
    },

    computed: {
        deviceNumberOrSerialNumberComputed () {
            if (this.deviceNumber) return this.deviceNumber
            if (this.serialNumber) return this.serialNumber

            return 'Unknown'
        }
    },

    methods: {
        ...mapActions({
            setShouldRefreshOrder: 'AppStore/SetShouldRefreshOrder',
            getStatusCounts: 'FulfilmentStatusCountsStore/GetStatusCounts',
            setNotification: 'NotificationStore/SetNotification'
        }),

        async confirm () {
            this.loading = true

            try {
                await axios.delete(`${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/orders/device?orderId=${this.id}`, {
                    reason: this.note
                })

                this.setShouldRefreshOrder(true)

                await this.getStatusCounts()

                this.$emit('close')

                this.setNotification({
                    type: 'success',
                    title: 'Success',
                    message: 'The device has been removed.'
                })
            } catch {
                this.setNotification({
                    type: 'error',
                    title: 'Error',
                    message: 'Something went wrong. The device has not been.'
                })
            } finally {
                this.loading = false
            }
        },

        close () {
            this.$emit('close')
        }
    }
}
</script>
