export default {
    computed: {
        showingComputed () {
            let returnVal

            // console.log('current page ' + this.currentPage)
            // console.log('offset ' + this.results.offset)
            // console.log('pageSize ' + this.results.pageSize)
            // console.log('results count ' + this.results.resultsCount)
            // console.log('total count ' + this.results.totalCount)

            if (this.results.pageSize === 1) {
                returnVal = parseInt(this.results.pageSize) + parseInt(this.results.offset)
                return returnVal
            } else {
                // not paging by 1, and not on the last page
                // 1-2 of 3, 3-4 of 5 etc..

                // if on page 1, show 1 - x of total
                if (this.currentPage === 1) {
                    if (this.results.resultsCount === this.results.totalCount) {
                        returnVal = this.results.totalCount
                        return returnVal
                    }

                    returnVal = `1 - ${this.results.pageSize}`
                    return returnVal
                } else {
                    if ((parseInt(this.results.pageSize) + parseInt(this.results.offset)) >= this.results.totalCount && this.results.resultsCount === 1) {
                        returnVal = this.results.totalCount
                        return returnVal
                    }

                    if ((parseInt(this.results.pageSize) + parseInt(this.results.offset)) >= this.results.totalCount) {
                        returnVal = `${parseInt(this.results.offset) + 1} - ${this.results.totalCount}`
                        return returnVal
                    }

                    returnVal = `${parseInt(this.results.offset) + 1} - ${parseInt(this.currentPage) * this.results.pageSize}`
                }
            }

            return returnVal
        }
    }
}
