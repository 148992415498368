import store from '@/store'
import axios from 'axios'

store.subscribe((mutation) => {
    switch (mutation.type) {
        case 'UserStore/SET_ACCESS_TOKEN' :
            if (mutation.payload) {
                // user is signed in
                axios.defaults.headers.common.Authorization = `Bearer ${mutation.payload}`
            } else {
                // user is not signed in
                axios.defaults.headers.common.Authorization = null
            }
        break
        case 'UserStore/SET_USER_CURRENT_DOMAIN' :
            if (mutation.payload) {
                // user is signed in
                axios.defaults.headers.common.DomainId = `${mutation.payload}`
            } else {
                // user is not signed in
                axios.defaults.headers.common.DomainId = null
            }
        break
    }
})
