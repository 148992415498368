import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VModal from 'vue-js-modal'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import '@/assets/scss/app.scss'

import msalPlugin from './plugins/msalPlugin'

// Import vuex Stores
import store from './store'

// Install bootstrap vue
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

// Install moment
Vue.use(require('vue-moment'))

// Install VModal
Vue.use(VModal, {
  dynamic: true,
  dynamicDefaults: {
    height: 'auto',
    scrollable: true,
    classes: 'outer-modal'
  }
})

Vue.use(msalPlugin)

Vue.config.productionTip = false

// Run Store Subscriber. This sets the bearer token when it comes back from auth 0
require('@/store/Subscriber')

// Set axios defaults
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

axios.interceptors.request.use(
  async (request) => {
    // get token
    var account = store.getters['UserStore/GetUserAccount']
    const scopes = process.env.VUE_APP_MSAL_SCOPES
    const scopesArray = scopes.split(',')
    const accessTokenRequest = {
      scopes: scopesArray,
      account: account
    }

    await Vue.prototype.$msalInstance.handleRedirectPromise()
    var token = await Vue.prototype.$msalInstance.acquireTokenSilent(accessTokenRequest)
    request.headers.common.Authorization = `Bearer ${token.accessToken}`
    store.commit('UserStore/SET_ACCESS_TOKEN', token.accessToken)

    return Promise.resolve(request)
  },
  error => {
    // Do something with request error
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  response => {
    if (response.status === 401) {
      return Promise.reject(response)
    }

    if (response.status === 404) {
      return Promise.reject(response)
    }

    return Promise.resolve(response)
  },

  error => {
    if (error.response.status) {
      switch (error.response.status) {
        case 401:
          store.dispatch('UserStore/Signout')
          break
      }
      return Promise.reject(error.response)
    }
})

// Set up inactivity timer

var timer
window.onload = resetTimer
window.onmousemove = resetTimer
window.onmousedown = resetTimer // catches touchscreen presses as well
window.ontouchstart = resetTimer // catches touchscreen swipes as well
window.ontouchmove = resetTimer // required by some devices
window.onclick = resetTimer // catches touchpad clicks as well
window.onkeydown = resetTimer
window.addEventListener('scroll', resetTimer, true)

function inactiveUserSignOut () {
  store.dispatch('UserStore/Signout')
}

function resetTimer () {
    clearTimeout(timer)
    timer = setTimeout(inactiveUserSignOut, 60 * 60 * 1000) // 1 hour (time is in milliseconds)
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
