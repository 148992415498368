<template>
    <div>
        <Loader v-if="loading" />

        <div v-else>
            <h2 class="mt-2">Dashboard</h2>

            <div class="card mt-4">
                <div class="card-body">
                    <h3>Statistics</h3>

                    <p class="mb-0 font-weight-bold mt-45">This week</p>
                    <p class="text-sm">({{ testsStatsThisWeek.dateFrom | moment("MMM D, YYYY")}} - {{ testsStatsThisWeek.dateTo | moment("MMM D, YYYY")}})</p>

                    <div class="row text-center">
                        <div class="col-lg col-md-4 mb-1 mb-lg-0">
                            <div class="dashboard-card test-past-due-date">
                                <p class="h3 mb-0 font-weight-bold">{{ testsStatsThisWeek.pastDueCount }}</p>
                                <p class="mb-0">Past test date</p>
                            </div>
                        </div>

                        <div class="col-lg col-md-4 mb-1 mb-lg-0">
                            <div class="dashboard-card test-due">
                                <p class="h3 mb-0 font-weight-bold">{{ testsStatsThisWeek.dueCount }}</p>
                                <p class="mb-0">Tests due</p>
                            </div>
                        </div>

                        <div class="col-lg col-md-4 mb-1 mb-lg-0">
                            <div class="dashboard-card test-complete">
                                <p v-if="testsStatsThisWeek.statusCounts.COMPLETED" class="h3 mb-0 font-weight-bold">{{ testsStatsThisWeek.statusCounts.COMPLETED }}</p>
                                <p v-else class="h3 mb-0 font-weight-bold">0</p>
                                <p class="mb-0">Tests complete</p>
                            </div>
                        </div>

                        <div class="col-lg col-md-4 mb-1 mb-lg-0">
                            <div class="dashboard-card test-device-issued">
                                <p v-if="testsStatsThisWeek.statusCounts.ISSUED" class="h3 mb-0 font-weight-bold">{{ testsStatsThisWeek.statusCounts.ISSUED }}</p>
                                <p v-else class="h3 mb-0 font-weight-bold">0</p>
                                <p class="mb-0">Devices issued</p>
                            </div>
                        </div>

                        <div class="col-lg col-md-4 mb-1 mb-lg-0">
                            <div class="dashboard-card test-error">
                                <p v-if="testsStatsThisWeek.statusCounts.ERRORED" class="h3 mb-0 font-weight-bold">{{ testsStatsThisWeek.statusCounts.ERRORED }}</p>
                                <p v-else class="h3 mb-0 font-weight-bold">0</p>
                                <p class="mb-0">Errored scans</p>
                            </div>
                        </div>
                    </div>

                    <p class="mb-0 font-weight-bold mt-4">This month</p>
                    <p class="text-sm">({{ testsStatsThisMonth.dateFrom | moment("MMM D, YYYY")}} - {{ testsStatsThisMonth.dateTo | moment("MMM D, YYYY")}})</p>

                    <div class="row text-center">
                        <div class="col-lg col-md-4 mb-1 mb-lg-0">
                            <div class="dashboard-card test-past-due-date">
                                <p class="h3 mb-0 font-weight-bold">{{ testsStatsThisMonth.pastDueCount }}</p>
                                <p class="mb-0">Past test date</p>
                            </div>
                        </div>

                        <div class="col-lg col-md-4 mb-1 mb-lg-0">
                            <div class="dashboard-card test-due">
                                <p class="h3 mb-0 font-weight-bold">{{ testsStatsThisMonth.dueCount }}</p>
                                <p class="mb-0">Tests due</p>
                            </div>
                        </div>

                        <div class="col-lg col-md-4 mb-1 mb-lg-0">
                            <div class="dashboard-card test-complete">
                                <p v-if="testsStatsThisMonth.statusCounts.COMPLETED" class="h3 mb-0 font-weight-bold">{{ testsStatsThisMonth.statusCounts.COMPLETED }}</p>
                                <p v-else class="h3 mb-0 font-weight-bold">0</p>
                                <p class="mb-0">Tests complete</p>
                            </div>
                        </div>

                        <div class="col-lg col-md-4 mb-1 mb-lg-0">
                            <div class="dashboard-card test-device-issued">
                                <p v-if="testsStatsThisMonth.statusCounts.ISSUED" class="h3 mb-0 font-weight-bold">{{ testsStatsThisMonth.statusCounts.ISSUED }}</p>
                                <p v-else class="h3 mb-0 font-weight-bold">0</p>
                                <p class="mb-0">Devices issued</p>
                            </div>
                        </div>

                        <div class="col-lg col-md-4 mb-1 mb-lg-0">
                            <div class="dashboard-card test-error">
                                <p v-if="testsStatsThisMonth.statusCounts.ERRORED" class="h3 mb-0 font-weight-bold">{{ testsStatsThisMonth.statusCounts.ERRORED }}</p>
                                <p v-else class="h3 mb-0 font-weight-bold">0</p>
                                <p class="mb-0">Errored scans</p>
                            </div>
                        </div>
                    </div>

                    <p class="font-weight-bold mt-4">In total</p>

                    <div class="row text-center">
                        <div class="col-lg col-md-4 mb-1 mb-lg-0">
                            <div class="dashboard-card test-alt">
                                <p class="h3 mb-0 font-weight-bold">{{ allTestsStats.testsIssuedCount }}</p>
                                <p class="mb-0">Devices issued</p>
                            </div>
                        </div>

                        <div class="col-lg col-md-4 mb-1 mb-lg-0">
                            <div class="dashboard-card test-complete">
                                <p class="h3 mb-0 font-weight-bold">{{ allTestsStats.testsCompleteCount }}</p>
                                <p class="mb-0">Tests complete</p>
                            </div>
                        </div>

                        <div class="col-lg col-md-4 mb-1 mb-lg-0">
                            <div class="dashboard-card test-alt">
                                <p class="h3 mb-0 font-weight-bold">{{ patientsStats.patientsCount }}</p>
                                <p class="mb-0">Patients on record</p>
                            </div>
                        </div>

                        <div class="col-lg col-md-4 mb-1 mb-lg-0">
                            <div class="dashboard-card test-alt">
                                <p class="h3 mb-0 font-weight-bold">{{ organisationsStats.organisationsCount }}</p>
                                <p class="mb-0">Organisations</p>
                            </div>
                        </div>

                        <div class="col-lg col-md-4 mb-1 mb-lg-0">
                            <div class="dashboard-card test-alt">
                                <p class="h3 mb-0 font-weight-bold">{{ organisationsStats.practitionersCount }}</p>
                                <p class="mb-0">Health care professionals</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import Loader from '@/partials/Loader.vue'
export default {
    name: 'Dashboard',

    components: {
        Loader
    },

    data () {
        const startOfWeek = this.$moment().startOf('week').format('YYYY-MM-DDT00:00:00')
        const endOfWeek = this.$moment().utc().endOf('week').format('YYYY-MM-DDT23:59:59')
        const startOfMonth = this.$moment().utc().startOf('month').format('YYYY-MM-DDT00:00:00')
        const endOfMonth = this.$moment().utc().endOf('month').format('YYYY-MM-DDT23:59:59')

        return {
            loading: true,
            testsStatsThisWeek: {},
            testsStatsThisMonth: {},
            allTestsStats: {},
            patientsStats: {},
            organisationsStats: {},
            startOfWeek: startOfWeek,
            endOfWeek: endOfWeek,
            startOfMonth: startOfMonth,
            endOfMonth: endOfMonth
        }
    },

    mounted () {
        this.initPage()
    },

    computed: {
        ...mapGetters({
            userRoleType: 'UserStore/GetUserRoleType'
        })
    },

    methods: {
        ...mapActions({
            setNotification: 'NotificationStore/SetNotification'
        }),

        async initPage () {
            try {
                this.testsStatsThisWeek = await this.getTestsStatsByDateRange(this.startOfWeek, this.endOfWeek)
                this.testsStatsThisMonth = await this.getTestsStatsByDateRange(this.startOfMonth, this.endOfMonth)
                await this.getAllTestsStats()
                await this.getPatientsStats()
                await this.getOrganisationsStats()
            } catch {
                this.setNotification({
                    type: 'error',
                    title: 'Error',
                    message: 'Something went wrong while fetching stats. Please try again.'
                })
            } finally {
                this.loading = false
            }
        },

        async getTestsStatsByDateRange (from, to) {
            try {
                const response = await axios.get(`${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/stats/tests?dateFrom=${from.toString()}&dateTo=${to.toString()}`)

                return response.data
            } catch (err) {
                throw Error(err)
            }
        },

        async getAllTestsStats () {
            try {
                const response = await axios.get(`${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/stats/tests/all`)

                this.allTestsStats = response.data
            } catch (err) {
                throw Error(err)
            }
        },

        async getPatientsStats () {
            try {
                const response = await axios.get(`${process.env.VUE_APP_AXIOS_PATIENT_BASE_URL}/api/v1/stats`)

                this.patientsStats = response.data
            } catch (err) {
                throw Error(err)
            }
        },

        async getOrganisationsStats () {
            try {
                const response = await axios.get(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/stats`)

                this.organisationsStats = response.data
            } catch (err) {
                throw Error(err)
            }
        }
    }
}
</script>
