<template>
    <div>
        <p class="modal-heading">Set as Returned</p>
        <p class="text-sm">Are you sure you would like to override the selected {{ tests.length === 1 ? "test" : "tests" }} <strong>({{ tests.length }})</strong> to the status of Returned?</p>
        <div class="modal-status-warning">
            <img class="d-block" src="@/assets/images/icons/warning-icon.svg" width="20" alt="warning"><span>{{ tests.length  }} {{ tests.length  === 1 ? "test" : "tests" }} will be moved to</span><testStatuses :status="TestStatus.RETURNED" />
        </div>

        <div class="d-flex align-items-center mt-45">
            <button :disabled="loading" @click="close()" class="btn btn-cancel btn-lg lg-shadow d-flex flex-grow-1 justify-content-center align-items-center" :class="{disabled : loading}">Cancel</button>
            <button :disabled="loading" @click="setAsReturned()" class="btn btn-success btn-lg lg-shadow d-flex flex-grow-1 ml-3 justify-content-center align-items-center" :class="{disabled : loading}">Change status <span v-if="loading" class="btn-loading"></span></button>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mapActions } from 'vuex'
import TestStatus from '@/enums/TestStatus'
import TestStatuses from '@/components/TestStatusesComponent.vue'
export default {
    name: 'ChangeTestsToReturnedModalComponent',

    props: {
        tests: []
    },

    components: {
        TestStatuses
    },

    data () {
        return {
            loading: false,
            TestStatus
        }
    },

    methods: {
        ...mapActions({
            setNotification: 'NotificationStore/SetNotification',
            getStatusCountsAction: 'FulfilmentStatusCountsStore/GetStatusCounts'
        }),

        async setAsReturned () {
            this.loading = true
            this.isBusy = true
            const postBody = this.tests.map(test => {
                const testBody = {
                    organisationId: test.organisationId,
                    id: test.testId
                }
                return testBody
            })

            try {
                await axios.post(`${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/tests/ChangeTestsToReturned`, postBody)

                this.setNotification({
                        type: 'success',
                        title: 'Success',
                        message: 'The tests have been successfully changed to \'Returned\''
                })
                await this.getStatusCountsAction()
            } catch (err) {
                this.setNotification({
                    type: 'error',
                    title: 'Error',
                    message: 'Something went wrong. Please try again'
                })
            } finally {
                this.loading = false
                this.isBusy = false
            }

            this.$emit('close')
        },
        close () {
            this.$emit('close')
        }
    }
}
</script>
