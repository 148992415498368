<template>
    <div class="btn-block-mobile">
        <button onclick="this.blur();" @click="toggleScanning()" class="btn btn-block-mobile btn-lg lg-shadow mt-2 mt-md-0 ml-0 ml-md-3" :class="{'btn-success' : !scanning && !reassign, 'btn-primary' : !scanning && reassign, 'btn-danger' : scanning}">{{ !scanning ? buttonTitleComputed : 'Stop scanning' }}</button>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import axios from 'axios'
import ScannerCommandType from '@/enums/ScannerCommandType'
import ConfirmAssignDeviceModal from '@/components/modals/ConfirmAssignDeviceModalComponent'
import CheckPackageModalComponent from '@/components/modals/CheckPackageModalComponent'
import ConfirmVerifyDeviceModalComponent from '@/components/modals/ConfirmVerifyDeviceModalComponent'
export default {
    name: 'ToggleScanningComponent',

    props: {
        buttonTitle: String,
        uniqueCode: String,
        orderId: String,
        orderNumber: Number,
        reassign: Boolean(false),
        verifyScan: Boolean(false)
    },

    data () {
        return {
            loading: false,
            scanning: false,
            connection: null,
            ConfirmAssignDeviceModal,
            ScannerCommandType,
            CheckPackageModalComponent,
            ConfirmVerifyDeviceModalComponent
        }
    },

    beforeDestroy () {
        if (this.scanning) {
            this.disconnect(null)
        }
    },

    computed: {
        buttonTitleComputed () {
            if (this.buttonTitle) {
                return this.buttonTitle
            }

            return 'Start scanning'
        }
    },

    methods: {
        ...mapActions({
            setNotification: 'NotificationStore/SetNotification'
        }),

        toggleScanning () {
            if (!this.scanning) {
                this.scanning = true
                return this.connect()
            }

            this.scanning = false
            return this.disconnect('Successfully disconnected')
        },

        processMessage (msg) {
            if (msg.length < 3) {
                console.log('Error invalid server message')
                this.disconnect()
            }

            const msgType = msg.substring(0, 3)

            switch (msgType) {
                // errors
                case ScannerCommandType.BSO: {
                    this.disconnect('Barcode scanner offline')
                    break
                }

                case ScannerCommandType.NRO:
                    this.disconnect('NFC reader offline')
                    break

                case ScannerCommandType.ERR: {
                    this.disconnect('Something went wrong')
                    break
                }

                case ScannerCommandType.CMT:
                    this.disconnect('Current mode terminated')
                    break

                // results
                case ScannerCommandType.PMR: {
                    const data = msg.split(';')
                    const rawData = data[0].slice(4)
                    const shippingLabel = data[1]

                    console.log('Raw data ' + rawData)
                    console.log('Shipping label ' + shippingLabel)

                    this.assign(null, null, rawData, shippingLabel)
                    break
                }

                case ScannerCommandType.QMR: {
                    const data = msg.split(';')
                    const rawData = data[0].slice(4)
                    const shippingLabel = data[1]

                    console.log('Raw data ' + rawData)
                    console.log('Shipping label ' + shippingLabel)

                    this.verify(null, rawData, shippingLabel)
                    break
                }

                case ScannerCommandType.CPR: {
                    const data = msg.split(';')
                    const rawData = data[0].slice(4)
                    const shippingLabel = data[1]

                    console.log('Raw data ' + rawData)
                    console.log('Shipping label ' + shippingLabel)

                    this.checkPackage(null, rawData, shippingLabel)
                    break
                }

                case ScannerCommandType.QSR: {
                    const data = msg
                    const rawData = data.slice(4)

                    console.log('Raw data ' + rawData)

                    this.verify(this.orderId, rawData, null)
                    break
                }

                case ScannerCommandType.ASR: {
                    const data = msg
                    const rawData = data.slice(4)

                    console.log('Raw data ' + rawData)

                    this.checkDeviceAndAssign(rawData)
                    break
                }
            }
        },

        connect () {
            try {
                this.connection = new WebSocket(process.env.VUE_APP_WEBSOCKET_URL)
            } catch {
                this.scanning = false

                return this.setNotification({
                    type: 'error',
                    title: 'Error',
                    message: 'Could not connect to web socket.'
                })
            }

            this.connection.onmessage = function (event) {
                this.processMessage(event.data)
            }.bind(this)

            this.connection.onopen = function () {
                this.setNotification({
                    type: 'success',
                    title: 'Connected',
                    message: 'You are connected. Please start scanning.'
                })
                this.connection.send(this.uniqueCode)
            }.bind(this)

            this.connection.onerror = function () {
                this.disconnect('An error occured. You are disconnected.')
            }.bind(this)
        },

        disconnect (msg = null, showAlert = true) {
            if (this.connection) this.connection.close()
            this.scanning = false

            if (showAlert && msg) {
                this.setNotification({
                    type: msg === 'Successfully disconnected' ? 'success' : 'error',
                    title: 'Disconnected',
                    message: msg
                })
            }
        },

        async checkDeviceAndAssign (rawData) {
            this.loading = true

            try {
                const response = await axios.post(`${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/devices/parse-for-assignment`, {
                    rawData: rawData,
                    orderId: this.orderId
                })

                this.$modal.show(ConfirmAssignDeviceModal, { orderId: this.orderId, orderNumber: this.orderNumber, rawData: rawData, parsedResponse: response.data, reassign: this.reassign }, { width: '400px' })
            } catch {
                this.setNotification({
                    type: 'error',
                    title: 'Error',
                    message: 'There was an error with this device.'
                })
            } finally {
                this.loading = false
                this.disconnect(null, false)
            }
        },

        async assign (orderId = null, shippingOrderNumber = null, rawData, shippingLabel = null) {
            this.loading = true

            try {
                const response = await axios.patch(`${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/orders/device/assign/`, {
                    orderId: orderId,
                    shippingOrderNumber: shippingOrderNumber,
                    rawData: rawData,
                    shippingLabel: shippingLabel
                })

                this.$emit('deviceAssigned', response)
            } catch (err) {
                this.setNotification({
                    type: 'error',
                    title: 'Error',
                    message: err.data.detail ? err.data.detail : 'Something went wrong trying to assign the device. Please try again.'
                })
            } finally {
                this.loading = false
            }
        },

        async verify (orderId = null, rawData, shippingLabel = null) {
            this.loading = true

            try {
                const response = await axios.post(`${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/orders/verify`, {
                    orderId: orderId,
                    rawData: rawData,
                    shippingLabel: shippingLabel
                })

                if (response.data.deviceClockFaulty) {
                    this.$modal.hideAll()
                    this.$modal.show(ConfirmVerifyDeviceModalComponent, { orderId: this.orderId, orderNumber: this.orderNumber, rawData: rawData, parsedResponse: response.data, shippingLabel: shippingLabel }, { width: '440px' })
                } else {
                    this.$emit('deviceVerified', response)
                }
            } catch (err) {
                this.setNotification({
                    type: 'error',
                    title: 'Error',
                    message: err.data.errorMessage ? err.data.errorMessage : 'Something went wrong trying to verify the device. Please try again.'
                })
            } finally {
                this.loading = false
            }
        },

        closingCheckModal (event) {
            console.log('disconnecting')
            this.disconnect()
        },

        async checkPackage (orderId = null, rawData, shippingLabel = null) {
            this.loading = true
            try {
                const response = await axios.post(`${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/orders/checkpackage`, {
                    orderId: orderId,
                    rawData: rawData,
                    shippingLabel: shippingLabel
                })
                this.$modal.hideAll()
                this.$modal.show(CheckPackageModalComponent, { orderId: this.orderId, orderNumber: this.orderNumber, rawData: rawData, parsedResponse: response.data }, { width: '440px' }, { 'before-close': this.closingCheckModal })
            } catch (err) {
                this.$modal.hideAll()
                var errorMsg = err.data?.errorMessage ? err.data.errorMessage : 'Something went wrong trying to check the package. Please try again.'
                this.$modal.show(CheckPackageModalComponent, { orderId: this.orderId, orderNumber: this.orderNumber, rawData: rawData, parsedResponse: err.data, errorMsg: errorMsg }, { width: '440px' }, { 'before-close': this.closingCheckModal })
            } finally {
                this.loading = false
            }
        },

        onVerifyConfirmed (response) {
            this.$emit('deviceVerified', response)
        }
    }
}
</script>
