<template>
    <div>
        <Breadcrumbs />

        <div v-if="organisation == null">
            <div class="col">
                <div class="alert alert-info">
                    There is no organisation set.
                </div>
            </div>
        </div>

        <div v-else-if="!organisation.dataStoreId" class="row mt-3">
            <div class="col">
                <div v-if="userIsOwner" class="alert alert-info">
                    There is no data store set for this organisation. Please edit your organisation and set a data store.
                </div>
                <div v-else class="alert alert-info">
                    There is no data store set for this organisation. Please contact your system administrator.
                </div>
            </div>
        </div>

        <div v-else>
            <div class="row mt-3">
                <div class="col">
                    <div class="card text-sm">
                        <div class="card-body">
                            <h5 class="mb-0">Facility details</h5>

                            <div class="row mt-4">
                                <div class="col-xl-5">
                                    <div class="form-group row">
                                        <label for="name" class="col-sm-4 col-form-label">Name <span class="text-danger">*</span></label>
                                        <div class="col-sm-8">
                                            <input type="text" class="form-control" v-model="form.facilityName">
                                            <small v-if="errors && errors.facilityName" class="text-danger">
                                                {{ errors.facilityName[0] }}
                                            </small>
                                        </div>
                                    </div>

                                    <div v-if="organisation.countryCode == 'GB'" class="form-group row">
                                        <label for="autocomplete" class="col-sm-4 col-form-label">Find Address</label>
                                        <div class="col-sm-8">
                                            <input type="text" class="form-control" @input="findAddress()" v-model="addressQueryString" placeholder="Search for address" autocomplete="new-password" />
                                            <ul v-if="autocompleteSuggestions.length" class="list-group autocomplete-suggestions">
                                                <li v-for="(suggestion, index) in autocompleteSuggestions" :key="index" class="list-group-item" @click="chooseSuggestion(suggestion.id)">{{ suggestion.address }}</li>
                                            </ul>
                                            <button class="btn show-manual-address" @click="enterAddressManually()">Enter address manually</button>
                                    </div>
                                    </div>

                                    <div v-if="expandManualUkAddress">
                                        <div class="form-group row">
                                            <label for="address1" class="col-sm-4 col-form-label">Address 1 <span class="text-danger">*</span></label>
                                            <div class="col-sm-8">
                                                <input type="text" class="form-control" :class="{'border border-danger' : errors && errors.facilityAddress1}" id="address1" v-model="form.facilityAddress1">
                                                <small v-if="errors && errors.facilityAddress1" class="text-danger">
                                                    {{ errors.facilityAddress1[0] }}
                                                </small>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label for="address2" class="col-sm-4 col-form-label">Address 2 <span class="text-danger">*</span></label>
                                            <div class="col-sm-8">
                                                <input type="text" class="form-control" :class="{'border border-danger' : errors && errors.facilityAddress2}" id="address2" v-model="form.facilityAddress2">
                                                <small v-if="errors && errors.facilityAddress2" class="text-danger">
                                                    {{ errors.facilityAddress2[0] }}
                                                </small>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label for="address3" class="col-sm-4 col-form-label">Address 3</label>
                                            <div class="col-sm-8">
                                                <input type="text" class="form-control" :class="{'border border-danger' : errors && errors.facilityAddress3}" id="address3" v-model="form.facilityAddress3">
                                                <small v-if="errors && errors.facilityAddress3" class="text-danger">
                                                    {{ errors.facilityAddress3[0] }}
                                                </small>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label for="postalCode" class="col-sm-4 col-form-label">Postcode <span class="text-danger">*</span></label>
                                            <div class="col-sm-8">
                                                <input type="text" class="form-control" :class="{'border border-danger' : errors && errors.facilityPostalCode}" id="postalCode" v-model="form.facilityPostalCode">
                                                <small v-if="errors && errors.facilityPostalCode" class="text-danger">
                                                    {{ errors.facilityPostalCode[0] }}
                                                </small>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label for="country" class="col-sm-4 col-form-label">Country <span class="text-danger">*</span></label>
                                            <div class="col-sm-8">
                                                <input type="text" class="form-control" id="country" v-model="form.facilityCountry">
                                                <small v-if="errors && errors.facilityCountry" class="text-danger">
                                                    {{ errors.facilityCountry[0] }}
                                                </small>
                                            </div>
                                        </div>

                                    </div>

                                    <div v-if="expandManualUsAddress">
                                        <div class="form-group row">
                                            <label for="address1" class="col-sm-4 col-form-label">Address 1 <span class="text-danger">*</span></label>
                                            <div class="col-sm-8">
                                                <input type="text" class="form-control" :class="{'border border-danger' : errors && errors.facilityAddress1}" id="address1" v-model="form.facilityAddress1">
                                                <small v-if="errors && errors.facilityAddress1" class="text-danger">
                                                    {{ errors.facilityAddress1[0] }}
                                                </small>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label for="address2" class="col-sm-4 col-form-label">Address 2 <span class="text-danger">*</span></label>
                                            <div class="col-sm-8">
                                                <input type="text" class="form-control" :class="{'border border-danger' : errors && errors.facilityAddress2}" id="address2" v-model="form.facilityAddress2">
                                                <small v-if="errors && errors.facilityAddress2" class="text-danger">
                                                    {{ errors.facilityAddress2[0] }}
                                                </small>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label for="address3" class="col-sm-4 col-form-label">City</label>
                                            <div class="col-sm-8">
                                                <input type="text" class="form-control" :class="{'border border-danger' : errors && errors.facilityAddress3}" id="address3" v-model="form.facilityAddress3">
                                                <small v-if="errors && errors.facilityAddress3" class="text-danger">
                                                    {{ errors.facilityAddress3[0] }}
                                                </small>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label for="address4" class="col-sm-4 col-form-label">State</label>
                                            <div class="col-sm-8">
                                                <input type="text" class="form-control" :class="{'border border-danger' : errors && errors.facilityAddress4}" id="address4" v-model="form.facilityAddress4">
                                                <small v-if="errors && errors.facilityAddress4" class="text-danger">
                                                    {{ errors.facilityAddress4[0] }}
                                                </small>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label for="postalCode" class="col-sm-4 col-form-label">Zip code <span class="text-danger">*</span></label>
                                            <div class="col-sm-8">
                                                <input type="text" class="form-control" :class="{'border border-danger' : errors && errors.facilityPostalCode}" id="postalCode" v-model="form.facilityPostalCode">
                                                <small v-if="errors && errors.facilityPostalCode" class="text-danger">
                                                    {{ errors.facilityPostalCode[0] }}
                                                </small>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="fulfilment-provider" class="col-sm-4 col-form-label">Fulfilment provider <span class="text-danger">*</span></label>
                                        <div class="col-sm-8">
                                            <select type="text" class="custom-select" v-model="form.facilityFulfimentProvider">
                                                <option value="0">Digostics</option>
                                            </select>
                                            <small v-if="errors && errors.facilityFulfimentProvider" class="text-danger">
                                                {{ errors.facilityFulfimentProvider[0] }}
                                            </small>
                                        </div>
                                    </div>

                                    <div class="form-group row align-items-center">
                                        <label for="status" class="col-sm-4 col-form-label">Status <span class="text-danger">*</span></label>
                                        <div class="col-sm-8">
                                            <b-form-checkbox v-model="form.statusChecked" name="check-button" switch class="custom-switch-md">
                                                {{ form.statusChecked ? 'Active' : 'Inactive' }}
                                            </b-form-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h5 class="mb-0 mt-5">Primary contact</h5>

                            <div class="row mt-4">
                                <div class="col-xl-5">
                                    <div class="form-group row">
                                        <label for="title" class="col-sm-4 col-form-label">Title <span class="text-danger">*</span></label>
                                        <div class="col-sm-8">
                                            <select type="text" class="custom-select" v-model="form.title">
                                                <option :value="Salutation.MR">Mr</option>
                                                <option :value="Salutation.MRS">Mrs</option>
                                                <option :value="Salutation.MISS">Miss</option>
                                                <option :value="Salutation.MS">Ms</option>
                                                <option :value="Salutation.DR">Dr</option>
                                                <option :value="Salutation.SIR">Sir</option>
                                                <option :value="Salutation.PROFESSOR">Professor</option>
                                            </select>
                                            <small v-if="errors && errors.title" class="text-danger">
                                                {{ errors.title[0] }}
                                            </small>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="first-name" class="col-sm-4 col-form-label">First name <span class="text-danger">*</span></label>
                                        <div class="col-sm-8">
                                            <input type="text" class="form-control" v-model="form.firstName">
                                            <small v-if="errors && errors.firstName" class="text-danger">
                                                {{ errors.firstName[0] }}
                                            </small>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="middle-names" class="col-sm-4 col-form-label">Middle names</label>
                                        <div class="col-sm-8">
                                            <input type="text" class="form-control" v-model="form.middleNames">
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="surname" class="col-sm-4 col-form-label">Surname <span class="text-danger">*</span></label>
                                        <div class="col-sm-8">
                                            <input type="text" class="form-control" v-model="form.surname">
                                            <small v-if="errors && errors.surname" class="text-danger">
                                                {{ errors.surname[0] }}
                                            </small>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="phone" class="col-sm-4 col-form-label">Phone number <span class="text-danger">*</span></label>
                                        <div class="col-sm-8">
                                            <input type="text" class="form-control" v-model="form.phone">
                                            <small v-if="errors && errors.phone" class="text-danger">
                                                {{ errors.phone[0] }}
                                            </small>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="email" class="col-sm-4 col-form-label">Email address <span class="text-danger">*</span></label>
                                        <div class="col-sm-8">
                                            <input type="text" class="form-control" v-model="form.email">
                                            <small v-if="errors && errors.email" class="text-danger">
                                                {{ errors.email[0] }}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="fixed-bottom bg-white text-right center-mobile p-3">
                <button :disabled="loading" @click="cancel()" class="btn btn-cancel btn-lg lg-shadow" :class="{disabled : loading}">Cancel</button>
                <button :disabled="loading" @click="create()" class="btn btn-success btn-lg lg-shadow pl-55 pr-55 ml-2" :class="{disabled : loading}">Create <span v-if="loading" class="btn-loading"></span></button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import Breadcrumbs from '@/partials/site-structure/BreadcrumbsComponent'
import Salutation from '@/enums/Salutation'
export default {
    name: 'CreateFacility',

    components: {
        Breadcrumbs
    },

    props: {
        organisation: Object,
        organisationName: String
    },

    data () {
        return {
            loading: false,
            expandManualUkAddress: false,
            expandManualUsAddress: false,
            addressQueryString: null,
            autocompleteSuggestions: [],
            form: {
                facilityAddress1: null,
                facilityAddress2: null,
                facilityAddress3: null,
                facilityAddress4: null,
                facilityPostalCode: null,
                facilityCountry: null,
                facilityLatitude: null,
                facilityLongitude: null,
                facilityCountryCode: null,
                facilityFulfimentProvider: 0,
                statusChecked: true
            },
            errors: {},
            Salutation
        }
    },

    mounted () {
        if (!this.organisation) return this.$router.push({ name: 'Organisations' })

        if (this.organisation.countryCode === 'GB') {
            this.expandManualUsAddress = false
            this.form.facilityCountryCode = 'GB'
            return
        }

        if (this.organisation.countryCode === 'US') {
            this.expandManualUkAddress = false
            this.expandManualUsAddress = true
            this.form.facilityCountry = 'US'
            this.form.facilityCountryCode = 'US'
            return
        }

        this.expandManualUkAddress = false
        this.expandManualUsAddress = false
        this.form.facilityCountryCode = null
    },

    computed: {
        ...mapGetters({
            userIsOwner: 'UserStore/GetIsUserOwner'
        })
    },

    methods: {
        ...mapActions({
            setNotification: 'NotificationStore/SetNotification'
        }),

        async findAddress () {
            const response = await axios.get(`https://api.getAddress.io/autocomplete/${this.addressQueryString}?api-key=${process.env.VUE_APP_GET_ADDRESS_API_KEY}`)

            this.autocompleteSuggestions = response.data.suggestions
        },

        async chooseSuggestion (id) {
            this.clearAddress()

            const response = await axios.get(`https://api.getAddress.io/get/${id}?api-key=${process.env.VUE_APP_GET_ADDRESS_API_KEY}`)

            if (response.data) {
                this.autocompleteSuggestions = []
                this.addressQueryString = null
                this.expandManualUkAddress = true
                this.form.facilityAddress1 = response.data.line_1
                this.form.facilityAddress2 = response.data.town_or_city
                this.form.facilityAddress3 = response.data.county
                this.form.facilityPostalCode = response.data.postcode
                this.form.facilityCountry = response.data.country
                this.form.facilityLatitude = response.data.latitude
                this.form.facilityLongitude = response.data.longitude
            }
        },

        clearAddress () {
            this.fullAddress = null
            this.form.facilityAddress1 = null
            this.form.facilityAddress2 = null
            this.form.facilityAddress3 = null
            this.form.facilityPostalCode = null
            this.form.facilityCountry = null
            this.form.facilityLatitude = null
            this.form.facilityLongitude = null
        },

        enterAddressManually () {
            this.expandManualUkAddress = true
        },

        cancel () {
            return this.$router.push({ name: 'ViewOrganisation', params: { id: this.$route.params.id } })
        },

        async create () {
            this.expandManualAddress = true
            this.checkForm()

            if (!Object.keys(this.errors).length) {
                this.loading = true

                const facilityModel = {
                    organisationName: this.organisationName,
                    name: this.form.facilityName,
                    fulfilmentProvider: this.form.facilityFulfimentProvider,
                    isActive: this.form.statusChecked,
                    address1: this.form.facilityAddress1,
                    address2: this.form.facilityAddress2,
                    ddress3: this.form.facilityAddress3 ?? null,
                    address4: this.form.facilityAddress4 ?? null,
                    postalCode: this.form.facilityPostalCode,
                    country: this.form.facilityCountry,
                    countryCode: this.form.facilityCountryCode,
                    latitude: this.form.latitude ?? 0,
                    longitude: this.form.longitude ?? 0,
                    title: this.form.title,
                    firstName: this.form.firstName,
                    middleNames: this.form.middleNames,
                    surname: this.form.surname,
                    phone: this.form.phone,
                    email: this.form.email
                }

                try {
                    await axios.post(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/facilities/${this.$route.params.id}`, facilityModel)

                    this.setNotification({
                        type: 'success',
                        title: 'Success',
                        message: 'The facility was added.'
                    })

                    return this.$router.push({ name: 'ViewOrganisation', params: { id: this.$route.params.id } })
                } catch (err) {
                    this.setNotification({
                        type: 'error',
                        title: 'Error',
                        message: err.data.detail ? err.data.detail : 'Something went wrong. The facility was not added.'
                    })
                } finally {
                    this.loading = false
                }
            }
        },

        checkForm () {
            this.errors = {}

            if (!this.form.facilityName) {
                this.errors.facilityName = ['The facility name field is required']
            }

            if (this.form.facilityFulfimentProvider == null || parseInt(this.facilityFulfimentProvider) < 0) {
                this.errors.facilityFulfimentProvider = ['The facility fulfilment provider field is required']
            }

            if (!this.form.facilityAddress1) {
                this.errors.facilityAddress1 = ['The facility address 1 field is required']
            }

            if (!this.form.facilityAddress2) {
                this.errors.facilityAddress2 = ['The facility address 2 field is required']
            }

            if (!this.form.facilityPostalCode) {
                this.errors.facilityPostalCode = ['The facility postal code field is required']
            }

            if (!this.form.facilityCountry) {
                this.errors.facilityCountry = ['The facility country field is required']
            }

            if (!this.form.facilityCountryCode) {
                this.errors.facilityCountryCode = ['The facility country code field is required']
            }

            if (this.form.title == null || parseInt(this.form.title) < 0) {
                this.errors.title = ['The user title field is required']
            }

            if (!this.form.firstName) {
                this.errors.firstName = ['The user first name field is required']
            }

            if (!this.form.surname) {
                this.errors.surname = ['The user surname field is required']
            }

            if (!this.form.phone) {
                this.errors.phone = ['The user phone number field is required']
            }

            if (this.form.phone != null && !this.form.phone.match(/[0-9]{9,20}/)) {
                this.errors.phone = ['The phone number must be a minimum of 9 digits']
            }

            if (!this.form.email) {
                this.errors.email = ['The user email field is required']
            }

            if (this.form.email != null && !this.form.email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                this.errors.email = ['This not a valid email address']
            }
        }
    }
}
</script>
