<template>
    <div>
        <Loader v-if="loading" />

        <div v-if="!loading && Object.keys(hcp).length">
            <Breadcrumbs
                :dynamicTitle="`${formatTitle(hcp.title)} ${hcp.firstName} ${hcp.surname}`"
            />

            <div class="card text-sm mt-3">
                <div class="card-body">
                    <div class="d-flex flex-column flex-md-row justify-content-between">
                        <h5 class="mb-0">HCP details</h5>
                        <div class="d-flex justify-content-end flex-column">
                            <div>
                                <button :disabled="loading" @click="setTraining()" class="btn btn-success sm-shadow mt-4 mt-md-0 px-4" :class="{disabled : loading}">Set Training</button>
                                <button :disabled="loading" @click="save()" class="btn btn-success sm-shadow mt-4 mt-md-0 px-4 ml-md-3" :class="{disabled : loading}">Save changes</button>
                            </div>
                        </div>
                    </div>
                    <span v-if="Object.keys(errors).length" class="text-sm d-block text-danger text-right mt-2">There are errors on the page. Please review them and try again</span>

                    <div class="row justify-content-between mt-4">
                        <div class="col-xl-5">
                            <div class="form-group row">
                                <label for="title" class="col-sm-4 col-form-label">Title <span class="text-danger">*</span></label>
                                <div class="col-sm-8">
                                    <select type="text" class="custom-select" v-model="hcp.title">
                                        <option :value="Salutation.MR">Mr</option>
                                        <option :value="Salutation.MRS">Mrs</option>
                                        <option :value="Salutation.MISS">Miss</option>
                                        <option :value="Salutation.MS">Ms</option>
                                        <option :value="Salutation.DR">Dr</option>
                                        <option :value="Salutation.SIR">Sir</option>
                                        <option :value="Salutation.PROFESSOR">Professor</option>
                                    </select>
                                    <small v-if="errors && errors.title" class="text-danger">
                                        {{ errors.title[0] }}
                                    </small>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="first-name" class="col-sm-4 col-form-label">First Name <span class="text-danger">*</span></label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" v-model="hcp.firstName">
                                    <small v-if="errors && errors.firstName" class="text-danger">
                                        {{ errors.firstName[0] }}
                                    </small>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="surname" class="col-sm-4 col-form-label">Middle names</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" v-model="hcp.middleNames">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="surname" class="col-sm-4 col-form-label">Surname <span class="text-danger">*</span></label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" v-model="hcp.surname">
                                    <small v-if="errors && errors.surname" class="text-danger">
                                        {{ errors.surname[0] }}
                                    </small>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="email" class="col-sm-4 col-form-label">Email address <span class="text-danger">*</span></label>
                                <div class="col-sm-8">
                                    <input type="email" class="form-control" v-model="hcp.email">
                                    <small v-if="errors && errors.email" class="text-danger">
                                        {{ errors.email[0] }}
                                    </small>
                                </div>
                            </div>

                            <div class="form-group row align-items-center">
                                <label for="fulfilment-provider" class="col-sm-4 col-form-label">Status <span class="text-danger">*</span></label>
                                <div class="col-sm-8">
                                    <b-form-checkbox v-model="hcp.isActive" name="check-button" switch class="custom-switch-md">
                                        {{ hcp.isActive ? 'Active' : 'Inactive' }}
                                    </b-form-checkbox>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="title" class="col-sm-4 col-form-label">Speciality <span class="text-danger">*</span></label>
                                <div class="col-sm-8">
                                    <select type="text" class="custom-select" v-model="hcp.speciality">
                                        <option value="0">Obstetrician</option>
                                        <option value="1">Midwife</option>
                                        <option value="2">HCA</option>
                                        <option value="3">Nurse</option>
                                        <option value="4">GP</option>
                                        <option value="5">SCC</option>
                                        <option value="6">Other</option>
                                    </select>
                                    <small v-if="errors && errors.speciality" class="text-danger">
                                        {{ errors.speciality[0] }}
                                    </small>
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-sm-4 font-weight-medium col-form-label">
                                    Notifications <span class="text-danger">*</span>
                                    <p class="text-sm text-muted"><i>User can configure notification settings for their organisation</i></p>
                                </div>

                                <div class="col-sm-8">
                                    <b-form-checkbox v-model="hcp.isNotificationManager" name="check-button" switch class="custom-switch-md">
                                        {{ hcp.isNotificationManager ? 'Yes' : 'No' }}
                                    </b-form-checkbox>
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-sm-4 font-weight-medium col-form-label">Training Version</div>
                                <div class="col-sm-8 col-form-label">{{ hcp.trainingVersionCompleted }}</div>
                            </div>

                            <div class="form-group row">
                                <div class="col-sm-4 font-weight-medium col-form-label">Training Completed Date</div>
                                <div class="col-sm-8 col-form-label">{{ formatDateTime(hcp.trainingCompletedDateTime) }}</div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div v-if="results && results.results.length" class="mt-5">
                <h5>Facilities <span class="font-italic">({{ results.results.length }})</span></h5>

                <b-table
                    v-if="results.results.length"
                    ref="table"
                    id="table"
                    class="custom-table padding-lg bg-white mt-4"
                    responsive
                    no-sort-reset
                    :items="results.results"
                    :fields="filteredFields"
                >
                    <template #cell(name)="data">
                        <router-link class="font-weight-bold underlined text-capitalize" :to="{name: 'ViewFacility', params: {oId : data.item.organisationId, fId : data.item.id}}">{{ data.item.name }}</router-link>
                    </template>

                    <template #cell(action)="data">
                        <button v-if="!hcp.facilityIds.includes(data.item.id)" @click="selectFacility(data.item.id)" class="btn btn-primary sm-shadow text-nowrap">Select</button>
                        <button v-if="hcp.facilityIds.includes(data.item.id)" @click="removeFacility(data.item.id)" class="btn btn-danger sm-shadow text-nowrap">Remove</button>
                    </template>
                </b-table>

                <small v-if="errors && errors.facilities" class="text-danger">
                    {{ errors.facilities[0] }}
                </small>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import axios from 'axios'
import Loader from '@/partials/Loader'
import Breadcrumbs from '@/partials/site-structure/BreadcrumbsComponent'
import FormatterMixin from '@/mixins/FormatterMixin'
import Salutation from '@/enums/Salutation'
import TrainingVersion from '@/enums/TrainingVersion'
export default {
    name: 'EditHcp',

    mixins: [
        FormatterMixin
    ],

    components: {
        Loader,
        Breadcrumbs
    },

    data () {
        return {
            loading: true,
            hcp: {},
            results: {},
            errors: {},
            Salutation
        }
    },

    async mounted () {
        try {
            const hcpResponse = await axios.get(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/practitioners/${this.$route.params.oId}/${this.$route.params.pId}`)
            const facilities = await axios.get(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/facilities/${this.$route.params.oId}/100/0/NAME/ASCENDING`)

            this.hcp = hcpResponse.data
            this.results = facilities.data
        } catch {
            this.setNotification({
                type: 'error',
                title: 'Error',
                message: 'Something went wrong while fetching the HCP. Please try again.'
            })
        } finally {
            this.loading = false
        }
    },

    computed: {
        filteredFields () {
            return [
                {
                    key: 'name',
                    label: 'Name',
                    sortable: true
                },
                {
                    key: 'action',
                    label: ''
                }
            ]
        }
    },

    methods: {
        ...mapActions({
            setNotification: 'NotificationStore/SetNotification'
        }),

        selectFacility (id) {
            if (!this.hcp.facilityIds.includes(id)) {
                this.hcp.facilityIds.push(id)
            }
        },

        removeFacility (id) {
            if (this.hcp.facilityIds.includes(id)) {
                const index = this.hcp.facilityIds.indexOf(id)

                this.hcp.facilityIds.splice(index, 1)
            }
        },

        async save () {
            this.checkForm()

            if (!Object.keys(this.errors).length) {
                this.loading = true

                try {
                    await axios.patch(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/practitioners/${this.$route.params.oId}/${this.$route.params.pId}`, this.hcp)

                    this.$router.push({ name: 'ViewHcp', params: { oId: this.$route.params.oId, pId: this.$route.params.pId } })

                    return this.setNotification({
                        type: 'success',
                        title: 'Success',
                        message: 'The practitioner was updated.'
                    })
                } catch {
                    this.setNotification({
                        type: 'error',
                        title: 'Error',
                        message: 'Something went wrong. The practitioner was not updated.'
                    })
                } finally {
                    this.loading = false
                }
            }
        },

        checkForm () {
            this.errors = {}

            if (this.hcp.title == null || parseInt(this.hcp.title) < 0) {
                this.errors.title = ['The title field is required']
            }

            if (!this.hcp.firstName) {
                this.errors.firstName = ['The first name field is required']
            }

            if (!this.hcp.surname) {
                this.errors.surname = ['The surname field is required']
            }

            if (!this.hcp.email) {
                this.errors.email = ['The user email field is required']
            }

            if (this.hcp.email != null && !this.hcp.email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                this.errors.email = ['This not a valid email address']
            }

            if (this.hcp.speciality == null || parseInt(this.hcp.speciality) < 0) {
                this.errors.speciality = ['The speciality field is required']
            }

            if (!this.hcp.facilityIds.length) {
                this.errors.facilities = ['You must select at least one facility']
            }
        },

        async setTraining () {
            this.loading = true
            try {
                const response = await axios.patch(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/practitioners/complete-training/${this.$route.params.oId}/${this.$route.params.pId}`, {
                    trainingVersion: TrainingVersion.CURRENT,
                    trainingCompletedDateTime: new Date().toISOString() // UTC
                })

                this.hcp = response.data
            } catch {
                this.loading = false
                this.setNotification({
                    type: 'error',
                    title: 'Error',
                    message: 'Sorry, something went wrong. Please try again.'
                })
            } finally {
                this.loading = false
            }
        }
    }
}
</script>
