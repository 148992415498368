<template>
    <div>
        <img class="d-block mx-auto" src="@/assets/images/icons/green-tick.svg" width="80" alt="tick">
        <p class="text-center lead font-weight-bold mt-3">Device found</p>
        <div class="text-sm mt-4">
            <div class="row">
                <div class="col-4">Device number</div>
                <div class="col-8">{{ parsedResponse.serialNumber }}</div>
            </div>

            <hr />

            <div class="row">
                <div class="col-4">Assign to</div>
                <div class="col-8">Order {{ orderNumber }}</div>
            </div>

            <hr />

            <div v-if="parsedResponse.isAssigned" class="alert alert-danger mt-4" role="alert">
                This device number is currently assigned to another order
            </div>

            <div class="d-flex align-items-center mt-45">
                <button :disabled="loading" @click="$emit('close')" class="btn btn-cancel btn-lg lg-shadow d-flex flex-grow-1 w-45 justify-content-center align-items-center" :class="{disabled : loading}">Cancel</button>
                <button v-if="!parsedResponse.isAssigned" :disabled="loading" @click="confirm()" class="btn btn-success btn-lg lg-shadow d-flex flex-grow-1 w-55 ml-3 justify-content-center align-items-center" :class="{disabled : loading}">Assign <span v-if="loading" class="btn-loading"></span></button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import axios from 'axios'
export default {
    name: 'ConfirmAssignDeviceModalComponent',

    props: {
        orderId: String,
        orderNumber: Number,
        rawData: String,
        parsedResponse: Object,
        reassign: Boolean(false)
    },

    data () {
        return {
            loading: false
        }
    },

    methods: {
        ...mapActions({
            setShouldRefreshOrder: 'AppStore/SetShouldRefreshOrder',
            setNotification: 'NotificationStore/SetNotification'
        }),

        async confirm () {
            this.loading = true

            try {
                if (!this.reassign) {
                    await axios.patch(`${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/orders/device/assign/`, {
                        orderId: this.orderId,
                        rawData: this.rawData
                    })
                } else {
                    await axios.patch(`${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/orders/device/reassign/`, {
                        orderId: this.orderId,
                        rawData: this.rawData
                    })
                }

                this.setShouldRefreshOrder(true)
                this.$emit('close')

                this.setNotification({
                    type: 'success',
                    title: 'Device assigned',
                    message: 'Device was assigned successfully.'
                })
            } catch (err) {
                this.$emit('close')

                this.setNotification({
                    type: 'error',
                    title: 'Error',
                    message: err.data.detail ? err.data.detail : 'There was an error. The device was not assigned.'
                })
            } finally {
                this.loading = false
            }
        },

        close () {
            this.$emit('close')
        }
    }
}
</script>
