<template>
    <div v-if="parsedResponse">
        <img class="d-block mx-auto" src="@/assets/images/icons/warning-icon.svg" width="80" alt="warning">
        <p class="text-center lead font-weight-bold mt-3">Please confirm verification</p>
        <div class="text-sm mt-4">
            <div class="row">
                <div class="col-4">Device number</div>
                <div class="col-8">{{ parsedResponse.serialNumber }}</div>
            </div>

            <hr />

            <div class="row">
                <div class="col-4">Order no</div>
                <div class="col-8">{{ parsedResponse.orderNumber }}</div>
            </div>

            <hr />

            <div v-if="parsedResponse.deviceClockFaulty" class="alert alert-danger mt-4" role="alert">
                <p>This device appears to have a faulty clock</p>

                <div class="row">
                    <div class="col-5">Device clock date</div>
                    <div class="col-7">{{ parsedResponse.deviceClockCurrentDateTime | moment("DD/MM/YYYY HH:mm") }}</div>
                </div>

                <div class="row">
                    <div class="col-5">Assignment date</div>
                    <div class="col-7">{{ parsedResponse.deviceAssignedDateTime | moment("DD/MM/YYYY HH:mm") }}</div>
                </div>

                <p class="mt-3 mb-0">The above dates are expected to match. If you wish to continue with this device, click 'Confirm'</p>
            </div>

            <div class="d-flex align-items-center mt-45">
                <button :disabled="loading" @click="$emit('close')" class="btn btn-cancel btn-lg lg-shadow d-flex flex-grow-1 w-45 justify-content-center align-items-center" :class="{disabled : loading}">Cancel</button>
                <button v-if="!parsedResponse.isAssigned" :disabled="loading" @click="confirm()" class="btn btn-success btn-lg lg-shadow d-flex flex-grow-1 w-55 ml-3 justify-content-center align-items-center" :class="{disabled : loading}">Confirm <span v-if="loading" class="btn-loading"></span></button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import axios from 'axios'
import FormatterMixin from '@/mixins/FormatterMixin'
export default {
    name: 'ConfirmVerifyDeviceModalComponent',

    props: {
        orderId: String,
        orderNumber: Number,
        rawData: String,
        parsedResponse: Object,
        shippingLabel: String
    },

    mixins: [
        FormatterMixin
    ],

    data () {
        return {
            loading: false
        }
    },

    methods: {
        ...mapActions({
            setShouldRefreshOrder: 'AppStore/SetShouldRefreshOrder',
            setVerifyOrder: 'AppStore/SetVerifyOrder',
            setNotification: 'NotificationStore/SetNotification'
        }),

        async confirm () {
            this.loading = true

            try {
                const response = await axios.post(`${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/orders/verify`, {
                    orderId: this.orderId,
                    rawData: this.rawData,
                    shippingLabel: this.shippingLabel,
                    ForceAssignIfDeviceClockFaulty: true
                })

                this.setVerifyOrder(response.data.orderNumber)
                this.$emit('close')
            } catch (err) {
                this.setNotification({
                    type: 'error',
                    title: 'Error',
                    message: err.data.errorMessage ? err.data.errorMessage : 'Something went wrong trying to verify the device. Please try again.'
                })
            } finally {
                this.loading = false
            }
        },

        close () {
            this.$emit('close')
        }
    }
}
</script>
