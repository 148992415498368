<template>
    <div class="row mt-4">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <h5 class="mb-0">Notes</h5>

                    <div v-if="notes.length">
                        <div class="note mt-4 border border-danger" v-for="(note, index) in notes" :key="index">
                            <div class="note-header d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
                                <div>
                                    <p class="mb-0 creator">{{ note.practitionerName }}</p>
                                </div>
                                <div>
                                    <p class="mb-0 text-sm">
                                    <span v-if="note.type == TestNoteType.ISSUE" class="font-weight-bold font-italic mr-4">Reported issue note</span>
                                    <span v-if="note.type == TestNoteType.SYSTEM" class="font-weight-bold font-italic mr-4">System note</span>
                                    {{ note.dateCreated | moment("DD/MM/YYYY HH:mm")}}</p>
                                </div>
                            </div>

                            <div class="note-body text-sm">
                                {{ note.text }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TestNoteType from '@/enums/TestNoteType'
export default {
    name: 'TestNotesComponent',

    props: {
        notes: Array
    },

    data () {
        return {
            loading: false,
            TestNoteType
        }
    },

    computed: {
        ...mapGetters({
            userFullName: 'UserStore/GetUserFullName'
        })
    }
}
</script>
