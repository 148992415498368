<template>
    <div class="row">
        <div class="col">
            <h5>Tests <span class="font-italic">({{ results.totalCount }})</span></h5>

            <div v-if="!results.results.length" class="alert alert-info mt-4">
                There are no tests for this patient
            </div>

            <div v-else>
                <b-table
                    ref="table"
                    class="custom-table bg-white"
                    responsive
                    no-sort-reset
                    :sort-by.sync="sortBySync"
                    :sort-desc.sync="sortDesc"
                    @sort-changed="sortingChanged"
                    :no-local-sorting="true"
                    :busy.sync="isBusy"
                    :fields="filteredTestsFields"
                    :items="results.results"
                    :tbody-tr-class="rowClass"
                    id="tests-table"
                >
                    <template #cell(testNumber)="data">
                        <span @click="viewTest(data.item.id)" class="font-weight-bold underlined text-capitalize" :class="{'cursor-pointer' : data.item.id !== $route.params.tId}">{{ data.item.testNumber }}</span>
                        <span v-if="data.item.id === $route.params.tId" class="font-italic"> - (Currently viewing)</span>
                        <div class="test-history-icons" v-if="data.item.hasBeenPreviouslyReordered || data.item.previouslyBeenSetAsReturned">
                            <img src="@/assets/images/icons/set-return-icon.svg" :class="{invisible: !data.item.previouslyBeenSetAsReturned}" v-b-tooltip.hover title="Test has previously been set as Returned" />
                            <img v-if="data.item.replacedByTestId" src="@/assets/images/icons/reorder-old-icon.svg" v-b-tooltip.hover title="This test has been reordered"/>
                            <img v-else-if="data.item.replacesTestId" src="@/assets/images/icons/reorder-new-icon.svg" v-b-tooltip.hover title="This test replaces a previous test"/>
                            <img v-else-if="data.item.hasBeenPreviouslyReordered" src="@/assets/images/icons/reorder-old-icon.svg" v-b-tooltip.hover title="Test has been previously reordered"/>
                        </div>
                    </template>

                    <template #cell(status)="data">
                        <testStatuses :status="data.item.status" />
                    </template>

                    <template #cell(reason)="data">
                        <span v-if="data.item.reason == TestIssueReason.GD">Gestational Diabetes Mellitus</span>
                        <span v-if="data.item.reason == TestIssueReason.CF">Cystic Fibrosis</span>
                        <span v-if="data.item.reason == TestIssueReason.TYPE1">Type 1</span>
                    </template>

                    <template #cell(action)="data">
                        <button v-if="data.item.status != TestStatus.NEW && data.item.status != TestStatus.ISSUED" :disabled="data.item.id === $route.params.tId" @click="viewTest(data.item.id)" class="btn btn-primary sm-shadow text-nowrap" :class="{disabled : data.item.id === $route.params.tId}">View Test</button>
                        <button v-else :disabled="!canShowCancel(data.item.status)" @click="cancelTest(data.item)" class="btn btn-danger sm-shadow text-nowrap" :class="{disabled : !canShowCancel(data.item.status)}">Cancel</button>
                    </template>
                </b-table>

                <div class="mt-4 d-flex flex-column flex-md-row align-items-center justify-content-end">
                        <div class="form-group d-flex align-items-center mb-4 mb-md-0">
                            <label class="flex-shrink-0 mr-2">Items per page</label>
                            <select class="custom-select" v-model="pageSize" @change="handleChangePageSize()">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                            </select>
                        </div>

                        <div>
                            <b-pagination
                                v-model="currentPage"
                                :current-page="currentPage"
                                :per-page="results.pageSize"
                                :total-rows="results.totalCount"
                                prev-text="< Previous"
                                next-text="Next >"
                                @change="pageChange"
                                aria-controls="table"
                                hide-goto-end-buttons
                                prev-class="prev-page"
                                next-class="next-page"
                                :busy.sync="isBusy"
                                align="right"
                                class="mb-0"
                            ></b-pagination>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import TestStatuses from '@/components/TestStatusesComponent.vue'
import FormatterMixin from '@/mixins/FormatterMixin'
import TestIssueReason from '@/enums/TestIssueReason'
import TestStatus from '@/enums/TestStatus'
import CancelTestModal from '@/components/modals/CancelTestModalComponent.vue'
export default {
    name: 'PatientTestsComponent',

    props: {
        tests: Object,
        patientId: String,
        organisationId: String,
        showUpcomingTests: Boolean(null)
    },

    data () {
        return {
            loading: true,
            isBusy: false,
            currentPage: 1,
            offset: 0,
            pageSize: process.env.VUE_APP_PAGINATION_PAGE_SIZE,
            sortColumn: 'TEST_NUMBER',
            sortType: 'DESCENDING',
            sortDesc: true,
            sortBySync: 'testNumber',
            results: this.tests,
            TestIssueReason,
            TestStatus,
            CancelTestModal
        }
    },

    components: {
        TestStatuses
    },

    mixins: [
        FormatterMixin
    ],

    mounted () {
        this.$refs.table.refresh()
    },

    computed: {
        filteredTestsFields () {
            return [
                {
                    key: 'testNumber',
                    label: 'Test Number',
                    sortable: true
                },
                {
                    key: 'issuedDate',
                    label: 'Issue Date',
                    sortable: true,
                    formatter: 'formatDate'
                },
                {
                    key: 'returnDate',
                    label: 'Return date',
                    sortable: true,
                    formatter: 'formatDate'
                },
                {
                    key: 'dueDate',
                    label: 'Test date',
                    sortable: true,
                    formatter: 'formatDate'
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: true
                },
                {
                    key: 'practitionerName',
                    label: 'Assigned HCP',
                    sortable: true
                },
                {
                    key: 'reason',
                    label: 'Testing for',
                    sortable: true
                },
                {
                    key: 'action',
                    label: ''
                }
            ]
        }
    },

    methods: {
        canShowCancel (status) {
            return status !== TestStatus.COMPLETE && status !== TestStatus.CANCELLED && status !== TestStatus.REPORTED && status !== TestStatus.RETURNED && status !== TestStatus.ISSUED
        },

        cancelTest (test) {
            this.$modal.show(CancelTestModal, { testExists: true, organisationId: test.organisationId, testId: test.id }, { width: '400px' })
        },

        sortingChanged (e) {
            switch (e.sortBy) {
                case 'patientName':
                    this.sortByValue = 'PATIENT_NAME'
                break

                case 'testNumber':
                    this.sortByValue = 'TEST_NUMBER'
                break

                case 'patientIdentifier':
                    this.sortByValue = 'PATIENT_IDENTIFIER'
                break

                case 'deviceNumber':
                    this.sortByValue = 'DEVICE_NUMBER'
                break

                case 'patientDOB':
                    this.sortByValue = 'PATIENT_DOB'
                break

                case 'patientPostalCode':
                    this.sortByValue = 'POSTCODE'
                break

                case 'issuedDate':
                    this.sortByValue = 'ISSUED_DATE'
                break

                case 'returnDate':
                    this.sortByValue = 'RETURN_DATE'
                break

                case 'dueDate':
                    this.sortByValue = 'DUE_DATE'
                break

                case 'gdEstimatedDeliveryDate':
                    this.sortByValue = 'EDD'
                break

                case 'status':
                    this.sortByValue = 'STATUS'
                break

                case 'practitionerName':
                    this.sortByValue = 'PRACTITIONER_NAME'
                break

                case 'facilityName':
                    this.sortByValue = 'FACILITY_NAME'
                break

                case 'dateUpdated':
                    this.sortByValue = 'LAST_UPDATED_DATE'
                break
            }

            this.sortColumn = this.sortByValue
            this.sortDesc = e.sortDesc

            if (this.sortDesc) {
                this.sortType = 'DESCENDING'
            } else {
                this.sortType = 'ASCENDING'
            }

            this.currentPage = 1
            this.offset = 0
            this.getResults()
        },

        pageChange (value) {
            this.currentPage = value
            this.offset = (value - 1) * this.pageSize
            this.getResults()
        },

        handleChangePageSize () {
            this.currentPage = 1
            this.offset = 0

            this.getResults()
        },

        async getResults () {
            this.isBusy = true

            var testStatus = []
            if (this.showUpcomingTests === true) {
                testStatus = [TestStatus.NEW, TestStatus.ISSUED]
            }
            if (this.showUpcomingTests === false) {
                testStatus = [TestStatus.RETURNED, TestStatus.COMPLETE, TestStatus.CANCELLED, TestStatus.REPORTED, TestStatus.FAILED, TestStatus.ERROR]
            }

            const searchModel = {
                organisationId: this.organisationId,
                patientId: this.patientId,
                pageSize: this.pageSize,
                offset: this.offset,
                sortColumn: this.sortColumn,
                sortType: this.sortType,
                testStatus: testStatus
            }

            try {
                const response = await axios.post(`${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/tests/search`, searchModel)
                this.results = response.data
            } catch {
                this.setNotification({
                    type: 'error',
                    title: 'Error',
                    message: 'Something went wrong. Please try again'
                })
            } finally {
                this.isBusy = false
            }
        },

        async viewTest (testId) {
            if (testId === this.$route.params.tId) return
            await this.$router.push({ name: 'ViewTest', params: { oId: this.organisationId, tId: testId } })
        },

        rowClass (item, type) {
            if (type === 'row') {
                if (item.id === this.$route.params.tId) return 'disabled'
            }
        }
    }
}
</script>
