const SpecialityType = Object.freeze({
    OBSTETRICIAN: 0,
    MIDWIFE: 1,
    HCA: 2,
    NURSE: 3,
    GP: 4,
    SCC: 5,
    OTHER: 6
})

export default SpecialityType
