<template>
    <div class="my-2">
        <h1>404 - page not found</h1>
    </div>
</template>

<script>
export default {
    name: 'Error404'
}
</script>
