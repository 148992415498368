const ScanningCodeType = Object.freeze({
    BPM: 'BPM', // begin packing mode
    EPM: 'EPM', // end packing mode
    BQM: 'BQM', // begin qa mode
    EQM: 'EQM', // end qa mode
    QAS: 'QAS', // single package
    ASD: 'ASD', // assign single device
    BCP: 'BCP', // begin check package mode
    ECP: 'ECP' // end check package mode
})

export default ScanningCodeType
