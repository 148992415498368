<template>
    <div>
        <Breadcrumbs />

        <div class="row mt-3">
            <div class="col">
                <div class="card text-sm">
                    <div class="card-body">
                        <h5 class="mb-0">Organisation details</h5>

                        <div class="row mt-4">
                            <div class="col-xl-5">
                                <div v-if="userIsDomainAssigner" class="form-group row">
                                    <label class="col-sm-5 col-form-label">Domain</label>
                                    <div class="col-sm-7"><input type="text" readonly class="form-control" v-model="currentDomainName"></div>
                                </div>

                                <div class="form-group row">
                                    <label for="name" class="col-sm-5 col-form-label">Name <span class="text-danger">*</span></label>
                                    <div class="col-sm-7">
                                        <input type="text" class="form-control" v-model="form.organisationName">
                                        <small v-if="errors && errors.organisationName" class="text-danger">
                                            {{ errors.organisationName[0] }}
                                        </small>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="country-code" class="col-sm-5 col-form-label">Select your region <span class="text-danger">*</span></label>
                                    <div class="col-sm-7">
                                        <select class="custom-select" name="country-code" v-model="form.organisationCountryCode" @change="chooseCountry()">
                                            <option value="">Please select an option</option>
                                            <option value="GB">United Kingdom</option>
                                            <option value="US">United States</option>
                                        </select>
                                        <small v-if="errors && errors.organisationCountryCode" class="text-danger">
                                            {{ errors.organisationCountryCode[0] }}
                                        </small>
                                    </div>
                                </div>

                                <div v-if="form.organisationCountryCode == 'GB'" class="form-group row">
                                    <label for="autocomplete" class="col-sm-5 col-form-label">Find Address</label>
                                    <div class="col-sm-7">
                                        <input type="text" class="form-control" @input="findAddress()" v-model="addressQueryString" placeholder="Search for address" autocomplete="new-password" />
                                        <ul v-if="autocompleteSuggestions.length" class="list-group autocomplete-suggestions">
                                            <li v-for="(suggestion, index) in autocompleteSuggestions" :key="index" class="list-group-item" @click="chooseSuggestion(suggestion.id)">{{ suggestion.address }}</li>
                                        </ul>
                                        <button class="btn show-manual-address" @click="enterAddressManually()">Enter address manually</button>
                                    </div>
                                </div>

                                <div v-if="expandManualUkAddress">
                                    <div class="form-group row">
                                        <label for="address1" class="col-sm-5 col-form-label">Address 1 <span class="text-danger">*</span></label>
                                        <div class="col-sm-7">
                                            <input type="text" class="form-control" id="address1" v-model="form.organisationAddress1">
                                            <small v-if="errors && errors.organisationAddress1" class="text-danger">
                                                {{ errors.organisationAddress1[0] }}
                                            </small>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="address2" class="col-sm-5 col-form-label">Address 2 <span class="text-danger">*</span></label>
                                        <div class="col-sm-7">
                                            <input type="text" class="form-control" id="address2" v-model="form.organisationAddress2">
                                            <small v-if="errors && errors.organisationAddress2" class="text-danger">
                                                {{ errors.organisationAddress2[0] }}
                                            </small>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="address3" class="col-sm-5 col-form-label">Address 3</label>
                                        <div class="col-sm-7">
                                            <input type="text" class="form-control" id="address3" v-model="form.organisationAddress3">
                                            <small v-if="errors && errors.organisationAddress3" class="text-danger">
                                                {{ errors.organisationAddress3[0] }}
                                            </small>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="postalCode" class="col-sm-5 col-form-label">Postcode <span class="text-danger">*</span></label>
                                        <div class="col-sm-7">
                                            <input type="text" class="form-control" id="postalCode" v-model="form.organisationPostalCode">
                                            <small v-if="errors && errors.organisationPostalCode" class="text-danger">
                                                {{ errors.organisationPostalCode[0] }}
                                            </small>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="country" class="col-sm-5 col-form-label">Country <span class="text-danger">*</span></label>
                                        <div class="col-sm-7">
                                            <input type="text" class="form-control" id="country" v-model="form.organisationCountry">
                                            <small v-if="errors && errors.organisationCountry" class="text-danger">
                                                {{ errors.organisationCountry[0] }}
                                            </small>
                                        </div>
                                    </div>

                                </div>

                                <div v-if="expandManualUsAddress">
                                    <div class="form-group row">
                                        <label for="address1" class="col-sm-5 col-form-label">Address 1 <span class="text-danger">*</span></label>
                                        <div class="col-sm-7">
                                            <input type="text" class="form-control" id="address1" v-model="form.organisationAddress1">
                                            <small v-if="errors && errors.organisationAddress1" class="text-danger">
                                                {{ errors.organisationAddress1[0] }}
                                            </small>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="address2" class="col-sm-5 col-form-label">Address 2 <span class="text-danger">*</span></label>
                                        <div class="col-sm-7">
                                            <input type="text" class="form-control" id="address2" v-model="form.organisationAddress2">
                                            <small v-if="errors && errors.organisationAddress2" class="text-danger">
                                                {{ errors.organisationAddress2[0] }}
                                            </small>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="address3" class="col-sm-5 col-form-label">City</label>
                                        <div class="col-sm-7">
                                            <input type="text" class="form-control" id="address3" v-model="form.organisationAddress3">
                                            <small v-if="errors && errors.organisationAddress3" class="text-danger">
                                                {{ errors.organisationAddress3[0] }}
                                            </small>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="address4" class="col-sm-5 col-form-label">State</label>
                                        <div class="col-sm-7">
                                            <input type="text" class="form-control" id="address3" v-model="form.organisationAddress4">
                                            <small v-if="errors && errors.organisationAddress4" class="text-danger">
                                                {{ errors.organisationAddress4[0] }}
                                            </small>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="postalCode" class="col-sm-5 col-form-label">Zip Code <span class="text-danger">*</span></label>
                                        <div class="col-sm-7">
                                            <input type="text" class="form-control" id="postalCode" v-model="form.organisationPostalCode">
                                            <small v-if="errors && errors.organisationPostalCode" class="text-danger">
                                                {{ errors.organisationPostalCode[0] }}
                                            </small>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="fulfilment-provider" class="col-sm-5 col-form-label">Fulfilment provider <span class="text-danger">*</span></label>
                                    <div class="col-sm-7">
                                        <select type="text" class="custom-select" v-model="form.organisationFulfimentProvider">
                                            <option value="0">Digostics</option>
                                        </select>
                                        <small v-if="errors && errors.organisationFulfimentProvider" class="text-danger">
                                            {{ errors.organisationFulfimentProvider[0] }}
                                        </small>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="patient-identifier-label" class="col-sm-5 col-form-label">Patient identifier label <span class="text-danger">*</span></label>
                                    <div class="col-sm-7">
                                        <input type="text" class="form-control" v-model="form.organisationPatientIdentifierLabel">
                                        <small v-if="errors && errors.organisationPatientIdentifierLabel" class="text-danger">
                                            {{ errors.organisationPatientIdentifierLabel[0] }}
                                        </small>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="patient-identifier-validation-regex" class="col-sm-5 col-form-label">Patient identifier validation regex <span class="text-danger">*</span></label>
                                    <div class="col-sm-7">
                                        <input type="text" class="form-control" v-model="form.organisationPatientIdentifierValidationRegex">
                                        <small v-if="errors && errors.organisationPatientIdentifierValidationRegex" class="text-danger">
                                            {{ errors.organisationPatientIdentifierValidationRegex[0] }}
                                        </small>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="patient-identifier-error-message" class="col-sm-5 col-form-label">Patient identifier error message <span class="text-danger">*</span></label>
                                    <div class="col-sm-7">
                                        <input type="text" class="form-control" v-model="form.organisationPatientIdentifierErrorMessage">
                                        <small v-if="errors && errors.organisationPatientIdentifierErrorMessage" class="text-danger">
                                            {{ errors.organisationPatientIdentifierErrorMessage[0] }}
                                        </small>
                                    </div>
                                </div>

                                <div class="form-group row align-items-center">
                                    <label for="status" class="col-sm-5 col-form-label">Status <span class="text-danger">*</span></label>
                                    <div class="col-sm-7">
                                        <b-form-checkbox v-model="form.organisationStatusChecked" name="check-button" switch class="custom-switch-md">
                                            {{ form.organisationStatusChecked ? 'Active' : 'Inactive' }}
                                        </b-form-checkbox>
                                    </div>
                                </div>

                                <div class="form-group row align-items-center">
                                    <label for="is-trial" class="col-sm-5 col-form-label">Is Trial? <span class="text-danger">*</span></label>
                                    <div class="col-sm-7">
                                        <b-form-checkbox v-model="form.isTrial" name="check-button" switch class="custom-switch-md">
                                            {{ form.isTrial ? 'Yes' : 'No' }}
                                        </b-form-checkbox>
                                    </div>
                                </div>

                                <div v-if="userIsOwner" class="form-group row align-items-center">
                                    <label for="datastore" class="col-sm-5 col-form-label">Data Store <span class="text-danger">*</span></label>
                                    <div class="col-sm-7">
                                        <select class="custom-select" v-model="form.organisationDataStore">
                                            <option value="">Please select an option</option>
                                            <option v-for="(store, index) in dataStores.results" :key="index" :value="store.id">{{ store.name }}</option>
                                        </select>
                                        <small v-if="errors && errors.organisationDataStore" class="text-danger">
                                            {{ errors.organisationDataStore[0] }}
                                        </small>
                                    </div>
                                </div>

                                <div v-else class="form-group row align-items-center">
                                    <label for="datastore" class="col-sm-5 col-form-label">Data Store <span class="text-danger">*</span></label>
                                    <div class="col-sm-7">
                                        <em>Only an owner can select a data store</em>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h5 class="mb-0 mt-5">Primary contact</h5>

                        <div class="row mt-4">
                            <div class="col-xl-5">
                                <div class="form-group row">
                                    <label for="title" class="col-sm-5 col-form-label">Title <span class="text-danger">*</span></label>
                                    <div class="col-sm-7">
                                        <select type="text" class="custom-select" v-model="form.title">
                                            <option :value="Salutation.MR">Mr</option>
                                            <option :value="Salutation.MRS">Mrs</option>
                                            <option :value="Salutation.MISS">Miss</option>
                                            <option :value="Salutation.MS">Ms</option>
                                            <option :value="Salutation.DR">Dr</option>
                                            <option :value="Salutation.SIR">Sir</option>
                                            <option :value="Salutation.PROFESSOR">Professor</option>
                                        </select>
                                        <small v-if="errors && errors.title" class="text-danger">
                                            {{ errors.title[0] }}
                                        </small>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="first-name" class="col-sm-5 col-form-label">First name <span class="text-danger">*</span></label>
                                    <div class="col-sm-7">
                                        <input type="text" class="form-control" v-model="form.firstName">
                                        <small v-if="errors && errors.firstName" class="text-danger">
                                            {{ errors.firstName[0] }}
                                        </small>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="middle-names" class="col-sm-5 col-form-label">Middle names</label>
                                    <div class="col-sm-7">
                                        <input type="text" class="form-control" v-model="form.middleNames">
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="surname" class="col-sm-5 col-form-label">Surname <span class="text-danger">*</span></label>
                                    <div class="col-sm-7">
                                        <input type="text" class="form-control" v-model="form.surname">
                                        <small v-if="errors && errors.surname" class="text-danger">
                                            {{ errors.surname[0] }}
                                        </small>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="phone-number" class="col-sm-5 col-form-label">Phone number <span class="text-danger">*</span></label>
                                    <div class="col-sm-7">
                                        <input type="text" class="form-control" v-model="form.phone">
                                        <small v-if="errors && errors.phone" class="text-danger">
                                            {{ errors.phone[0] }}
                                        </small>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="email" class="col-sm-5 col-form-label">Email address <span class="text-danger">*</span></label>
                                    <div class="col-sm-7">
                                        <input type="text" class="form-control" v-model="form.email">
                                        <small v-if="errors && errors.email" class="text-danger">
                                            {{ errors.email[0] }}
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="fixed-bottom bg-white text-right p-3">
            <button :disabled="loading" @click="cancel()" class="btn btn-cancel btn-lg lg-shadow" :class="{disabled : loading}">Cancel</button>
            <button :disabled="loading" @click="create()" class="btn btn-success btn-lg lg-shadow pl-55 pr-55 ml-2" :class="{disabled : loading}">Create <span v-if="loading" class="btn-loading"></span></button>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import Breadcrumbs from '@/partials/site-structure/BreadcrumbsComponent'
import Salutation from '@/enums/Salutation'
import _ from 'lodash'
export default {
    name: 'CreateOrganisation',

    components: {
        Breadcrumbs
    },

    data () {
        return {
            loading: false,
            dataStores: [],
            expandManualUkAddress: false,
            expandManualUsAddress: false,
            addressQueryString: null,
            autocompleteSuggestions: [],
            form: {
                organisationAddress1: null,
                organisationAddress2: null,
                organisationAddress3: null,
                organisationPostalCode: null,
                organisationCountry: null,
                organisationLatitude: null,
                organisationLongitude: null,
                organisationCountryCode: null,
                organisationDefaultPhoneCountryCode: null,
                organisationFulfimentProvider: 0,
                organisationDataStore: '',
                organisationStatusChecked: true,
                isTrial: false,
                domainId: null
            },
            errors: {},
            Salutation
        }
    },

    async mounted () {
        if (this.userIsOwner) {
            this.loading = true
            try {
                const response = await axios.get(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/datastores/100/0`)

                this.dataStores = response.data
            } catch {
                this.setNotification({
                    type: 'error',
                    title: 'Error',
                    message: 'Something went while fetching the datastores. Please try again.'
                })
            } finally {
                this.loading = false
            }
        }

        // Default the domain value
        this.form.domainId = this.userCurrentDomain
    },

    computed: {
        ...mapGetters({
            userIsOwner: 'UserStore/GetIsUserOwner',
            allDomains: 'DomainsStore/GetDomains',
            userIsDomainAssigner: 'UserStore/GetIsUserDomainAssigner',
            userCurrentDomain: 'UserStore/GetUserCurrentDomain'
        }),
        currentDomainName () {
            const domain = _.find(this.allDomains, (d) => {
                return d.id === this.userCurrentDomain
            })
            return domain.name
        }
    },

    methods: {
        ...mapActions({
            setNotification: 'NotificationStore/SetNotification'
        }),

        async chooseCountry () {
            this.clearAddress()

            if (this.form.organisationCountryCode === 'GB') {
                this.expandManualUsAddress = false
                this.form.organisationDefaultPhoneCountryCode = '+44'
                return
            }

            if (this.form.organisationCountryCode === 'US') {
                this.expandManualUkAddress = false
                this.expandManualUsAddress = true
                this.form.organisationDefaultPhoneCountryCode = '+1'
                this.form.organisationCountry = 'US'
                return
            }

            this.expandManualUkAddress = false
            this.expandManualUsAddress = false
            this.form.organisationDefaultPhoneCountryCode = null
        },

        async findAddress () {
            const response = await axios.get(`https://api.getAddress.io/autocomplete/${this.addressQueryString}?api-key=${process.env.VUE_APP_GET_ADDRESS_API_KEY}`)

            this.autocompleteSuggestions = response.data.suggestions
        },

        async chooseSuggestion (id) {
            this.clearAddress()

            const response = await axios.get(`https://api.getAddress.io/get/${id}?api-key=${process.env.VUE_APP_GET_ADDRESS_API_KEY}`)

            if (response.data) {
                this.autocompleteSuggestions = []
                this.addressQueryString = null
                this.expandManualUkAddress = true
                this.form.organisationAddress1 = response.data.line_1
                this.form.organisationAddress2 = response.data.town_or_city
                this.form.organisationAddress3 = response.data.county
                this.form.organisationPostalCode = response.data.postcode
                this.form.organisationCountry = response.data.country
                this.form.organisationLatitude = response.data.latitude
                this.form.organisationLongitude = response.data.longitude
            }
        },

        clearAddress () {
            this.fullAddress = null
            this.form.organisationAddress1 = null
            this.form.organisationAddress2 = null
            this.form.organisationAddress3 = null
            this.form.organisationPostalCode = null
            this.form.organisationCountry = null
            this.form.organisationLatitude = null
            this.form.organisationLongitude = null
        },

        enterAddressManually () {
            this.expandManualUkAddress = true
        },

        async create () {
            if (this.form.organisationCountryCode) {
                if (this.form.organisationCountryCode === 'GB') this.expandManualUkAddress = true
                if (this.form.organisationCountryCode === 'US') this.expandManualUsAddress = true
            }

            this.checkForm()
            if (!Object.keys(this.errors).length) {
                this.loading = true

                const organisationModel = {
                    domainId: this.form.domainId,
                    name: this.form.organisationName,
                    fulfilmentProvider: this.form.organisationFulfimentProvider,
                    isActive: this.form.organisationStatusChecked,
                    address1: this.form.organisationAddress1,
                    address2: this.form.organisationAddress2,
                    address3: this.form.organisationAddress3 ?? null,
                    address4: this.form.organisationAddress4 ?? null,
                    postalCode: this.form.organisationPostalCode,
                    country: this.form.organisationCountry,
                    countryCode: this.form.organisationCountryCode,
                    latitude: this.form.latitude ?? 0,
                    longitude: this.form.longitude ?? 0,
                    title: this.form.title,
                    firstName: this.form.firstName,
                    middleNames: this.form.middleNames,
                    surname: this.form.surname,
                    phone: this.form.phone,
                    email: this.form.email,
                    patientIdentifierLabel: this.form.organisationPatientIdentifierLabel,
                    patientIdentifierValidationRegex: this.form.organisationPatientIdentifierValidationRegex,
                    patientIdentifierErrorMessage: this.form.organisationPatientIdentifierErrorMessage,
                    isTrial: this.form.isTrial,
                    defaultPhoneCountryCode: this.form.organisationDefaultPhoneCountryCode
                }

                try {
                    const response = await axios.post(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/organisations`, organisationModel)

                    if (this.userIsOwner && this.form.organisationDataStore) {
                        await axios.patch(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/datastores/${response.data.id}/${this.form.organisationDataStore}`)
                    }

                    this.setNotification({
                        type: 'success',
                        title: 'Success',
                        message: 'The organisation was added.'
                    })

                    return this.$router.push({ name: 'Organisations' })
                } catch (err) {
                    this.setNotification({
                        type: 'error',
                        title: 'Error',
                        message: err.data.detail ? err.data.detail : 'Something went wrong. The organisation was not added.'
                    })
                } finally {
                    this.loading = false
                }
            }
        },

        checkForm () {
            this.errors = {}

            if (!this.form.domainId) {
                this.errors.organisationDomain = ['The domain field is required']
            }

            if (!this.form.organisationName) {
                this.errors.organisationName = ['The organisation name field is required']
            }

            if (this.form.organisationFulfimentProvider == null || parseInt(this.form.organisationFulfimentProvider) < 0) {
                this.errors.organisationFulfimentProvider = ['The organisation fulfilment provider field is required']
            }

            if (this.form.title == null || parseInt(this.form.title) < 0) {
                this.errors.title = ['The contact title field is required']
            }

            if (!this.form.firstName) {
                this.errors.firstName = ['The contact first name field is required']
            }

            if (!this.form.surname) {
                this.errors.surname = ['The contact surname field is required']
            }

            if (!this.form.phone) {
                this.errors.phone = ['The contact phone number field is required']
            }

            if (this.form.phone != null && !this.form.phone.match(/[0-9]{9,20}/)) {
                this.errors.phone = ['The phone number must be a minimum of 9 digits']
            }

            if (!this.form.email) {
                this.errors.email = ['The contact email field is required']
            }

            if (this.form.email != null && !this.form.email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                this.errors.email = ['This not a valid email address']
            }

            if (!this.form.organisationAddress1) {
                this.errors.organisationAddress1 = ['The organisation address 1 field is required']
            }

            if (!this.form.organisationAddress2) {
                this.errors.organisationAddress2 = ['The organisation address 2 field is required']
            }

            if (!this.form.organisationPostalCode) {
                this.errors.organisationPostalCode = ['The organisation postal / zip code field is required']
            }

            if (!this.form.organisationCountry) {
                this.errors.organisationCountry = ['The organisation country field is required']
            }

            if (!this.form.organisationCountryCode) {
                this.errors.organisationCountryCode = ['The organisation country code field is required']
            }

            if (!this.form.organisationPatientIdentifierLabel) {
                this.errors.organisationPatientIdentifierLabel = ['The organisation patient identifier label field is required']
            }

            if (!this.form.organisationPatientIdentifierValidationRegex) {
                this.errors.organisationPatientIdentifierValidationRegex = ['The organisation validation regex field is required']
            }

            if (!this.form.organisationPatientIdentifierErrorMessage) {
                this.errors.organisationPatientIdentifierErrorMessage = ['The organisation patient identifier error message field is required']
            }

            if (!this.form.organisationDefaultPhoneCountryCode) {
                this.errors.organisationDefaultPhoneCountryCode = ['The organisation default phone country code field is required']
            }

            if (this.userIsOwner && !this.form.organisationDataStore) {
                this.errors.organisationDataStore = ['The organisation data store field is required']
            }
        },

        cancel () {
            return this.$router.push({ name: 'Organisations' })
        }
    }
}
</script>
