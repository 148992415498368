<template>
    <div>
        <Loader v-if="loading" />

        <div v-if="!loading && Object.keys(facility).length">
            <div class="row align-items-center">
                <div class="col-md-6">
                    <Breadcrumbs
                        :dynamicTitle="facility.name"
                    />
                </div>

                <div class="col-md-6 text-right mt-2 mt-md-0">
                    <div class="d-flex flex-column flex-md-row justify-content-end">
                        <button @click="viewResults()" class="btn btn-yellow btn-lg lg-shadow pl-55 pr-55">View results</button>
                    </div>
                </div>
            </div>

            <div class="card mt-3">
                <div class="card-body">
                    <div class="d-flex flex-column flex-md-row justify-content-between">
                        <h5 class="mb-0">Facility details</h5>
                        <router-link :to="{name: 'EditFacility', params: {oId: $route.params.oId, fId: $route.params.fId}}" class="btn btn-primary sm-shadow mt-4 mt-md-0 px-4">Edit</router-link>
                    </div>

                    <div class="row mt-4">
                        <div class="col-lg-6">
                            <div class="row">
                                <div class="col-sm-4 font-weight-medium">Address</div>
                                <div class="col-sm-8">
                                    <span v-if="facility.address1">{{ `${facility.address1}, ` }}</span>
                                    <span v-if="facility.address2">{{ `${facility.address2}, ` }}</span>
                                    <span v-if="facility.address3">{{ `${facility.address3}, ` }}</span>
                                    <span v-if="facility.address4">{{ `${facility.address4}, ` }}</span>
                                    <span v-if="facility.postalCode" class="text-uppercase">{{ `${facility.postalCode}, ` }}</span>
                                    <span v-if="facility.country">{{ `${facility.country}` }}</span>
                                </div>
                            </div>

                            <hr>

                            <div class="row">
                                <div class="col-sm-4 font-weight-medium">Fulfilment provider</div>
                                <div class="col-sm-8">
                                    <span v-if="facility.fulfilmentProvider == 0">Digostics</span>
                                </div>
                            </div>

                            <hr>

                            <div v-if="facility.dataStoreName">
                                <div class="row">
                                    <div class="col-sm-4 font-weight-medium">Data store</div>
                                    <div class="col-sm-8">{{ facility.dataStoreName }}</div>
                                </div>

                                <hr>
                            </div>

                            <div class="row">
                                <div class="col-sm-4 font-weight-medium">Status</div>
                                <div class="col-sm-8" :class="{'text-success' : facility.isActive, 'text-danger' : !facility.isActive}">{{ facility.isActive ? "ACTIVE" : "INACTIVE" }}</div>
                            </div>
                        </div>
                    </div>

                    <h5 class="mt-5">Primary contact</h5>

                    <div class="row mt-4">
                        <div class="col-lg-6">
                            <div class="row">
                                <div class="col-sm-4 font-weight-medium">Name</div>
                                <div class="col-sm-8">{{ facility.firstName + ' ' + facility.surname }}</div>
                            </div>

                            <hr>

                            <div class="row">
                                <div class="col-sm-4 font-weight-medium">Phone number</div>
                                <div class="col-sm-8">{{ facility.phone }}</div>
                            </div>

                            <hr>

                             <div class="row">
                                <div class="col-sm-4 font-weight-medium">Email</div>
                                <div class="col-sm-8">{{ facility.email }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="results.results.length" class="row mt-5">
                <div class="col">
                    <h5>Health Care Professionals <span class="font-italic">({{ results.totalCount }})</span></h5>

                    <b-table
                        ref="table"
                        id="table"
                        :sort-by.sync="sortBySync"
                        :sort-desc.sync="sortDesc"
                        @sort-changed="sortingChanged"
                        :no-local-sorting="true"
                        :busy.sync="isBusy"
                        class="custom-table padding-lg bg-white mt-4"
                        responsive
                        no-sort-reset
                        :items="results.results"
                        :fields="filteredFields"
                    >
                        <template #cell(name)="data">
                            <router-link class="font-weight-bold underlined text-capitalize" :to="{name: 'ViewHcp', params: {oId: $route.params.oId, pId: data.item.id }}">{{ `${data.item.firstName} ${data.item.surname}` }}</router-link>
                        </template>

                        <template #cell(isNotificationManager)="data">
                            <span :class="{'text-success' : data.item.isNotificationManager, 'text-danger' : !data.item.isNotificationManager}">{{ data.item.isNotificationManager ? 'YES' : 'NO' }}</span>
                        </template>

                        <template #cell(testsIssuedCount)="data">
                            {{ data.item.testsIssuedCount ? data.item.testsIssuedCount : 0 }}
                        </template>

                        <template #cell(testsCompleteCount)="data">
                            {{ data.item.testsCompleteCount ? data.item.testsCompleteCount : 0 }}
                        </template>

                        <template #cell(isActive)="data">
                            <span :class="{'text-success' : data.item.isActive, 'text-danger' : !data.item.isActive}">{{ data.item.isActive ? 'ACTIVE' : 'INACTIVE' }}</span>
                        </template>

                        <template #cell(setTraining)="data">
                            <button :disabled="loading" @click="setTraining(data.item.id)" class="btn btn-success sm-shadow mt-4 mt-md-0 px-4" :class="{disabled : loading}">Set Training</button>
                        </template>

                    </b-table>

                    <div class="mt-4">
                        <b-pagination
                            v-if="results.results.length"
                            v-model="currentPage"
                            :current-page="currentPage"
                            :per-page="results.pageSize"
                            :total-rows="results.totalCount"
                            prev-text="< Previous"
                            next-text="Next >"
                            @change="pageChange"
                            aria-controls="tests-table"
                            hide-goto-end-buttons
                            prev-class="prev-page"
                            next-class="next-page"
                            align="right"
                        ></b-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import Loader from '@/partials/Loader'
import Breadcrumbs from '@/partials/site-structure/BreadcrumbsComponent'
import FormatterMixin from '@/mixins/FormatterMixin'
import TrainingVersion from '@/enums/TrainingVersion'
export default {
    name: 'ViewFacility',

    components: {
        Loader,
        Breadcrumbs
    },

    mixins: [
        FormatterMixin
    ],

    data () {
        return {
            loading: true,
            facility: {},
            results: {},
            isBusy: false,
            currentPage: 1,
            pageSize: process.env.VUE_APP_PAGINATION_PAGE_SIZE,
            offset: 0,
            sortColumn: 'NAME',
            sortType: 'ASCENDING',
            sortDesc: false,
            sortBySync: 'name'
        }
    },

    computed: {
        ...mapGetters({
            userIsOwner: 'UserStore/GetIsUserOwner'
        }),

        filteredFields () {
            return [
                {
                    key: 'name',
                    label: 'Name',
                    sortable: true
                },
                {
                    key: 'isNotificationManager',
                    label: 'Notification',
                    variant: 'text-wrap w-15'
                },
                {
                    key: 'testsIssuedCount',
                    label: 'Tests Issued',
                    variant: 'text-wrap w-15',
                    sortable: true
                },
                {
                    key: 'testsCompleteCount',
                    label: 'Tests Complete',
                    variant: 'text-wrap w-15',
                    sortable: true
                },
                {
                    key: 'isActive',
                    label: 'Status',
                    sortable: true
                },
                {
                    key: 'trainingVersionCompleted',
                    label: 'Training Version',
                    variant: 'text-wrap w-10',
                    sortable: true
                },
                {
                    key: 'trainingCompletedDateTime',
                    label: 'Training Completed Date',
                    variant: 'text-wrap w-15',
                    formatter: 'formatDateTime',
                    sortable: true
                },
                {
                    key: 'setTraining',
                    variant: 'text-wrap w-15',
                    label: ''
                }
            ]
        }
    },

    async mounted () {
        try {
            await this.getFacility()
            await this.getHcps()
        } catch {
            this.setNotification({
                type: 'error',
                title: 'Error',
                message: 'Something went wrong while fetching the data. Please try again.'
            })
        } finally {
            this.loading = false
        }
    },

    methods: {
        ...mapActions({
            clearSearchStore: 'SearchStore/ClearSearchStore',
            setOrganisationIdInSearchStore: 'SearchStore/SetChosenOrganisationId',
            setFacilityIdInSearchStore: 'SearchStore/SetChosenFacilityId',
            setNotification: 'NotificationStore/SetNotification'
        }),

        async viewResults () {
            await this.clearSearchStore()

            await this.setOrganisationIdInSearchStore(this.$route.params.oId)
            await this.setFacilityIdInSearchStore(this.$route.params.fId)

            return this.$router.push({ name: 'Results' })
        },

        async getFacility () {
            try {
                const response = await axios.get(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/facilities/${this.$route.params.oId}/${this.$route.params.fId}`)

                this.facility = response.data
            } catch (err) {
                throw new Error(err)
            }
        },

        async getHcps () {
            this.isBusy = true

            try {
                const response = await axios.get(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/practitioners/facility/${this.$route.params.oId}/${this.$route.params.fId}/${this.pageSize}/${this.offset}/${this.sortColumn}/${this.sortType}`)

                this.results = response.data
            } catch (err) {
                throw new Error(err)
            } finally {
                this.isBusy = false
            }
        },

        pageChange (value) {
            this.currentPage = value
            this.offset = (value - 1) * this.pageSize
            this.getHcps()
        },

        sortingChanged (e) {
            switch (e.sortBy) {
                case 'name':
                    this.sortColumn = 'NAME'
                break

                case 'testsIssuedCount':
                    this.sortColumn = 'TESTS_ISSUED'
                break

                case 'testsCompleteCount':
                    this.sortColumn = 'TESTS_COMPLETE'
                break

                case 'isActive':
                    this.sortColumn = 'STATUS'
                break

                case 'trainingVersionCompleted':
                    this.sortColumn = 'TRAININGVERSIONCOMPLETED'
                break

                case 'trainingCompletedDateTime':
                    this.sortColumn = 'TRAININGCOMPLETEDDATETIME'
                break
            }

            this.sortDesc = e.sortDesc
            this.sortBySync = e.sortBy

            if (this.sortDesc) {
                this.sortType = 'DESCENDING'
            } else {
                this.sortType = 'ASCENDING'
            }

            this.getHcps()
        },

        async setTraining (id) {
            this.isBusy = true
            try {
                await axios.patch(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/practitioners/complete-training/${this.$route.params.oId}/${id}`, {
                    trainingVersion: TrainingVersion.CURRENT,
                    trainingCompletedDateTime: new Date().toISOString() // UTC
                })

                this.getHcps()
            } catch (err) {
                throw new Error(err)
            } finally {
                this.isBusy = false
            }
        }
    }
}
</script>
