import axios from 'axios'
export default {
    namespaced: true,

    state: {
        facilities: []
    },

    getters: {
        GetAllFacilities (state) {
            return state.facilities
        }
    },

    mutations: {
        PUSH_FACILITIES (state, data) {
            state.facilities = data
        }
    },

    actions: {
        async GetFacilitiesByOrganisation ({ commit }, id) {
            try {
                const response = await axios.get(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/facilities/${id}`)

                commit('PUSH_FACILITIES', response.data)
            } catch (err) {
                throw new Error(err)
            }
        }
    }
}
