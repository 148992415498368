<template>
    <div>
        <div class="row align-items-center">
            <div class="col-md-6">
                <h2 class="mb-0">Users</h2>
            </div>

            <div class="col-md-6 text-right">
                <button @click="$router.push({name: 'CreateUser'})" class="btn btn-success btn-block-mobile btn-lg lg-shadow pl-55 pr-55 mt-4 mt-md-0">Add a user</button>
            </div>
        </div>

        <Loader v-if="loading" />

        <div v-else class="mt-45">
            <div class="row justify-content-between align-items-center">
                <div class="col">
                    <h5 class="mb-0">All <span class="font-italic">({{ results.totalCount }})</span></h5>
                </div>

                <div class="col-md-4 text-right mt-2 mt-md-0">
                    <div class="d-flex flex-column flex-md-row">
                        <input class="form-control searchbar-input" type="text" placeholder="Search for a user" v-model="query" />
                        <button onclick="this.blur();" :disabled="isBusy" @click="search()" class="btn btn-success btn-form sm-shadow ml-0 ml-md-2 my-2 my-md-0" :class="{disabled : isBusy}">Search</button>
                        <button onclick="this.blur();" @click="resetSearch()" class="btn btn-yellow btn-form sm-shadow ml-0 ml-md-2" :class="{disabled : isBusy}">Reset</button>
                    </div>
                </div>
            </div>

            <b-table
                v-if="results.results.length"
                ref="table"
                id="table"
                :sort-by.sync="sortBySync"
                :sort-desc.sync="sortDesc"
                @sort-changed="sortingChanged"
                :no-local-sorting="true"
                :busy.sync="isBusy"
                class="custom-table bg-white mt-4"
                :class="{'padding-lg' : !userIsOwner}"
                responsive
                no-sort-reset
                :items="results.results"
                :fields="filteredFields"
            >
                <template #cell(name)="data">
                    {{ data.item.firstName + ' ' + data.item.lastName }}
                </template>

                <template #cell(isActive)="data">
                    <span :class="{'text-success' : data.item.isActive}">{{ data.item.isActive ? 'ACTIVE' : 'INACTIVE' }}</span>
                </template>

                <template #cell(role)="data">
                    <span v-if="data.item.role == RoleType.ADMIN">Admin</span>
                    <span v-if="data.item.role == RoleType.OWNER">Owner</span>
                    <span v-if="data.item.role == RoleType.PRACTITIONER">Practitioner</span>
                    <span v-if="data.item.role == RoleType.FULFILMENT">Fulfilment</span>
                </template>

                <template #cell(action)="data">
                    <router-link :to="{name: 'EditUser', params: {id : data.item.id}}" class="btn btn-primary px-4 sm-shadow text-nowrap">Edit</router-link>
                </template>
            </b-table>

            <div class="mt-4">
                <b-pagination
                    v-if="results.results.length"
                    v-model="currentPage"
                    :current-page="currentPage"
                    :per-page="results.pageSize"
                    :total-rows="results.totalCount"
                    prev-text="< Previous"
                    next-text="Next >"
                    @change="pageChange"
                    aria-controls="tests-table"
                    hide-goto-end-buttons
                    prev-class="prev-page"
                    next-class="next-page"
                    align="right"
                ></b-pagination>
            </div>
        </div>

        <div v-if="!loading && !results.results.length">
            <div class="row mt-45">
                <div class="col-md-6">
                    <div class="alert alert-info">
                        There are no users to show
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import Loader from '@/partials/Loader.vue'
import RoleType from '@/enums/RoleType'
export default {
    name: 'Users',

    components: {
        Loader
    },

    data () {
        return {
            loading: true,
            query: '',
            results: {},
            isBusy: false,
            currentPage: 1,
            pageSize: process.env.VUE_APP_PAGINATION_PAGE_SIZE,
            offset: 0,
            sortColumn: 'NAME',
            sortType: 'ASCENDING',
            sortDesc: false,
            sortBySync: 'name',
            RoleType
        }
    },

    computed: {
        ...mapGetters({
            userIsOwner: 'UserStore/GetIsUserOwner'
        }),

        filteredFields () {
            return [
                {
                    key: 'name',
                    label: 'Name',
                    sortable: true
                },

                {
                    key: 'email',
                    label: 'Email',
                    sortable: true
                },

                {
                    key: 'role',
                    label: 'Role',
                    sortable: true
                },

                {
                    key: 'isActive',
                    label: 'Status',
                    sortable: true
                },

                {
                    key: 'action',
                    label: ''
                }
            ]
        }
    },

    async mounted () {
        await this.getUsers()
    },

    methods: {
        ...mapActions({
            setNotification: 'NotificationStore/SetNotification'
        }),

        async getUsers () {
            this.isBusy = true

            try {
                var response
                if (this.query) {
                    response = await axios.get(`${process.env.VUE_APP_AXIOS_ADMIN_BASE_URL}/api/v1/admins/${this.query}/${this.pageSize}/${this.offset}/${this.sortColumn}/${this.sortType}`)
                } else {
                    response = await axios.get(`${process.env.VUE_APP_AXIOS_ADMIN_BASE_URL}/api/v1/admins/${this.pageSize}/${this.offset}/${this.sortColumn}/${this.sortType}`)
                }

                this.results = response.data
            } catch (err) {
                this.setNotification({
                    type: 'error',
                    title: 'Error',
                    message: 'Something went wrong while fetching the users. Please try again.'
                })
            } finally {
                this.loading = false
                this.isBusy = false
            }
        },

        async resetSearch () {
            this.query = ''
            await this.search()
        },

        async search () {
            this.currentPage = 1
            this.offset = 0
            await this.getUsers()
        },

        pageChange (value) {
            this.currentPage = value
            this.offset = (value - 1) * this.pageSize
            this.getUsers()
        },

        sortingChanged (e) {
            switch (e.sortBy) {
                case 'name':
                    this.sortColumn = 'NAME'
                break

                case 'email':
                    this.sortColumn = 'EMAIL'
                break

                case 'role':
                    this.sortColumn = 'ROLE'
                break

                case 'isActive':
                    this.sortColumn = 'STATUS'
                break
            }

            this.sortDesc = e.sortDesc
            this.sortBySync = e.sortBy

            if (this.sortDesc) {
                this.sortType = 'DESCENDING'
            } else {
                this.sortType = 'ASCENDING'
            }

            this.getUsers()
        }
    }
}
</script>
