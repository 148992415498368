const OrderStatus = Object.freeze({
    OPEN: 0,
    PACKING: 1,
    QA: 2,
    PASSED_QA: 3,
    DISPATCHED: 4,
    FUTURE: 5,
    CANCELLED: 6
})

export default OrderStatus
