export default {
    EF001: 'Cover 1 removed then replaced again before sample detected on Strip 1',
    EF002: 'Cover 2 removed at some point before test started',
    EF003: 'Battery low at start of test',
    EF004: 'Cover 2 removed early during the test',
    EF005: 'Timeout waiting for timer SET switch to be pressed',
    EF006: 'Timeout waiting for user to cancel timer alarm',
    EF007: 'Timeout waiting for cover 2 to be removed',
    // EF008: "",
    EF009: 'Timeout waiting for sample on Strip 1',
    EF010: 'Strip 1 measurement between T=0 to T=8 too low',
    EF011: 'Strip 1 measurement between T=8 to T=9 too high',
    EF012: 'Strip 1 measurement between T=8 to T=9 too low',
    EF013: 'Strip 1 measurement between T=9 to T=10 too high',
    EF014: 'Strip 1 measurement between T=9 to T=10 too low',
    EF015: 'Strip 1 gradient was positive',
    // EF016: "",
    EF017: 'Timeout waiting for sample on Strip 2',
    EF018: 'Strip 2 measurement between T=0 to T=8 too low',
    EF019: 'Strip 2 measurement between T=8 to T=9 too high',
    EF020: 'Strip 2 measurement between T=8 to T=9 too low',
    EF021: 'Strip 2 measurement between T=9 to T=10 too high',
    EF022: 'Strip 2 measurement between T=9 to T=10 too low',
    EF023: 'Strip 2 gradient was positive'
    // EF024: "",
    // EF025: "",
    // EF026: "",
    // EF027: "",
    // EF028: "",
    // EF029: "",
    // EF030: "",
}
