<template>
    <div>
        <h2 class="mt-2">Fulfilment | Search</h2>

        <div class="accordion mt-4" role="tablist">
            <div class="card">
                <div class="card-header bg-none d-flex align-items-center justify-content-between p-3 p-1" role="tab">
                    <h5 class="mb-0">Search criteria</h5>
                    <b-button v-b-toggle.accordion-1 variant="link" @click="collapsed = !collapsed"><span class="underlined">Hide</span> <img class="accordion-chevron" :class="{collapsed : collapsed}" src="@/assets/images/icons/select-chevron.svg" /></b-button>
                </div>

                <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                    <div class="card-body pt-2">
                        <div class="row">
                            <div class="col-xl-11">
                                <div class="row">
                                    <div class="col-xl-4 col-lg-6">
                                        <div class="form-group">
                                            <label>Patient name</label>
                                            <input type="text" class="form-control" placeholder="Search for patient name" v-model="advancedSearchParams.queryText">
                                        </div>

                                        <div class="form-group">
                                            <label>Address</label>
                                            <input type="text" class="form-control" placeholder="Search for address" v-model="advancedSearchParams.addressText">
                                        </div>

                                        <div class="form-group">
                                            <label>Organisation</label>
                                            <select class="custom-select" v-model="advancedSearchParams.organisationId" @change="chooseOrganisation()">
                                                <option value="">Please choose an organisation</option>
                                                <option v-for="(organisation, index) in allOrganisations" :key="index" :value="organisation.id">{{ organisation.name }}</option>
                                            </select>
                                            <small v-if="errors && errors.organisationId" class="text-danger">
                                                {{ errors.organisationId[0] }}
                                            </small>
                                        </div>

                                        <div class="form-group">
                                            <label>Facility</label>
                                            <select :disabled="facilityListDisabled" class="custom-select" v-model="advancedSearchParams.facilityId">
                                                <option value="">All Facilities</option>
                                                <option v-for="(facility, index) in allFacilities" :key="index" :value="facility.id">{{ facility.name }}</option>
                                            </select>
                                        </div>

                                        <div class="form-group">
                                            <label>Health care professional</label>
                                            <select :disabled="practitionerListDisabled" class="custom-select" v-model="advancedSearchParams.practitionerId">
                                                <option value="">All HCPs</option>
                                                <option v-for="(practitioner, index) in allPractitioners" :key="index" :value="practitioner.id">{{ practitioner.name }}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-xl-4 col-lg-6">
                                        <div class="form-group">
                                            <label>Device number</label>
                                            <input type="text" class="form-control" placeholder="Search for device number" v-model="advancedSearchParams.deviceNumber">
                                        </div>

                                        <div class="form-group">
                                            <label>Order number</label>
                                            <input type="text" class="form-control" placeholder="Search for order number" v-model="advancedSearchParams.orderNumber">
                                        </div>

                                        <div class="form-group">
                                            <label>Test number</label>
                                            <input type="text" class="form-control" placeholder="Search for test number" v-model="advancedSearchParams.testNumber">
                                        </div>

                                        <div class="form-group">
                                            <div class="row align-items-end">
                                                <div class="col-md-5">
                                                    <label>Issue date from</label>
                                                    <b-form-datepicker
                                                        v-model="advancedSearchParams.issueDateFrom"
                                                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                        :locale="locale"
                                                        placeholder="Choose"
                                                        hide-header
                                                        show-decade-nav
                                                        no-flip
                                                    >
                                                    </b-form-datepicker>
                                                </div>

                                                <div class="col-md-5 mt-2 mt-md-0">
                                                    <label>Issue date to</label>
                                                    <b-form-datepicker
                                                        v-model="advancedSearchParams.issueDateTo"
                                                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                        :locale="locale"
                                                        placeholder="Choose"
                                                        hide-header
                                                        show-decade-nav
                                                        no-flip
                                                    >
                                                    </b-form-datepicker>
                                                </div>

                                                <div class="col-md-2">
                                                    <span @click="clearIssueDates()" class="text-underlined cursor-pointer d-block mb-2">
                                                        <svg class="clear-date" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 28 28">
                                                            <g id="Group_654" data-name="Group 654" transform="translate(-1030 -412)">
                                                                <g class="change-on-hover" id="Ellipse_383" data-name="Ellipse 383" transform="translate(1030 412)" fill="#dae2e7" stroke="#b5c4cf" stroke-width="1">
                                                                    <circle cx="14" cy="14" r="14" stroke="none"/>
                                                                    <circle cx="14" cy="14" r="13.5" fill="none"/>
                                                                </g>
                                                                <g id="Group_653" data-name="Group 653">
                                                                    <path id="Path_6099" data-name="Path 6099" d="M14240.6-19010.924l-10.974-10.975,2.121-2.121,10.974,10.975Z" transform="translate(-13192 19443.473)" fill="#515b62"/>
                                                                    <path id="Path_6100" data-name="Path 6100" d="M14241.661-19022.959l-10.974,10.975" transform="translate(-13192.001 19443.473)" fill="none" stroke="#515b62" stroke-width="3"/>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <div class="row align-items-end">
                                                <div class="col-md-5">
                                                    <label>Test date from</label>
                                                    <b-form-datepicker
                                                        v-model="advancedSearchParams.dueDateFrom"
                                                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                        :locale="locale"
                                                        placeholder="Choose"
                                                        hide-header
                                                        show-decade-nav
                                                        no-flip
                                                    >
                                                    </b-form-datepicker>
                                                </div>

                                                <div class="col-md-5 mt-2 mt-md-0">
                                                    <label>Test date to</label>
                                                    <b-form-datepicker
                                                        v-model="advancedSearchParams.dueDateTo"
                                                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                        :locale="locale"
                                                        placeholder="Choose"
                                                        hide-header
                                                        show-decade-nav
                                                        no-flip
                                                    >
                                                    </b-form-datepicker>
                                                </div>

                                                <div class="col-md-2">
                                                    <span @click="clearDueDates()" class="text-underlined cursor-pointer d-block mb-2">
                                                        <svg class="clear-date" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 28 28">
                                                            <g id="Group_654" data-name="Group 654" transform="translate(-1030 -412)">
                                                                <g class="change-on-hover" id="Ellipse_383" data-name="Ellipse 383" transform="translate(1030 412)" fill="#dae2e7" stroke="#b5c4cf" stroke-width="1">
                                                                <circle cx="14" cy="14" r="14" stroke="none"/>
                                                                <circle cx="14" cy="14" r="13.5" fill="none"/>
                                                                </g>
                                                                <g id="Group_653" data-name="Group 653">
                                                                <path id="Path_6099" data-name="Path 6099" d="M14240.6-19010.924l-10.974-10.975,2.121-2.121,10.974,10.975Z" transform="translate(-13192 19443.473)" fill="#515b62"/>
                                                                <path id="Path_6100" data-name="Path 6100" d="M14241.661-19022.959l-10.974,10.975" transform="translate(-13192.001 19443.473)" fill="none" stroke="#515b62" stroke-width="3"/>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-4 col-lg-6 offset-lg-6 offset-xl-0">
                                        <div class="form-group">
                                            <label>Status</label>
                                                <div class="d-flex">
                                                    <select class="custom-select" v-model="advancedSearchParams.status" @change="enableDisableArchivedOption()">
                                                        <option value="ALL_ACTIVE">All Active</option>
                                                        <option value="ALL_CLOSED">All Closed</option>
                                                        <option value="OPEN">Open</option>
                                                        <option value="PACKING">Packing</option>
                                                        <option value="QA">QA</option>
                                                        <option value="PASSED_QA">Passed QA</option>
                                                        <option value="DISPATCHED">Dispatched</option>
                                                        <option value="FUTURE">Future</option>
                                                        <option value="CANCELLED">Cancelled</option>
                                                    </select>
                                                    <b-form-checkbox class="ml-3 mt-1" name="archived" v-model="advancedSearchParams.archived" :disabled="!archivedStatusEnabled">Archived</b-form-checkbox>
                                                </div>
                                        </div>

                                        <div class="d-flex mt-45">
                                            <button @click="cancel()" onclick="this.blur();" class="btn btn-cancel btn-lg d-flex flex-grow-1 justify-content-center lg-shadow">Clear</button>
                                            <button @click="search()" onclick="this.blur();" class="btn btn-success btn-lg d-flex flex-grow-1 justify-content-center lg-shadow ml-4">Search</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-collapse>
            </div>
        </div>

        <div v-if="!isBusy && !results.results.length && advancedSearchParams.hasSearchBeenExecuted">
            <div class="row">
                <div class="col">
                    <div class="mt-4">
                        <div class="alert alert-info">
                            Your search query returned no results. Please try amending your search criteria
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="results.results && results.results.length">
            <div class="row mt-5">
                <div class="col">
                    <p class="h4">Search Results <small class="font-italic text-sm">{{ `Showing ${showingComputed} of ${results.totalCount}` }}</small></p>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col">
                    <b-table
                        ref="table"
                        id="table"
                        :sort-by.sync="sortBySync"
                        :sort-desc.sync="sortDesc"
                        @sort-changed="sortingChanged"
                        :no-local-sorting="true"
                        :busy.sync="isBusy"
                        class="custom-table bg-white"
                        responsive
                        no-sort-reset
                        :fields="filteredTestsFields"
                        :items="results.results"
                    >
                        <template #cell(patientName)="data">
                            <router-link :to="{name: 'ViewOrder', params: {id : data.item.id}}" class="font-weight-bold underlined text-capitalize">{{ data.item.patientName }}</router-link>
                        </template>

                        <template #cell(practitionerName)="data">
                            <router-link v-if="!userIsFulfilment" class="font-weight-bold underlined text-capitalize" :to="{name: 'ViewHcp', params: {oId : data.item.organisationId, pId: data.item.practitionerId}}">{{ data.item.practitionerName }}</router-link>
                            <span v-else>{{ data.item.practitionerName }}</span>
                        </template>

                        <template #cell(facilityName)="data">
                            <router-link v-if="!userIsFulfilment" class="font-weight-bold underlined text-capitalize" :to="{name: 'ViewFacility', params: {oId : data.item.organisationId, fId: data.item.facilityId}}">{{ data.item.facilityName }}</router-link>
                            <span v-else>{{ data.item.facilityName }}</span>
                        </template>

                        <template #cell(organisationName)="data">
                            <router-link v-if="!userIsFulfilment" class="font-weight-bold underlined text-capitalize" :to="{name: 'ViewOrganisation', params: {id : data.item.organisationId}}">{{ data.item.organisationName }}</router-link>
                            <span v-else>{{ data.item.organisationName }}</span>
                        </template>

                        <template #cell(orderNumber)="data">
                            <router-link :to="{name: 'ViewOrder', params: {id : data.item.id}}" class="font-weight-bold underlined text-capitalize">{{ data.item.orderNumber }}</router-link>
                        </template>

                        <template #cell(testNumber)="data">
                            <router-link class="font-weight-bold underlined text-capitalize" :to="{name: 'ViewTest', params: {oId : data.item.organisationId, tId: data.item.testId}}">{{ data.item.testNumber }}</router-link>
                        </template>

                        <template #cell(status)="data">
                            <orderStatuses :status="data.item.status" :cancelled="data.item.cancelled" :future="data.item.futureOrder"/>
                        </template>

                        <template #cell(action)="data">
                            <router-link :to="{name: 'ViewOrder', params: {id : data.item.id}}" class="btn btn-primary sm-shadow text-nowrap">View</router-link>
                        </template>
                    </b-table>

                    <div class="mt-4 d-flex flex-column flex-md-row align-items-center justify-content-end">
                        <div class="form-group d-flex align-items-center mb-4 mb-md-0">
                            <label class="flex-shrink-0 mr-2">Items per page</label>
                            <select class="custom-select" v-model="advancedSearchParams.pageSize" @change="handleChangePageSize()">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                            </select>
                        </div>

                        <div>
                            <b-pagination
                                v-model="currentPage"
                                :current-page="currentPage"
                                :per-page="results.pageSize"
                                :total-rows="results.totalCount"
                                prev-text="< Previous"
                                next-text="Next >"
                                @change="pageChange"
                                aria-controls="table"
                                hide-goto-end-buttons
                                prev-class="prev-page"
                                next-class="next-page"
                                align="right"
                                class="mb-0"
                            ></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import OrderStatuses from '@/components/OrderStatusesComponent'
import IssueMethod from '@/enums/IssueMethod'
import TestIssueReason from '@/enums/TestIssueReason'
import TestStatus from '@/enums/TestStatus'
import TotalResultsMixin from '@/mixins/TotalResultsMixin'
import FormatterMixin from '@/mixins/FormatterMixin'
export default {
    name: 'FulfilmentAdvancedSearch',

    components: {
        OrderStatuses
    },

    mixins: [
        TotalResultsMixin,
        FormatterMixin
    ],

    data () {
        return {
            facilityListDisabled: true,
            practitionerListDisabled: true,
            collapsed: false,
            locale: 'en-GB',
            loading: false,
            isBusy: false,
            currentPage: 1,
            offset: 0,
            sortColumn: 'PATIENT_NAME',
            sortType: 'ASCENDING',
            sortBySync: 'patientName',
            sortDesc: false,
            errors: {},
            IssueMethod,
            TestIssueReason,
            TestStatus,
            archivedStatusEnabled: false
        }
    },

    async mounted () {
        await this.getAllOrganisations()

        if (this.advancedSearchParams.organisationId !== '') {
            await this.getFacilitiesByOrganisation(this.advancedSearchParams.organisationId)
            await this.getPractitionersByOrganisation(this.advancedSearchParams.organisationId)
            this.facilityListDisabled = false
            this.practitionerListDisabled = false
        }

        this.enableDisableArchivedOption()
    },

    computed: {
        ...mapGetters({
            userId: 'UserStore/GetUserId',
            userIsFulfilment: 'UserStore/GetIsUserFulfilment',
            advancedSearchParams: 'FulfilmentSearchStore/GetAdvancedSearchParams',
            results: 'FulfilmentSearchStore/GetResults',
            allFacilities: 'FacilityStore/GetAllFacilities',
            allPractitioners: 'PractitionerStore/GetAllPractitioners',
            allOrganisations: 'OrganisationStore/GetAllOrganisations'
        }),

        years () {
            const currentYear = new Date().getFullYear()
            const years = []
            let startYear = 1920

            while (startYear <= currentYear) {
                years.push(startYear++)
            }

            return years
        },

        filteredTestsFields () {
            return [
                {
                    key: 'patientName',
                    label: 'Patient name',
                    variant: 'text-wrap w-15',
                    sortable: true
                },
                {
                    key: 'practitionerName',
                    label: 'Assigned HCP',
                    sortable: 'true'
                },
                {
                    key: 'facilityName',
                    label: 'Facility',
                    sortable: true
                },
                {
                    key: 'organisationName',
                    label: 'Organisation',
                    sortable: true
                },
                {
                    key: 'serialNumber',
                    label: 'Device No',
                    formatter: 'formatSerialNumber',
                    sortable: true
                },
                {
                    key: 'orderNumber',
                    label: 'Order No',
                    sortable: true
                },
                {
                    key: 'testNumber',
                    label: 'Test No',
                    sortable: true
                },
                {
                    key: 'issuedDate',
                    label: 'Issue Date',
                    sortable: true,
                    formatter: 'formatDate'
                },
                {
                    key: 'dueDate',
                    label: 'Test date',
                    sortable: true,
                    formatter: 'formatDate'
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: true
                },
                {
                    key: 'dateUpdated',
                    label: 'Last Updated',
                    variant: 'text-wrap w-15',
                    formatter: 'formatDate',
                    sortable: true
                },
                {
                    key: 'action',
                    label: ''
                }
            ]
        }
    },

    methods: {
        ...mapActions({
            clearAdvancedFacilityIdAndAdvancedPractitionerIdInSearchStore: 'FulfilmentSearchStore/ClearAdvancedFacilityIdAndAdvancedPractitionerId',
            getFacilitiesByOrganisation: 'FacilityStore/GetFacilitiesByOrganisation',
            getPractitionersByOrganisation: 'PractitionerStore/GetPractitionersByOrganisation',
            getAllOrganisations: 'OrganisationStore/GetAllOrganisations',
            setSearchResultsInSearchStore: 'FulfilmentSearchStore/SetSearchResults',
            setNotification: 'NotificationStore/SetNotification',
            clearSearchStore: 'FulfilmentSearchStore/ClearSearchStore'
        }),

        async search () {
            this.currentPage = 1
            this.offset = 0
            await this.getResults()
        },

        async getResults () {
            this.checkForm()

            if (!Object.keys(this.errors).length) {
                const searchModel = {
                    patientText: this.advancedSearchParams.queryText,
                    shippingAddressText: this.advancedSearchParams.addressText,
                    organisationId: this.advancedSearchParams.organisationId,
                    facilityId: this.advancedSearchParams.facilityId,
                    practitionerId: this.advancedSearchParams.practitionerId,
                    deviceNumber: this.advancedSearchParams.deviceNumber,
                    orderNumber: this.advancedSearchParams.orderNumber,
                    issueDateFrom: this.advancedSearchParams.issueDateFrom,
                    issueDateTo: this.advancedSearchParams.issueDateTo,
                    dueDateFrom: this.advancedSearchParams.dueDateFrom,
                    dueDateTo: this.advancedSearchParams.dueDateTo,
                    status: this.advancedSearchParams.status,
                    testNumber: this.advancedSearchParams.testNumber,
                    // hasNotes: true,
                    // cancelled: false,
                    // onHold: false,
                    pageSize: this.advancedSearchParams.pageSize,
                    offset: this.offset,
                    sortColumn: this.sortColumn,
                    sortType: this.sortType,
                    searchType: this.advancedSearchParams.searchType
                }

                // Special cases
                if (searchModel.status === 'ALL_ACTIVE') {
                    searchModel.status = null
                } else if (searchModel.status === 'ALL_CLOSED') {
                    searchModel.status = 'CLOSED'
                }

                if (this.advancedSearchParams.archived) {
                    searchModel.searchType = 'ARCHIVED'
                } else {
                    searchModel.searchType = 'ACTIVE'
                }

                try {
                    this.isBusy = true
                    this.advancedSearchParams.hasSearchBeenExecuted = true

                    const response = await axios.post(`${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/orders/search`, searchModel)

                    this.setSearchResultsInSearchStore(response.data)
                } catch {
                    this.setNotification({
                        type: 'error',
                        title: 'Error',
                        message: 'Something went wrong. Please try again.'
                    })
                } finally {
                    this.isBusy = false
                }
            }
        },

        async chooseOrganisation () {
            await this.clearAdvancedFacilityIdAndAdvancedPractitionerIdInSearchStore()

            if (this.advancedSearchParams.organisationId) {
                await this.getFacilitiesByOrganisation(this.advancedSearchParams.organisationId)
                await this.getPractitionersByOrganisation(this.advancedSearchParams.organisationId)
                this.facilityListDisabled = false
                this.practitionerListDisabled = false
            } else {
                this.facilityListDisabled = true
                this.practitionerListDisabled = true
            }
        },

        sortingChanged (e) {
            switch (e.sortBy) {
                case 'patientName':
                    this.sortColumn = 'PATIENT_NAME'
                break

                case 'patientIdentifier':
                    this.sortColumn = 'PATIENT_IDENTIFIER'
                break

                case 'deviceNumber':
                    this.sortColumn = 'DEVICE_NUMBER'
                break

                case 'orderNumber':
                    this.sortColumn = 'ORDER_NUMBER'
                break

                case 'testNumber':
                    this.sortColumn = 'TEST_NUMBER'
                break

                case 'issuedDate':
                    this.sortColumn = 'ISSUED_DATE'
                break

                case 'dueDate':
                    this.sortColumn = 'DUE_DATE'
                break

                case 'status':
                    this.sortColumn = 'STATUS'
                break

                case 'practitionerName':
                    this.sortColumn = 'PRACTITIONER_NAME'
                break

                case 'facilityName':
                    this.sortColumn = 'FACILITY_NAME'
                break

                case 'organisationName':
                    this.sortColumn = 'ORGANISATION_NAME'
                break

                case 'dateUpdated':
                    this.sortColumn = 'LAST_UPDATED_DATE'
                break
            }

            this.sortDesc = e.sortDesc

            if (this.sortDesc) {
                this.sortType = 'DESCENDING'
            } else {
                this.sortType = 'ASCENDING'
            }

            this.getResults()
        },

        pageChange (value) {
            this.currentPage = value
            this.offset = (value - 1) * this.results.pageSize
            this.getResults()
        },

        clearIssueDates () {
            this.advancedSearchParams.issueDateFrom = null
            this.advancedSearchParams.issueDateTo = null
        },

        clearDueDates () {
            this.advancedSearchParams.dueDateFrom = null
            this.advancedSearchParams.dueDateTo = null
        },

        checkForm () {
            this.errors = {}

            if (this.advancedSearchParams.organisationId === '') {
                this.errors.organisationId = ['You must choose an organisation']
            }
        },

        refreshTable () {
            this.$refs.table.refresh()
        },

        cancel () {
            this.practitionerListDisabled = true
            this.facilityListDisabled = true
            this.clearSearchStore()
        },

        enableDisableArchivedOption () {
            if (this.advancedSearchParams.status === 'ALL_CLOSED' || this.advancedSearchParams.status === 'CANCELLED' || this.advancedSearchParams.status === 'DISPATCHED') {
                this.archivedStatusEnabled = true
            } else {
                this.advancedSearchParams.archived = false
                this.archivedStatusEnabled = false
            }
        },

        handleChangePageSize () {
            this.currentPage = 1
            this.offset = 0

            this.getResults()
        }
    }
}
</script>
