<template>
    <div>
        <div v-if="errorMsg">
            <img class="d-block mx-auto" src="@/assets/images/icons/error.svg" width="80" alt="tick">
            <p class="text-center lead font-weight-bold mt-3">Package error</p>
            <div class="text-sm mt-4">
                <div class="row">
                    <div class="col-4">Device number</div>
                    <div class="col-8">{{rawData}}</div>
                </div>

                <div v-if="parsedResponse && parsedResponse.orderNumber">

                    <hr />

                    <div class="row">
                        <div class="col-4">Order number</div>
                        <div class="col-8">{{parsedResponse.orderNumber}}</div>
                    </div>

                </div>

                <hr />

                <div class="row">
                    <div class="col-4">Error</div>
                    <div class="col-8">{{errorMsg}}</div>
                </div>

                <hr />

                <div class="d-flex align-items-center mt-45">
                    <button v-if="parsedResponse.mappings.length > 0 && parsedResponse.mappings[0].orderId" :disabled="loading" @click="viewIncorrectOrder()" class="btn btn-success btn-lg lg-shadow d-flex flex-grow-1 w-55 ml-3 justify-content-center align-items-center" :class="{disabled : loading}">View Order <span v-if="loading" class="btn-loading"></span></button>
                    <button v-else :disabled="loading" @click="viewAllOrders()" class="btn btn-cancel btn-lg lg-shadow d-flex flex-grow-1 w-55 ml-3 justify-content-center align-items-center" :class="{disabled : loading}">Find Order <span v-if="loading" class="btn-loading"></span></button>
                    <button :disabled="loading" @click="stopScanning()" class="btn btn-danger btn-lg lg-shadow d-flex flex-grow-1 w-55 ml-3 justify-content-center align-items-center" :class="{disabled : loading}">Stop Scanning <span v-if="loading" class="btn-loading"></span></button>
                </div>
            </div>
        </div>
        <div v-if="!errorMsg">
            <img class="d-block mx-auto" src="@/assets/images/icons/green-tick.svg" width="80" alt="tick">
            <p class="text-center lead font-weight-bold mt-3">Package confirmed</p>
            <div class="text-sm mt-4">
                <div class="row">
                    <div class="col-4">Device number</div>
                    <div class="col-8">{{rawData}}</div>
                </div>

                <hr />

                <div class="row">
                    <div class="col-4">Assigned to</div>
                    <div class="col-8">Order {{parsedResponse.orderNumber}}</div>
                </div>

                <hr />

                <div class="row">
                    <div class="col-4">Shipping to</div>
                    <div class="col-8">
                        <div v-if="parsedResponse.shippingAddress.line">{{ parsedResponse.shippingAddress.line }}</div>
                        <div v-if="parsedResponse.shippingAddress.city">{{ parsedResponse.shippingAddress.city }}</div>
                        <div v-if="parsedResponse.shippingAddress.state">{{ parsedResponse.shippingAddress.state }}</div>
                        <div v-if="parsedResponse.shippingAddress.postalCode">{{ parsedResponse.shippingAddress.postalCode }}</div>
                        <div v-if="parsedResponse.shippingAddress.country">{{ parsedResponse.shippingAddress.country }}</div>
                    </div>
                </div>

                <hr />

                <div class="d-flex align-items-center mt-45">
                    <button v-if="!parsedResponse.IsValid" :disabled="loading" @click="viewOrder()" class="btn btn-success btn-lg lg-shadow d-flex flex-grow-1 w-55 ml-3 justify-content-center align-items-center" :class="{disabled : loading}">View Order <span v-if="loading" class="btn-loading"></span></button>
                    <button v-if="!parsedResponse.IsValid" :disabled="loading" @click="stopScanning()" class="btn btn-danger btn-lg lg-shadow d-flex flex-grow-1 w-55 ml-3 justify-content-center align-items-center" :class="{disabled : loading}">Stop Scanning <span v-if="loading" class="btn-loading"></span></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import FormatterMixin from '@/mixins/FormatterMixin'
export default {
    name: 'CheckPackageModalComponent',

    props: {
        orderId: String,
        orderNumber: Number,
        rawData: String,
        parsedResponse: Object,
        errorMsg: String
    },

    mixins: [
        FormatterMixin
    ],

    data () {
        return {
            loading: false
        }
    },

    methods: {
        ...mapActions({
            setNotification: 'NotificationStore/SetNotification'
        }),

        viewOrder () {
            this.close()
            return this.$router.push({ name: 'ViewOrder', params: { id: this.parsedResponse.orderId } })
        },

        close () {
            this.$emit('close')
        },

        stopScanning () {
            this.close()
        },

        viewIncorrectOrder () {
            this.close()
            return this.$router.push({ name: 'ViewOrder', params: { id: this.parsedResponse.mappings[0].orderId } })
        },

        viewAllOrders () {
            this.close()
            return this.$router.push({ name: 'FulfilmentSearch' })
        }
    }
}
</script>
