import axios from 'axios'
export default {
    namespaced: true,

    state: {
        organisations: []
    },

    getters: {
        GetAllOrganisations (state) {
            return state.organisations
        }
    },

    mutations: {
        PUSH_ORGANISATIONS (state, data) {
            state.organisations = data
        }
    },

    actions: {
        async GetAllOrganisations ({ commit }) {
            try {
                const response = await axios.get(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/organisations`)

                commit('PUSH_ORGANISATIONS', response.data)

                return response
            } catch (err) {
                throw new Error(err)
            }
        }
    }
}
