<template>
    <div>
        <div v-if="domainId">
            <p class="text-center lead font-weight-bold mt-3">Edit domain name</p>
            <div class="text-sm mt-4">
                <div class="row">
                    <label for="domain-name" class="col-sm-4 col-form-label">Domain Name</label>
                    <div class="col-8"><input type="text" class="form-control" name="domain-name" v-model="domainName" /></div>
                </div>
                <div class="d-flex align-items-center mt-45">
                    <button @click="close()" class="btn btn-cancel btn-lg lg-shadow d-flex flex-grow-1 w-45 justify-content-center align-items-center">Cancel</button>
                    <button @click="updateDomain()" class="btn btn-success btn-lg lg-shadow d-flex flex-grow-1 w-55 ml-3 justify-content-center align-items-center" :class="{disabled : loading || domainName.length === 0 || domainName === initialDomainName}">Save changes</button>
                </div>
            </div>
        </div>
        <div v-if="!domainId">
            <p class="text-center lead font-weight-bold mt-3">Add a domain</p>
            <div class="text-sm mt-4">
                <div class="row">
                    <label for="domain-name" class="col-sm-4 col-form-label">Domain Name</label>
                    <div class="col-8"><input type="text" class="form-control" name="domain-name" v-model="domainName" /></div>
                </div>

                <div class="d-flex align-items-center mt-45">
                    <button @click="close()" class="btn btn-cancel btn-lg lg-shadow d-flex flex-grow-1 w-45 justify-content-center align-items-center">Cancel</button>
                    <button @click="addDomain()" class="btn btn-success btn-lg lg-shadow d-flex flex-grow-1 w-55 ml-3 justify-content-center align-items-center" :class="{disabled : loading || domainName.length === 0}">Create</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mapActions } from 'vuex'
export default {
    name: 'AddEditDomainModalComponent',

    props: {
        initialDomainId: String,
        initialDomainName: String
    },

    data () {
        return {
            loading: false,
            domainId: this.initialDomainId,
            domainName: this.initialDomainName ?? ''
        }
    },

    methods: {

        ...mapActions({
            populateDomains: 'DomainsStore/PopulateDomains'
        }),

        close () {
            this.$emit('close')
        },

        async addDomain () {
            this.loading = true
            try {
                await axios.post(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/domains/`, {
                    name: this.domainName
                })

                this.populateDomains()

                this.$emit('close')
            } catch (err) {
                this.setNotification({
                    type: 'error',
                    title: 'Error',
                    message: err.data.detail ? err.data.detail : 'Something went wrong trying to create the domain. Please try again.'
                })
            } finally {
                this.loading = false
            }
        },

        async updateDomain () {
            this.loading = true
            try {
                await axios.patch(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/domains/${this.domainId}`, {
                    name: this.domainName
                })

                this.populateDomains()

                this.$emit('close')
            } catch (err) {
                this.setNotification({
                    type: 'error',
                    title: 'Error',
                    message: err.data.detail ? err.data.detail : 'Something went wrong trying to update the domain. Please try again.'
                })
            } finally {
                this.loading = false
            }
        }
    }
}
</script>
