<template>
    <div>
        <span v-if="!cancelled && !future && status == OrderStatus.OPEN" class="badge badge-pill badge-fulfilment-open">Open</span>
        <span v-if="!cancelled && !future && status == OrderStatus.PACKING" class="badge badge-pill badge-fulfilment-packing">Packing</span>
        <span v-if="!cancelled && !future && status == OrderStatus.QA" class="badge badge-pill badge-fulfilment-qa">QA</span>
        <span v-if="!cancelled && !future && status == OrderStatus.PASSED_QA" class="badge badge-pill badge-fulfilment-passed-qa">Passed QA</span>
        <span v-if="!cancelled && !future && status == OrderStatus.DISPATCHED" class="badge badge-pill badge-fulfilment-dispatched">Dispatched</span>
        <span v-if="!cancelled && future" class="badge badge-pill badge-fulfilment-future-orders">Future</span>
        <span v-if="cancelled" class="badge badge-pill badge-fulfilment-cancelled">Cancelled</span>
    </div>
</template>

<script>
import OrderStatus from '@/enums/OrderStatus'
export default {
    name: 'OrderStatusBadgesComponent',

    props: {
        status: Number,
        cancelled: Boolean,
        future: Boolean
    },

    data () {
        return {
            OrderStatus
        }
    }
}
</script>
