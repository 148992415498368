<template>
    <div>
        <p class="modal-heading">Are you sure you want to cancel the test?</p>
        <p v-if="!testExists" class="text-sm">Any progress will not be saved</p>

        <div v-else>
            <label class="mt-2">Reason <span class="text-danger">*</span></label>
            <textarea class="form-control note" :class="{ 'border border-danger' : noteError }" placeholder="Tell us the reason for cancelling this test" v-model="note"></textarea>
            <p v-if="noteError" class="text-danger text-xs">This is a required field</p>
        </div>

        <div class="d-flex align-items-center mt-45">
            <button :disabled="loading" @click="cancelTest()" class="btn btn-success btn-lg lg-shadow d-flex flex-grow-1 w-50 justify-content-center align-items-center" :class="{disabled : loading}">Yes <span v-if="loading" class="btn-loading"></span></button>
            <button :disabled="loading" @click="close()" class="btn btn-danger btn-lg lg-shadow d-flex flex-grow-1 w-50 ml-3 justify-content-center align-items-center" :class="{disabled : loading}">No</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mapActions } from 'vuex'
export default {
    name: 'CancelTestModalComponent',

    props: {
        testExists: Boolean(false),
        organisationId: String,
        testId: String
    },

    data () {
        return {
            loading: false,
            note: null,
            noteError: false
        }
    },

    methods: {
        ...mapActions({
            setNotification: 'NotificationStore/SetNotification',
            setShouldRefreshViewTestComponent: 'AppStore/SetShouldRefreshViewTestComponent',
            getStatusCountsAction: 'FulfilmentStatusCountsStore/GetStatusCounts'
        }),

        async cancelTest () {
            this.noteError = false

            if (this.testExists) {
                if (this.note === null || this.note === '') {
                    this.noteError = true
                    return
                }

                this.loading = true

                try {
                    await axios.patch(`${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/tests/${this.organisationId}/${this.testId}/cancel`, {
                        reason: this.note
                    })

                    this.setNotification({
                        type: 'success',
                        title: 'Success',
                        message: 'The test has been marked as cancelled'
                    })
                    await this.getStatusCountsAction()
                } catch {
                    this.setNotification({
                        type: 'error',
                        title: 'Error',
                        message: 'Sorry, something went wrong when cancelling the test. Please try again'
                    })
                } finally {
                    this.loading = false
                }
            }

            this.$emit('close')
            this.setShouldRefreshViewTestComponent(true)
        },
        close () {
            this.$emit('close')
        }
    }
}
</script>
