<template>
    <div>
        <Loader v-if="loading" />

        <div v-else>
            <div class="row align-items-center no-gutters">
                <div class="col-12 d-flex flex-wrap justify-content-between flex-row-gap-20">
                    <div>
                        <h2 class="mb-0 mt-2">Review Results</h2>
                    </div>
                    <div v-if="selected.length > 0" class="flex text-right center-mobile results-button-container-parent">
                        <div class="results-button-container text-right">
                            <div class="results-button-wrapper readonly">
                                <button class="btn results-button-first">{{ selected.length }} selected</button>
                            </div>
                            <div class="results-button-wrapper">
                                <button :disabled="isBusy || !selected.length" class="btn" :class="{disabled : isBusy || !selected.length}" @click="setAsAccepted()"><img src="@/assets/images/icons/accept-icon.svg" /><span>Accept</span></button>
                            </div>
                            <div class="results-button-divider">
                                <span></span>
                            </div>
                            <div class="results-button-wrapper">
                                <button :disabled="isBusy || !selected.length" class="btn" :class="{disabled : isBusy || !selected.length}" @click="setAsReturned()"><img src="@/assets/images/icons/set-return-icon.svg" /><span>Set as Returned</span></button>
                            </div>
                            <div class="results-button-divider">
                                <span></span>
                            </div>
                            <div class="results-button-wrapper">
                                <button :disabled="isBusy || !selected.length" class="btn results-button-last cancel" :class="{disabled : isBusy || !selected.length}" @click="cancelSelection()"><img src="@/assets/images/icons/cancel-icon.svg" /><span>Cancel</span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-3 mt-md-45">
                <div class="col">
                    <h5 class="mb-0">All <span class="font-italic">({{ results.totalCount }})</span></h5>
                </div>
            </div>

            <div v-if="!results.results.length">
                <div class="row mt-5">
                    <div class="col-md-6">
                        <div class="alert alert-info">
                            There are currently no results for review
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="results.results && results.results.length" class="row mt-4">
                <div class="col">
                    <b-table
                        ref="table"
                        id="table"
                        :sort-by.sync="sortBySync"
                        :sort-desc.sync="sortDesc"
                        @sort-changed="sortingChanged"
                        :no-local-sorting="true"
                        :busy.sync="isBusy"
                        class="custom-table bg-white"
                        responsive
                        no-sort-reset
                        :fields="filteredTestsFields"
                        :items="results.results"
                        @row-selected="onRowSelected"
                        selectable
                        :select-mode="selectMode"
                    >

                        <template #cell(select)="data">
                            <div>
                                <b-form-checkbox
                                    v-if="data.rowSelected"
                                    v-model="data.rowSelected"
                                    @change="data.unselectRow()"
                                >
                                </b-form-checkbox>

                                <b-form-checkbox
                                    v-if="!data.rowSelected"
                                    v-model="data.rowSelected"
                                    @change="data.selectRow()"
                                >
                                </b-form-checkbox>

                                <div class="test-history-icons" v-if="data.item.hasBeenPreviouslyReordered || data.item.previouslyBeenSetAsReturned">
                                    <img src="@/assets/images/icons/set-return-icon.svg" :class="{invisible: !data.item.previouslyBeenSetAsReturned}" v-b-tooltip.hover title="Test has previously been set as Returned" />
                                    <img v-if="data.item.replacedByTestId" src="@/assets/images/icons/reorder-old-icon.svg" v-b-tooltip.hover title="This test has been reordered"/>
                                    <img v-else-if="data.item.replacesTestId" src="@/assets/images/icons/reorder-new-icon.svg" v-b-tooltip.hover title="This test replaces a previous test"/>
                                    <img v-else-if="data.item.hasBeenPreviouslyReordered" src="@/assets/images/icons/reorder-old-icon.svg" v-b-tooltip.hover title="Test has been previously reordered"/>
                                </div>
                            </div>
                        </template>

                          <!-- A custom formatted header cell for field 'name' -->
                        <template #head(select)="data">
                            <b-form-checkbox
                                    @change="toggleAllRowsSelected(data)"
                                >
                                </b-form-checkbox>
                        </template>

                        <template #cell(patientName)="data">
                            <router-link class="font-weight-bold underlined text-capitalize" :to="{name: 'ViewTest', params: {oId : data.item.organisationId, tId: data.item.testId}}">{{ data.item.patientName }}</router-link>
                        </template>

                        <template #cell(testNumber)="data">
                            <router-link class="font-weight-bold underlined text-capitalize" :to="{name: 'ViewTest', params: {oId : data.item.organisationId, tId: data.item.testId}}">{{ data.item.testNumber }}</router-link>
                        </template>

                        <template #cell(inReviewStatus)="data">
                            <testStatuses :status="data.item.inReviewStatus" />
                        </template>

                        <template #cell(practitionerName)="data">
                            <router-link class="font-weight-bold underlined text-capitalize" :to="{name: 'ViewHcp', params: {oId : data.item.organisationId, pId: data.item.practitionerId}}">{{ data.item.practitionerName }}</router-link>
                        </template>

                        <template #cell(facilityName)="data">
                            <router-link class="font-weight-bold underlined text-capitalize" :to="{name: 'ViewFacility', params: {oId : data.item.organisationId, fId: data.item.facilityId}}">{{ data.item.facilityName }}</router-link>
                        </template>

                        <template #cell(organisationName)="data">
                            <router-link class="font-weight-bold underlined text-capitalize" :to="{name: 'ViewOrganisation', params: {id : data.item.organisationId}}">{{ data.item.organisationName }}</router-link>
                        </template>

                        <template #cell(action)="data">
                            <router-link :to="{name: 'ViewTest', params: {oId : data.item.organisationId, tId: data.item.testId}}" class="btn btn-primary sm-shadow text-nowrap">View Test</router-link>
                        </template>
                    </b-table>

                    <div class="mt-4 d-flex flex-column flex-md-row align-items-center justify-content-end">
                        <div class="form-group d-flex align-items-center mb-4 mb-md-0">
                            <label class="flex-shrink-0 mr-2">Items per page</label>
                            <select class="custom-select" v-model="pageSize" @change="handleChangePageSize()">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                            </select>
                        </div>

                        <div>
                            <b-pagination
                                v-model="currentPage"
                                :current-page="currentPage"
                                :per-page="results.pageSize"
                                :total-rows="results.totalCount"
                                prev-text="< Previous"
                                next-text="Next >"
                                @change="pageChange"
                                aria-controls="table"
                                hide-goto-end-buttons
                                prev-class="prev-page"
                                next-class="next-page"
                                align="right"
                                class="mb-0"
                            ></b-pagination>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

import Loader from '@/partials/Loader.vue'
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import TestStatuses from '@/components/TestStatusesComponent.vue'
import FormatterMixin from '@/mixins/FormatterMixin'
import AcceptTestsStatusModal from '@/components/modals/AcceptTestsStatusModalComponent.vue'
import ChangeTestsToReturnedModal from '@/components/modals/ChangeTestsToReturnedModalComponent.vue'
import ChangeTestsToCancelledModal from '@/components/modals/ChangeTestsToCancelledModalComponent.vue'
export default {
    name: 'ReviewResults',

    components: {
        Loader,
        TestStatuses
    },

    mixins: [
        FormatterMixin
    ],

    data () {
        return {
            loading: true,
            facilityListDisabled: true,
            practitionerListDisabled: true,
            query: '',
            queryError: false,
            isBusy: false,
            currentPage: 1,
            offset: 0,
            pageSize: process.env.VUE_APP_PAGINATION_PAGE_SIZE,
            sortColumn: 'LAST_UPDATED_DATE',
            sortType: 'DESCENDING',
            sortDesc: false,
            sortBySync: 'patientName',
            selectMode: 'multi',
            selected: [],
            selectedAllRows: false,
            results: {
                results: []
            }
        }
    },

    async mounted () {
        await this.getResults()
    },

    computed: {
        ...mapGetters({
            userIsOwner: 'UserStore/GetIsUserOwner',
            userId: 'UserStore/GetUserId',
            searchParams: 'SearchStore/GetSearchParams'
        }),

        filteredTestsFields () {
                return [
                {
                    key: 'select',
                    label: ''
                },
                {
                    key: 'patientName',
                    label: 'Patient',
                    variant: 'text-wrap w-15',
                    sortable: true
                },
                {
                    key: 'testNumber',
                    label: 'Test No.',
                    sortable: true
                },
                {
                    key: 'issuedDate',
                    label: 'Issue Date',
                    sortable: true,
                    formatter: 'formatDate'
                },
                {
                    key: 'returnDate',
                    label: 'Return date',
                    sortable: true,
                    formatter: 'formatDate'
                },
                {
                    key: 'dueDate',
                    label: 'Test date',
                    sortable: true,
                    formatter: 'formatDate'
                },
                {
                    key: 'inReviewStatus',
                    label: 'Status',
                    sortable: true
                },
                {
                    key: 'practitionerName',
                    label: 'Assigned HCP',
                    variant: 'text-wrap w-15',
                    sortable: true
                },
                {
                    key: 'facilityName',
                    label: 'Facility',
                    variant: 'text-wrap w-15',
                    sortable: true
                },
                {
                    key: 'organisationName',
                    label: 'Organisation',
                    variant: 'text-wrap w-15',
                    sortable: true
                },
                {
                    key: 'dateUpdated',
                    label: 'Last Updated',
                    variant: 'text-wrap w-15',
                    formatter: 'formatDate',
                    sortable: true
                },
                {
                    key: 'action',
                    label: ''
                }
            ]
        }
    },

    methods: {
        ...mapActions({
            clearSearchStore: 'SearchStore/ClearSearchStore',
            setNotification: 'NotificationStore/SetNotification'
        }),

        reset () {
            this.practitionerListDisabled = true
            this.facilityListDisabled = true

            this.clearSearchStore()

            this.search()
        },

        async search () {
            this.currentPage = 1
            this.offset = 0
            await this.getResults()
        },

        async getResults () {
            this.isBusy = true
            this.searchParams.hasSearchBeenExecuted = true

            const searchModel = {
                pageSize: this.pageSize,
                offset: this.offset,
                sortColumn: this.sortColumn,
                sortType: this.sortType,
                forResultsReview: true
            }

            try {
                const response = await axios.post(`${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/tests/list`, searchModel)

                this.results = response.data
            } catch (err) {
                this.setNotification({
                    type: 'error',
                    title: 'Error',
                    message: 'Something went wrong. Please try again'
                })
            } finally {
                this.loading = false
                this.isBusy = false
            }
        },

        sortingChanged (e) {
            switch (e.sortBy) {
                case 'patientName':
                    this.sortByValue = 'PATIENT_NAME'
                break

                case 'testNumber':
                    this.sortByValue = 'TEST_NUMBER'
                break

                case 'issuedDate':
                    this.sortByValue = 'ISSUED_DATE'
                break

                case 'returnDate':
                    this.sortByValue = 'RETURN_DATE'
                break

                case 'dueDate':
                    this.sortByValue = 'DUE_DATE'
                break

                case 'status':
                    this.sortByValue = 'STATUS'
                break

                case 'practitionerName':
                    this.sortByValue = 'PRACTITIONER_NAME'
                break

                case 'facilityName':
                    this.sortByValue = 'FACILITY_NAME'
                break

                case 'organisationName':
                    this.sortByValue = 'ORGANISATION_NAME'
                break

                case 'dateUpdated':
                    this.sortByValue = 'LAST_UPDATED_DATE'
                break
            }

            this.sortColumn = this.sortByValue

            this.sortDesc = e.sortDesc

            if (this.sortDesc) {
                this.sortType = 'DESCENDING'
            } else {
                this.sortType = 'ASCENDING'
            }

            this.getResults()
        },

        onRowSelected (items) {
            this.selected = items
        },

        pageChange (value) {
            this.currentPage = value
            this.offset = (value - 1) * this.results.pageSize
            this.getResults()
        },

        refreshTable () {
            this.$refs.table.refresh()
        },

        handleChangePageSize () {
            this.currentPage = 1
            this.offset = 0

            this.getResults()
        },

        async setAsAccepted () {
            this.$modal.show(AcceptTestsStatusModal, { tests: this.selected }, { width: '400px' }, { 'before-close': this.getResults })
        },

        async setAsReturned () {
            this.$modal.show(ChangeTestsToReturnedModal, { tests: this.selected }, { width: '400px' }, { 'before-close': this.getResults })
        },

        async setAsCancelled () {
            this.$modal.show(ChangeTestsToCancelledModal, { tests: this.selected }, { width: '400px' }, { 'before-close': this.getResults })
        },

        cancelSelection () {
            this.refreshTable()
            this.selected = []
        },

        toggleAllRowsSelected (data) {
            if (this.selectedAllRows) {
                data.clearSelected()
            } else {
                data.selectAllRows()
            }
            this.selectedAllRows = !this.selectedAllRows
        }
    }
}
</script>
