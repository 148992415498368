<template>
    <div>
        <p class="modal-heading">Cancel order</p>
        <p>Are you sure you would like to cancel the order?</p>
        <div class="text-sm mt-3">
            <textarea class="form-control note mt-4" placeholder="Optionally add a note to explain this issue" v-model="note"></textarea>

            <div class="alert alert-danger border border-danger mt-4 d-flex align-items-center" role="alert">
                <img class="mr-2" src="@/assets/images/icons/warning-icon.svg" width="20" /> The order will be moved to <span class="badge badge-pill badge-cancelled ml-2">Cancelled</span>
            </div>

            <div class="d-flex align-items-center mt-45">
                <button :disabled="loading" @click="$emit('close')" class="btn btn-cancel btn-lg lg-shadow d-flex flex-grow-1 w-45 justify-content-center align-items-center" :class="{disabled : loading}">Cancel</button>
                <button :disabled="loading" @click="confirm()" class="btn btn-success btn-lg lg-shadow d-flex flex-grow-1 w-55 ml-3 justify-content-center align-items-center" :class="{disabled : loading}">Cancel order <span v-if="loading" class="btn-loading"></span></button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import axios from 'axios'
export default {
    name: 'CancelOrderModalComponent',

    props: {
        id: String
    },

    data () {
        return {
            loading: false,
            error: false,
            note: null
        }
    },

    methods: {
        ...mapActions({
            getStatusCountsAction: 'FulfilmentStatusCountsStore/GetStatusCounts',
            setNotification: 'NotificationStore/SetNotification'
        }),

        async confirm () {
            this.loading = true

            try {
                await axios.post(`${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/orders/cancel?orderId=${this.id}`, {
                    reason: this.note
                })

                this.setNotification({
                    type: 'success',
                    title: 'Success',
                    message: 'The order has been cancelled.'
                })
                await this.getStatusCountsAction()
                this.$emit('close')
                return this.$router.push({ name: 'FulfilmentCancelled' })
            } catch {
                this.setNotification({
                    type: 'error',
                    title: 'Error',
                    message: 'Something went wrong. The order has not been cancelled.'
                })
            } finally {
                this.loading = false
            }
        },

        close () {
            this.$emit('close')
        }
    }
}
</script>
