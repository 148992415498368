<template>
    <div>
        <Breadcrumbs />

        <div class="card text-sm mt-3">
            <div class="card-body">
                <h5 class="mb-0">User details</h5>

                <div class="row justify-content-between mt-4">
                    <div class="col-xl-5">
                        <div class="form-group row">
                            <label for="first-name" class="col-sm-4 col-form-label">Title <span class="text-danger">*</span></label>
                            <div class="col-sm-8">
                                <select type="text" class="custom-select" :class="{'border border-danger' : errors && errors.title}" id="title" v-model="form.title">
                                    <option :value="Salutation.MR">Mr</option>
                                    <option :value="Salutation.MRS">Mrs</option>
                                    <option :value="Salutation.MISS">Miss</option>
                                    <option :value="Salutation.MS">Ms</option>
                                    <option :value="Salutation.DR">Dr</option>
                                    <option :value="Salutation.SIR">Sir</option>
                                    <option :value="Salutation.PROFESSOR">Professor</option>
                                </select>
                                <small v-if="errors && errors.title" class="text-danger">
                                    {{ errors.title[0] }}
                                </small>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="first-name" class="col-sm-4 col-form-label">First Name <span class="text-danger">*</span></label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" v-model="form.firstName">
                                <small v-if="errors && errors.firstName" class="text-danger">
                                    {{ errors.firstName[0] }}
                                </small>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="middle-name" class="col-sm-4 col-form-label">Middle Name</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" v-model="form.middleName">
                                <small v-if="errors && errors.middleName" class="text-danger">
                                    {{ errors.middleName[0] }}
                                </small>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="surname" class="col-sm-4 col-form-label">Surname <span class="text-danger">*</span></label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" v-model="form.lastName">
                                <small v-if="errors && errors.lastName" class="text-danger">
                                    {{ errors.lastName[0] }}
                                </small>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="email" class="col-sm-4 col-form-label">Email address <span class="text-danger">*</span></label>
                            <div class="col-sm-8">
                                <input type="email" class="form-control" v-model="form.email">
                                <small v-if="errors && errors.email" class="text-danger">
                                    {{ errors.email[0] }}
                                </small>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="role" class="col-sm-4 col-form-label">Role <span class="text-danger">*</span></label>
                            <div class="col-sm-8">
                                <select type="text" class="custom-select" v-model="form.role">
                                    <option :value="RoleType.ADMIN">Admin</option>
                                    <option :value="RoleType.OWNER">Owner</option>
                                    <option :value="RoleType.FULFILMENT">Fulfilment</option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group row align-items-center">
                            <label for="fulfilment-provider" class="col-sm-4 col-form-label">Status <span class="text-danger">*</span></label>
                            <div class="col-sm-8">
                                <b-form-checkbox v-model="form.statusChecked" name="check-button" switch class="custom-switch-md">
                                    {{ form.statusChecked ? 'Active' : 'Inactive' }}
                                </b-form-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="fixed-bottom bg-white text-right center-mobile p-3">
            <button :disabled="loading" @click="cancel()" class="btn btn-cancel btn-lg lg-shadow" :class="{disabled : loading}">Cancel</button>
            <button :disabled="loading" @click="create()" class="btn btn-success btn-lg lg-shadow ml-2 px-55" :class="{disabled : loading}">Create <span v-if="loading" class="btn-loading"></span></button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import Breadcrumbs from '@/partials/site-structure/BreadcrumbsComponent'
import Salutation from '@/enums/Salutation'
import RoleType from '@/enums/RoleType'
export default {
    name: 'createUser',

    components: {
        Breadcrumbs
    },

    data () {
        return {
            loading: false,
            form: {
                statusChecked: true,
                middleName: '',
                role: 0
            },
            errors: {},
            Salutation,
            RoleType
        }
    },

    computed: {
        ...mapGetters({
            userCurrentDomain: 'UserStore/GetUserCurrentDomain'
        })
    },

    methods: {
        ...mapActions({
            setNotification: 'NotificationStore/SetNotification'
        }),

        cancel () {
            return this.$router.push({ name: 'Users' })
        },

        async create () {
            this.checkForm()

            if (!Object.keys(this.errors).length) {
                this.loading = true

                const userModel = {
                    role: this.form.role,
                    title: this.form.title,
                    firstName: this.form.firstName,
                    middleName: this.form.middleName,
                    lastName: this.form.lastName,
                    email: this.form.email,
                    domainId: this.userCurrentDomain
                }

                try {
                    await axios.post(`${process.env.VUE_APP_AXIOS_ADMIN_BASE_URL}/api/v1/admins`, userModel)

                    this.$router.push({ name: 'Users' })

                    return this.setNotification({
                        type: 'success',
                        title: 'Success',
                        message: 'The user was created.'
                    })
                } catch {
                    this.setNotification({
                        type: 'error',
                        title: 'Error',
                        message: 'Something went wrong. The user was not created.'
                    })
                } finally {
                    this.loading = false
                }
            }
        },

        checkForm () {
            this.errors = {}

            if (this.form.title == null || parseInt(this.form.title) < 0) {
                this.errors.title = ['The title field is required']
            }

            if (!this.form.firstName) {
                this.errors.firstName = ['The first name field is required']
            }

            if (!this.form.lastName) {
                this.errors.lastName = ['The last name field is required']
            }

            if (!this.form.email) {
                this.errors.email = ['The email field is required']
            }
        }
    }
}
</script>
