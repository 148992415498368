<template>
    <div>
        <Loader v-if="loading" />

        <div v-else>
            <div class="row mb-4">
                <div class="col-md-5">
                    <Breadcrumbs :dynamicTitle="`Order #${order.orderNumber}`" :orderStatus="order.status" :orderCancelled="order.cancelled" :futureOrder="order.futureOrder"/>
                </div>

                <div v-if="order.status != OrderStatus.DISPATCHED && !order.cancelled" class="col-md-7 text-right center-mobile">
                    <div class="d-flex flex-column flex-md-row justify-content-end align-items-center">
                        <button v-if="order.status != OrderStatus.CANCELLED" @click="cancelOrder()" class="btn btn-danger btn-block-mobile btn-lg lg-shadow mt-2 mt-md-0">Cancel order</button>

                        <ToggleScanningComponent
                            v-if="(!order.deviceNumber && !order.serialNumber) && order.status != OrderStatus.OPEN"
                            buttonTitle="Assign device"
                            :orderId="order.id"
                            :orderNumber="order.orderNumber"
                            :uniqueCode="ScanningCodeType.ASD"
                        />

                        <ToggleScanningComponent
                            v-if="(order.deviceNumber || order.serialNumber)"
                            buttonTitle="Reassign device"
                            :orderId="order.id"
                            :orderNumber="order.orderNumber"
                            :uniqueCode="ScanningCodeType.ASD"
                            :reassign="true"
                        />

                        <ToggleScanningComponent
                            v-if="(order.deviceNumber || order.serialNumber) && order.status == OrderStatus.QA && !order.verified"
                            buttonTitle="Verify device"
                            :orderId="order.id"
                            :orderNumber="order.orderNumber"
                            :uniqueCode="ScanningCodeType.QAS"
                            :verifyScan="true"
                            @deviceVerified="deviceVerified"
                        />

                        <button v-if="(order.deviceNumber || order.serialNumber)" @click="removeDevice()" class="btn btn-remove-device btn-lg lg-shadow ml-0 ml-md-3 mt-2 mt-md-0">Remove Device</button>
                    </div>
                </div>
            </div>

            <div class="card mt-3">
                <div class="card-body">
                    <div class="d-flex flex-column flex-md-row justify-content-between">
                        <h5 class="mb-0">Patient details</h5>
                    </div>

                    <div class="row mt-4">
                        <div class="col-lg-6">
                            <div class="row">
                                <div class="col-sm-4 font-weight-medium">Name</div>
                                <div class="col-sm-8">{{ order.patientName }}</div>
                            </div>

                            <hr>

                            <div class="row">
                                <div class="col-sm-4 font-weight-medium">Phone number</div>
                                <div class="col-sm-8">{{ order.patientPhoneNumber }}</div>
                            </div>

                            <hr>

                            <div class="row">
                                <div class="col-sm-4 font-weight-medium">Email</div>
                                <div class="col-sm-8">{{ order.patientEmailAddress }}</div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div v-if="order.alternativeLanguage != null">
                <div class="card mt-3">
                    <div class="card-body table-warning">
                        <div class="d-flex flex-column flex-md-row justify-content-between">
                            <h5 class="mb-0">Alternative Language</h5>
                        </div>

                        <div class="row mt-4">
                            <div class="col-lg-6">
                                <div class="row">
                                    <div class="col-sm-4 font-weight-medium">Code</div>
                                    <div class="col-sm-8">{{ languageCode }}</div>
                                </div>
                                <hr>
                                <div class="row">
                                    <div class="col-sm-4 font-weight-medium">Name</div>
                                    <div class="col-sm-8">{{ alternativeLanguages.find(lang=>lang.code === order.alternativeLanguage)?.name ?? "" }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mt-3">
                <div class="card-body">
                    <div class="d-flex flex-column flex-md-row justify-content-between">
                        <h5 class="mb-0">General information</h5>
                    </div>

                    <div class="row mt-4">
                        <div class="col-lg-6">
                            <div class="row">
                                <div class="col-sm-4 font-weight-medium">HCP</div>
                                <div class="col-sm-8">{{ order.practitionerName }}</div>
                            </div>

                            <hr>

                            <div class="row">
                                <div class="col-sm-4 font-weight-medium">Facility</div>
                                <div class="col-sm-8">{{ order.facilityName }}</div>
                            </div>

                            <hr>

                            <div class="row">
                                <div class="col-sm-4 font-weight-medium">Organisation</div>
                                <div class="col-sm-8">{{ order.organisationName ? order.organisationName : 'Unknown' }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mt-3">
                <div class="card-body">
                    <div class="d-flex flex-column flex-md-row justify-content-between">
                        <h5 class="mb-0">Device information</h5>
                    </div>

                    <div class="row mt-4">
                        <div class="col-lg-6">
                            <div class="row">
                                <div class="col-sm-4 font-weight-medium">Device status</div>
                                <div class="col-sm-8">{{ order.deviceNumber || order.serialNumber ? 'Assigned' : 'Unassigned' }}</div>
                            </div>

                            <div v-if="order.deviceNumber || order.serialNumber">
                                <hr>

                                <div class="row">
                                    <div class="col-sm-4 font-weight-medium">Device serial number</div>
                                    <div class="col-sm-8">{{ order.serialNumber }}</div>
                                </div>

                                <div v-if="order.deviceAssignedDate">
                                    <hr>

                                    <div class="row">
                                        <div class="col-sm-4 font-weight-medium">Date assigned</div>
                                        <div class="col-sm-8">{{ order.deviceAssignedDate | moment("DD/MM/YYYY HH:mm") }}</div>
                                    </div>
                                </div>

                                <div v-if="order.deviceAssignedUserName">
                                    <hr>

                                    <div class="row">
                                        <div class="col-sm-4 font-weight-medium">Assigned by</div>
                                        <div class="col-sm-8">{{ order.deviceAssignedUserName }}</div>
                                    </div>
                                </div>

                                <div v-if="order.verifiedDate">
                                    <hr>

                                    <div class="row">
                                        <div class="col-sm-4 font-weight-medium">Verified date</div>
                                        <div class="col-sm-8">{{ order.verifiedDate | moment("DD/MM/YYYY HH:mm") }}</div>
                                    </div>
                                </div>

                                <div v-if="order.verifiedUserName">
                                    <hr>

                                    <div class="row">
                                        <div class="col-sm-4 font-weight-medium">Verified username</div>
                                        <div class="col-sm-8">{{ order.verifiedUserName }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mt-3">
                <div class="card-body">
                    <div class="d-flex flex-column flex-md-row justify-content-between">
                        <h5 class="mb-0">Order information</h5>
                    </div>

                    <div class="row mt-4">
                        <div class="col-lg-6">
                            <div class="row">
                                <div class="col-sm-4 font-weight-medium">Order number</div>
                                <div class="col-sm-8">{{ order.orderNumber }}</div>
                            </div>

                            <hr>

                            <div class="row">
                                <div class="col-sm-4 font-weight-medium">Order status</div>
                                <div class="col-sm-8">
                                    <OrderStatusBadgesComponent :status="order.status" :cancelled="order.cancelled" :future="order.futureOrder" />
                                </div>
                            </div>

                            <hr>

                            <div class="row">
                                <div class="col-sm-4 font-weight-medium">Order date</div>
                                <div class="col-sm-8">{{ formatDate(order.dateCreated) }}</div>
                            </div>

                            <hr>

                            <div class="row">
                                <div class="col-sm-4 font-weight-medium">Test date</div>
                                <div class="col-sm-8">{{ formatDate(order.dueDate) }}</div>
                            </div>

                            <div v-if="order.issuedDate">
                                <hr>

                                <div class="row">
                                    <div class="col-sm-4 font-weight-medium">Date issued</div>
                                    <div class="col-sm-8">{{ order.issuedDate | moment("DD/MM/YYYY HH:mm") }}</div>
                                </div>
                            </div>

                            <div v-if="order.issuedUserName">
                                <hr>

                                <div class="row">
                                    <div class="col-sm-4 font-weight-medium">Issued by</div>
                                    <div class="col-sm-8">{{ order.issuedUserName }}</div>
                                </div>
                            </div>

                            <hr>

                            <div class="row">
                                <div class="col-sm-4 font-weight-medium">Shipping address</div>
                                <div class="col-sm-8">
                                    <span v-if="order.shippingAddress.line">{{ order.shippingAddress.line }}, </span>
                                    <span v-if="order.shippingAddress.line2">{{ order.shippingAddress.line2 }}, </span>
                                    <span v-if="order.shippingAddress.line3">{{ order.shippingAddress.line3 }}, </span>
                                    <span v-if="order.shippingAddress.city">{{ order.shippingAddress.city }}, </span>
                                    <span v-if="order.shippingAddress.district">{{ order.shippingAddress.district }}, </span>
                                    <span v-if="order.shippingAddress.state">{{ order.shippingAddress.state }}, </span>
                                    <span v-if="order.shippingAddress.postalCode">{{ order.shippingAddress.postalCode }}, </span>
                                    <span v-if="order.shippingAddress.country">{{ order.shippingAddress.country }}, </span>
                                    <span v-if="order.shippingAddress.countryCode">{{ order.shippingAddress.countryCode }}</span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="card mt-3" v-if="orderEvents.length">
                <div class="card-body">
                    <h5 class="mb-0">Order Interactions</h5>

                    <b-table class="mt-3" responsive borderless outlined :items="orderEvents" :fields="filteredFields">
                        <template #cell(dateCreated)="data">
                            {{ data.item.dateCreated | moment('DD/MM/YYYY HH:mm') }}
                        </template>

                        <template #cell(type)="data">
                            <span v-if="data.item.type === OrderEventType.CREATED">Created</span>
                            <span v-if="data.item.type === OrderEventType.DEVICE_ASSIGNED">Device assigned</span>
                            <span v-if="data.item.type === OrderEventType.DEVICE_REMOVED">Device removed</span>
                            <span v-if="data.item.type === OrderEventType.DEVICE_REASSIGNED">Device re-assigned</span>
                            <span v-if="data.item.type === OrderEventType.LABEL_PRINTED">Label printed</span>
                            <span v-if="data.item.type === OrderEventType.STATUS_PACKING">Status packing</span>
                            <span v-if="data.item.type === OrderEventType.STATUS_QA">Status QA</span>
                            <span v-if="data.item.type === OrderEventType.STATUS_PASSED_QA">Status passed QA</span>
                            <span v-if="data.item.type === OrderEventType.STATUS_DISPATCHED">Status dispatched</span>
                            <span v-if="data.item.type === OrderEventType.CANCELLED">Status cancelled</span>
                            <span v-if="data.item.type === OrderEventType.ON_HOLD">On hold</span>
                            <span v-if="data.item.type === OrderEventType.OFF_HOLD">Off hold</span>
                            <span v-if="data.item.type === OrderEventType.NOTE_ADDED">Note added</span>
                            <span v-if="data.item.type === OrderEventType.ARCHIVED">Archived</span>
                            <span v-if="data.item.type === OrderEventType.VALIDATED">Verified</span>
                            <span v-if="data.item.type === OrderEventType.OPEN">Status open</span>
                        </template>
                    </b-table>
                </div>
            </div>

            <OrderNotes
                v-if="orderNotes.results.length"
                :notes="orderNotes.results"
                :orderId="$route.params.id"
                v-on:reload="getOrderNotes()"
            />
        </div>

        <div v-if="order.status != OrderStatus.DISPATCHED && !order.cancelled" class="fixed-bottom bg-white text-right p-3">
            <button v-if="order.status == OrderStatus.OPEN" :disabled="loading" @click="createLabelAndMove()" class="btn btn-yellow btn-lg lg-shadow ml-2" :class="{disabled : loading}">Create Royal Mail Order <span v-if="loading" class="btn-loading"></span></button>
            <button v-if="order.status == OrderStatus.PACKING" :disabled="loading || (!order.deviceNumber && !order.serialNumber)" @click="moveOrder('QA')" class="btn btn-success btn-lg lg-shadow ml-2" :class="{disabled : loading || (!order.deviceNumber && !order.serialNumber)}">Move to Qa <span v-if="loading" class="btn-loading"></span></button>
            <button v-if="order.status == OrderStatus.QA" :disabled="loading || !order.verified" @click="moveOrder('PASSED_QA')" class="btn btn-success btn-lg lg-shadow ml-2" :class="{disabled : loading || !order.verified}">Move to Passed Qa <span v-if="loading" class="btn-loading"></span></button>
            <button v-if="order.status == OrderStatus.PASSED_QA" :disabled="loading" @click="moveOrder('DISPATCHED')" class="btn btn-success btn-lg lg-shadow ml-2" :class="{disabled : loading}">Move to Dispatched <span v-if="loading" class="btn-loading"></span></button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import Loader from '@/partials/Loader'
import Breadcrumbs from '@/partials/site-structure/BreadcrumbsComponent'
import ToggleScanningComponent from '@/components/ToggleScanningComponent'
import CancelOrderModalComponent from '@/components/modals/CancelOrderModalComponent'
import RemoveDeviceModalComponent from '@/components/modals/RemoveDeviceModalComponent'
import OrderStatusBadgesComponent from '@/components/OrderStatusBadgesComponent'
import OrderNotes from '@/components/OrderNotesComponent'
import FormatterMixin from '@/mixins/FormatterMixin'
import OrderStatus from '@/enums/OrderStatus'
import ScanningCodeType from '@/enums/ScanningCodeType'
import OrderEventType from '@/enums/OrderEventType'
export default {
    name: 'ViewOrder',

    components: {
        Loader,
        Breadcrumbs,
        ToggleScanningComponent,
        OrderNotes,
        OrderStatusBadgesComponent
    },

    mixins: [
        FormatterMixin
    ],

    data () {
        return {
            loading: true,
            order: {},
            orderNotes: {},
            orderEvents: [],
            CancelOrderModalComponent,
            OrderStatus,
            ScanningCodeType,
            OrderEventType
        }
    },

    watch: {
        shouldRefreshOrder: function (val) {
            if (val === true) {
                this.getOrder()
                this.getStatusCountsAction()
                this.getOrderEvents()
                this.setShouldRefreshOrder(false)
            }
        },
        verifyOrder: function (val) {
            if (val != null) {
                this.deviceVerified()
                this.setVerifyOrderNumber(null)
            }
        }
    },

    async mounted () {
        try {
            await this.getOrder()
            await this.getOrderNotes()
            await this.getOrderEvents()
        } catch {
            this.setNotification({
                type: 'error',
                title: 'Error',
                message: 'Something went wrong while fetching the data. Please try again.'
            })
        } finally {
            this.loading = false
        }
    },

    computed: {
        ...mapGetters({
            shouldRefreshOrder: 'AppStore/GetShouldRefreshOrder',
            verifyOrder: 'AppStore/GetVerifyOrder',
            alternativeLanguages: 'LanguageStore/GetLanguages'
        }),

        filteredFields () {
            return [
                {
                    key: 'userName',
                    label: 'User Name'
                },
                {
                    key: 'dateCreated',
                    label: 'Date'
                },
                {
                    key: 'type',
                    label: 'Interaction Type'
                }
            ]
        },

        languageCode () {
            var languageData = this.alternativeLanguages.find(lang => lang.code === this.order.alternativeLanguage)
            if (languageData != null) {
                return this.formatLanguageCode(languageData)
            }
            return ''
        }
    },

    methods: {
        ...mapActions({
            getStatusCountsAction: 'FulfilmentStatusCountsStore/GetStatusCounts',
            setShouldRefreshOrder: 'AppStore/SetShouldRefreshOrder',
            setNotification: 'NotificationStore/SetNotification',
            setVerifyOrderNumber: 'AppStore/SetVerifyOrder'
        }),

        async getOrder () {
            try {
                const response = await axios.get(`${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/orders/${this.$route.params.id}`)

                this.order = response.data
            } catch {
                throw Error()
            }
        },

        async getOrderNotes () {
            try {
                const response = await axios.get(`${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/ordernotes/${this.$route.params.id}/100/0`)

                this.orderNotes = response.data
            } catch {
                throw Error()
            }
        },

        async getOrderEvents () {
            try {
                const response = await axios.get(`${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/orderevents/${this.$route.params.id}/all`)

                this.orderEvents = response.data
            } catch {
                throw Error()
            }
        },

        async cancelOrder () {
            this.$modal.show(CancelOrderModalComponent, { id: this.order.id }, { width: '400px', classes: 'border border-danger px-4 py-5' })
        },

        removeDevice () {
            this.$modal.show(RemoveDeviceModalComponent, { id: this.order.id, deviceNumber: this.order.deviceNumber, serialNumber: this.order.serialNumber, orderNumber: this.order.orderNumber }, { width: '400px', classes: 'border border-danger px-4 py-5' })
        },

        async moveOrder (status) {
            this.loading = true

            const order = []
            order.push(this.order.id)

            try {
                await axios.post(`${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/orders/status?status=${status}`, order)
                await this.getStatusCountsAction()
                await this.getOrder()
                await this.getOrderEvents()
            } catch {
                throw Error('Something went wrong while updating the order status.')
            } finally {
                this.loading = false
            }
        },

        deviceVerified () {
            this.setNotification({
                type: 'success',
                title: 'Device verified!',
                message: 'The device was verified successfully'
            })

            this.getOrder()
        },

        async createLabelAndMove () {
            this.loading = true

            const order = []
            order.push(this.order.id)

            try {
                await axios.post(`${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/orders/label`, order)
                await this.moveOrder('PACKING')
                await this.getStatusCountsAction()
                await this.getOrder()
                await this.getOrderEvents()
            } catch {
                throw Error('Could not create labels')
            }
        }
    }
}
</script>
