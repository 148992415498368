<template>
    <div class="media breadcrumb-container">
        <svg v-if="!hideBackButton" @click="$router.go(-1)" class="back-button mr-2 mt-1" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 37 37">
            <path id="Path_5986" data-name="Path 5986" d="M10.484,39.5H31.516A8,8,0,0,0,39.5,31.516V10.484A8,8,0,0,0,31.516,2.5H10.484A8,8,0,0,0,2.5,10.484V31.516A8,8,0,0,0,10.484,39.5Zm.74-19.98,6-6A2.1,2.1,0,0,1,18.7,12.9a1.973,1.973,0,0,1,1.48.623,2.139,2.139,0,0,1,0,3L17.806,18.9H29.257a2.1,2.1,0,1,1,0,4.206H17.845l2.376,2.376a2.121,2.121,0,0,1-3,3l-6-6A2.092,2.092,0,0,1,11.224,19.52Z" transform="translate(-2.5 -2.5)"/>
        </svg>

        <div class="media-body">
            <ul class="d-flex list-unstyled align-items-center mb-0">
                <li v-if="$route.meta.breadcrumbTitle" class="h2 font-weight-medium">{{ $route.meta.breadcrumbTitle }}</li>
                <li v-if="dynamicTitle" class="h2 font-weight-medium">{{ dynamicTitle }}</li>
                <li v-if="testNumber" class="h2 font-weight-medium ml-1">{{ testNumber }}</li>
                <li class="h2 font-weight-medium ml-2">
                    <testStatuses v-if="testStatus != null" :status="testStatus" />
                    <OrderStatuses v-if="orderStatus != null" :status="orderStatus" :cancelled="orderCancelled" :future="futureOrder"/>
                </li>
            </ul>

            <nav aria-label="breadcrumb">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item" v-for="(breadcrumb, index) in $route.meta.breadcrumbs" :key="index"><router-link class="color-inherit" :to="{name: breadcrumb.link}">{{ breadcrumb.title }}</router-link></li>
                    <li v-if="$route.meta.breadcrumbTitle" class="breadcrumb-item active">{{ $route.meta.breadcrumbTitle }}</li>
                    <li v-if="dynamicTitle" class="breadcrumb-item active">{{ dynamicTitle }}</li>
                </ol>
            </nav>
        </div>
    </div>
</template>

<script>
import TestStatuses from '@/components/TestStatusesComponent.vue'
import OrderStatuses from '@/components/OrderStatusBadgesComponent.vue'
export default {
    name: 'BreadcrumbsComponent',

    components: {
        TestStatuses,
        OrderStatuses
    },

    props: {
        hideBackButton: Boolean(false),
        testNumber: Number,
        testStatus: Number,
        orderStatus: Number,
        dynamicTitle: String,
        orderCancelled: Boolean,
        futureOrder: Boolean
    }
}
</script>
