<template>
    <div>
        <Loader v-if="loading" />

        <div v-if="!loading && Object.keys(collectionPoint).length">
            <Breadcrumbs />

            <div class="card text-sm mt-3">
                <div class="card-body">
                    <h5 class="mb-0">Collection point details</h5>

                    <div class="row mt-4">
                        <div class="col-xl-5">
                            <div class="form-group row">
                                <label for="name" class="col-sm-4 col-form-label">Name <span class="text-danger">*</span></label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" v-model="collectionPoint.name">
                                    <small v-if="errors && errors.collectionPointName" class="text-danger">
                                        {{ errors.collectionPointName[0] }}
                                    </small>
                                </div>
                            </div>

                            <div v-if="collectionPoint.countryCode == 'GB'" class="form-group row">
                                <label for="autocomplete" class="col-sm-4 col-form-label">Find Address</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" @input="findAddress()" v-model="addressQueryString" placeholder="Search for address" autocomplete="new-password" />
                                    <ul v-if="autocompleteSuggestions.length" class="list-group autocomplete-suggestions">
                                        <li v-for="(suggestion, index) in autocompleteSuggestions" :key="index" class="list-group-item" @click="chooseSuggestion(suggestion.id)">{{ suggestion.address }}</li>
                                    </ul>
                                </div>
                            </div>

                            <div v-if="expandManualAddress">
                                <div class="form-group row">
                                    <label for="address1" class="col-sm-4 col-form-label">Address 1 <span class="text-danger">*</span></label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" :class="{'border border-danger' : errors && errors.collectionPointAddress1}" id="address1" v-model="collectionPoint.address1">
                                        <small v-if="errors && errors.collectionPointAddress1" class="text-danger">
                                            {{ errors.collectionPointAddress1[0] }}
                                        </small>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="address2" class="col-sm-4 col-form-label">Address 2 <span class="text-danger">*</span></label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" :class="{'border border-danger' : errors && errors.collectionPointAddress2}" id="address2" v-model="collectionPoint.address2">
                                        <small v-if="errors && errors.collectionPointAddress2" class="text-danger">
                                            {{ errors.collectionPointAddress2[0] }}
                                        </small>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="address3" class="col-sm-4 col-form-label">Address 3</label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" :class="{'border border-danger' : errors && errors.collectionPointAddress3}" id="address3" v-model="collectionPoint.address3">
                                        <small v-if="errors && errors.collectionPointAddress3" class="text-danger">
                                            {{ errors.collectionPointAddress3[0] }}
                                        </small>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label v-if="collectionPoint.countryCode == 'GB'" for="address4" class="col-sm-4 col-form-label">Address 4</label>
                                    <label v-if="collectionPoint.countryCode == 'US'" for="address4" class="col-sm-4 col-form-label">State</label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" :class="{'border border-danger' : errors && errors.collectionPointAddress4}" id="address4" v-model="collectionPoint.address4">
                                        <small v-if="errors && errors.collectionPointAddress4" class="text-danger">
                                            {{ errors.collectionPointAddress4[0] }}
                                        </small>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label v-if="collectionPoint.countryCode == 'GB'"  for="postalCode" class="col-sm-4 col-form-label">Postcode <span class="text-danger">*</span></label>
                                    <label v-if="collectionPoint.countryCode == 'US'"  for="postalCode" class="col-sm-4 col-form-label">Zip Code <span class="text-danger">*</span></label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" :class="{'border border-danger' : errors && errors.collectionPointPostalCode}" id="postalCode" v-model="collectionPoint.postalCode">
                                        <small v-if="errors && errors.collectionPointPostalCode" class="text-danger">
                                            {{ errors.collectionPointPostalCode[0] }}
                                        </small>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="country" class="col-sm-4 col-form-label">Country <span class="text-danger">*</span></label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" :class="{'border border-danger' : errors && errors.collectionPointCountry}" id="country" v-model="collectionPoint.country">
                                        <small v-if="errors && errors.collectionPointCountry" class="text-danger">
                                            {{ errors.collectionPointCountry[0] }}
                                        </small>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="country-code" class="col-sm-4 col-form-label">Country Code <span class="text-danger">*</span></label>
                                    <div class="col-sm-8">
                                        <input disabled type="text" class="form-control" :class="{'border border-danger' : errors && errors.collectionPointCountryCode}" id="country-code" v-model="collectionPoint.countryCode">
                                        <small v-if="errors && errors.collectionPointCountryCode" class="text-danger">
                                            {{ errors.collectionPointCountryCode[0] }}
                                        </small>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="fulfilment-provider" class="col-sm-4 col-form-label">Type <span class="text-danger">*</span></label>
                                <div class="col-sm-8">
                                    <select type="text" class="custom-select" v-model="collectionPoint.type">
                                        <option :value="CollectionPointType.PHARMACY">Pharmacy</option>
                                        <option :value="CollectionPointType.PLACE_OF_WORSHIP">Place of worship</option>
                                        <option :value="CollectionPointType.RETAIL">Retail</option>
                                        <option :value="CollectionPointType.COMMUNITY_CENTRE">Community centre</option>
                                    </select>
                                    <small v-if="errors && errors.collectionPointType" class="text-danger">
                                        {{ errors.collectionPointType[0] }}
                                    </small>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="website-address" class="col-sm-4 col-form-label">Website address</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" v-model="collectionPoint.websiteAddress">
                                    <small v-if="errors && errors.collectionPointWebsiteAddress" class="text-danger">
                                        {{ errors.collectionPointWebsiteAddress[0] }}
                                    </small>
                                </div>
                            </div>

                            <div class="form-group row align-items-center">
                                <label for="status" class="col-sm-4 col-form-label">Status <span class="text-danger">*</span></label>
                                <div class="col-sm-8">
                                    <b-form-checkbox v-model="collectionPoint.isActive" name="check-button" switch class="custom-switch-md">
                                        {{ collectionPoint.isActive ? 'Active' : 'Inactive' }}
                                    </b-form-checkbox>
                                    <small v-if="errors && errors.collectionPointStatusChecked" class="text-danger">
                                        {{ errors.collectionPointStatusChecked[0] }}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h5 class="my-4">Primary contact</h5>

                    <div class="row mt-4">
                        <div class="col-xl-5">
                            <div class="form-group row">
                                <label for="title" class="col-sm-4 col-form-label">Title <span class="text-danger">*</span></label>
                                <div class="col-sm-8">
                                    <select type="text" class="custom-select" v-model="collectionPoint.title">
                                        <option :value="Salutation.MR">Mr</option>
                                        <option :value="Salutation.MRS">Mrs</option>
                                        <option :value="Salutation.MISS">Miss</option>
                                        <option :value="Salutation.MS">Ms</option>
                                        <option :value="Salutation.DR">Dr</option>
                                        <option :value="Salutation.SIR">Sir</option>
                                        <option :value="Salutation.PROFESSOR">Professor</option>
                                    </select>
                                    <small v-if="errors && errors.title" class="text-danger">
                                        {{ errors.title[0] }}
                                    </small>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="address" class="col-sm-4 col-form-label">First name <span class="text-danger">*</span></label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" v-model="collectionPoint.firstName">
                                    <small v-if="errors && errors.firstName" class="text-danger">
                                        {{ errors.firstName[0] }}
                                    </small>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="address" class="col-sm-4 col-form-label">Surname <span class="text-danger">*</span></label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" v-model="collectionPoint.surname">
                                    <small v-if="errors && errors.surname" class="text-danger">
                                        {{ errors.surname[0] }}
                                    </small>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="address" class="col-sm-4 col-form-label">Phone number <span class="text-danger">*</span></label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control" v-model="collectionPoint.phone">
                                    <small v-if="errors && errors.phone" class="text-danger">
                                        {{ errors.phone[0] }}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="fixed-bottom bg-white text-right center-mobile p-3">
                <button :disabled="loading" @click="cancel()" class="btn btn-cancel btn-lg lg-shadow" :class="{disabled : loading}">Cancel</button>
                <button :disabled="loading" @click="save()" class="btn btn-success btn-lg lg-shadow px-55 ml-2" :class="{disabled : loading}">Save <span v-if="loading" class="btn-loading"></span></button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import Breadcrumbs from '@/partials/site-structure/BreadcrumbsComponent'
import axios from 'axios'
import Loader from '@/partials/Loader'
import Salutation from '@/enums/Salutation'
import CollectionPointType from '@/enums/CollectionPointType'
export default {
    name: 'EditCollectionPoint',

    components: {
        Loader,
        Breadcrumbs
    },

    data () {
        return {
            loading: true,
            addressQueryString: null,
            autocompleteSuggestions: [],
            expandManualAddress: true,
            collectionPoint: {},
            errors: {},
            Salutation,
            CollectionPointType
        }
    },

    async mounted () {
        await this.getCollectionPoint()
    },

    methods: {
        ...mapActions({
            setNotification: 'NotificationStore/SetNotification'
        }),

        cancel () {
            return this.$router.push({ name: 'CollectionPoints' })
        },

        async findAddress () {
            const response = await axios.get(`https://api.getAddress.io/autocomplete/${this.addressQueryString}?api-key=${process.env.VUE_APP_GET_ADDRESS_API_KEY}`)

            this.autocompleteSuggestions = response.data.suggestions
        },

        async chooseSuggestion (id) {
            this.clearAddress()

            const response = await axios.get(`https://api.getAddress.io/get/${id}?api-key=${process.env.VUE_APP_GET_ADDRESS_API_KEY}`)

            if (response.data) {
                this.autocompleteSuggestions = []
                this.addressQueryString = null
                this.expandManualUkAddress = true
                this.collectionPoint.address1 = response.data.line_1
                this.collectionPoint.address2 = response.data.town_or_city
                this.collectionPoint.address3 = response.data.county
                this.collectionPoint.postalCode = response.data.postcode
                this.collectionPoint.country = response.data.country
                this.collectionPoint.latitude = response.data.latitude
                this.collectionPoint.longitude = response.data.longitude
            }
        },

        clearAddress () {
            this.fullAddress = null
            this.collectionPoint.address1 = null
            this.collectionPoint.address2 = null
            this.collectionPoint.address3 = null
            this.collectionPoint.postalCode = null
            this.collectionPoint.country = null
            this.collectionPoint.latitude = null
            this.collectionPoint.longitude = null
        },

        async getCollectionPoint () {
            try {
                const response = await axios.get(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/collectionpoints/${this.$route.params.id}`)

                this.collectionPoint = response.data
            } catch {
                this.setNotification({
                    type: 'error',
                    title: 'Error',
                    message: 'Something went while fetching the collection point. Please try again.'
                })
            } finally {
                this.loading = false
            }
        },

        async save () {
            this.expandManualAddress = true
            this.checkForm()

            if (!Object.keys(this.errors).length) {
                this.loading = true

                try {
                    const collectionPointModel = {
                        name: this.collectionPoint.name,
                        type: this.collectionPoint.type,
                        isActive: this.collectionPoint.isActive,
                        websiteAddress: this.collectionPoint.websiteAddress,
                        address1: this.collectionPoint.address1,
                        address2: this.collectionPoint.address2,
                        address3: this.collectionPoint.address3,
                        address4: this.collectionPoint.address4,
                        postalCode: this.collectionPoint.postalCode,
                        country: this.collectionPoint.country,
                        countryCode: this.collectionPoint.countryCode,
                        latitude: this.collectionPoint.latitude,
                        longitude: this.collectionPoint.longitude,
                        title: this.collectionPoint.title,
                        firstName: this.collectionPoint.firstName,
                        surname: this.collectionPoint.surname,
                        phone: this.collectionPoint.phone
                    }

                    await axios.patch(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/collectionpoints/${this.$route.params.id}`, collectionPointModel)

                    this.setNotification({
                        type: 'success',
                        title: 'Success',
                        message: 'The collection point was updated.'
                    })

                    return this.$router.push({ name: 'CollectionPoints' })
                } catch (err) {
                    this.setNotification({
                        type: 'error',
                        title: 'Error',
                        message: 'Something went wrong. The collection point was not updated.'
                    })
                } finally {
                    this.loading = false
                }
            }
        },

        checkForm () {
            this.errors = {}

            if (!this.collectionPoint.name) {
                this.errors.collectionPointName = ['The collection point name field is required']
            }

            if (!this.collectionPoint.address1) {
                this.errors.collectionPointAddress1 = ['The collection point address 1 field is required']
            }

            if (!this.collectionPoint.address2) {
                this.errors.collectionPointAddress2 = ['The collection point address 2 field is required']
            }

            if (!this.collectionPoint.postalCode) {
                this.errors.collectionPointPostalCode = ['The collection point postal code field is required']
            }

            if (!this.collectionPoint.country) {
                this.errors.collectionPointCountry = ['The collection point country field is required']
            }

            if (!this.collectionPoint.countryCode) {
                this.errors.collectionPointCountryCode = ['The collection point country code field is required']
            }

            if (this.collectionPoint.type == null || parseInt(this.collectionPoint.type) < 0) {
                this.errors.collectionPointType = ['Collection point type field is required']
            }

            if (this.collectionPoint.title == null || parseInt(this.collectionPoint.title) < 0) {
                this.errors.title = ['The primary contact title field is required']
            }

            if (!this.collectionPoint.firstName) {
                this.errors.firstName = ['The primary contact first name field is required']
            }

            if (!this.collectionPoint.surname) {
                this.errors.surname = ['The primary contact surname field is required']
            }

            if (!this.collectionPoint.phone) {
                this.errors.phone = ['The primary contact phone number field is required']
            }

            if (this.collectionPoint.phone != null && !this.collectionPoint.phone.match(/[0-9]{9,20}/)) {
                this.errors.phone = ['The phone number must be a minimum of 9 digits']
            }
        }
    }
}
</script>
