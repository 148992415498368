import Vue from 'vue'
import Store from '../store'
import RoleType from '../enums/RoleType'

const msalPlugin = {
    async signIn () {
      try {
        const msalInstance = new msal.PublicClientApplication(msalConfig)
        Vue.prototype.$msalInstance = msalInstance
        await msalInstance.handleRedirectPromise()
        const accounts = msalInstance.getAllAccounts()

        if (accounts.length === 0) {
            await msalInstance.loginRedirect()
        } else {
            const account = accounts[0]
            const claims = account.idTokenClaims
            if (!account || !claims || !claims.extension_Role || claims.extension_Role === 'PRACTITIONER') {
              await this.signOut()
            } else {
              Store.commit('UserStore/SET_AUTH_DETAILS', account)
              Store.commit('UserStore/SET_USER_NAME', account)
              Store.commit('UserStore/SET_CLAIMS', claims)
              Store.commit('UserStore/SET_USER_ACCOUNT', account)

              switch (claims.extension_Role) {
                case 'OWNER' :
                  Store.commit('UserStore/SET_USER_ROLE_TYPE', RoleType.OWNER)
                break
                case 'ADMIN' :
                  Store.commit('UserStore/SET_USER_ROLE_TYPE', RoleType.ADMIN)
                break
                case 'FULFILMENT' :
                  Store.commit('UserStore/SET_USER_ROLE_TYPE', RoleType.FULFILMENT)
                break
              }

              if (claims.extension_DomainIds != null) {
                Store.commit('UserStore/SET_USER_DOMAINS', claims.extension_DomainIds.split(','))
              }

              // get token
              const scopes = process.env.VUE_APP_MSAL_SCOPES
              const scopesArray = scopes.split(',')
              const accessTokenRequest = {
                scopes: scopesArray,
                account: accounts[0]
              }

              const accessTokenResponse = await msalInstance.acquireTokenSilent(accessTokenRequest)
              Store.commit('UserStore/SET_ACCESS_TOKEN', accessTokenResponse.accessToken)
              Store.commit('UserStore/SET_USER_IS_AUTHENTICATED', true)
            }
        }
      } catch (ex) {
        await this.signOut()
      }
    },

    async signOut () {
      const msalInstance = new msal.PublicClientApplication(msalConfig)
      await msalInstance.handleRedirectPromise()

      const logoutRequest = {
        postLogoutRedirectUri: msalConfig.auth.redirectUri,
        mainWindowRedirectUri: msalConfig.auth.redirectUri
      }

      msalInstance.logoutRedirect(logoutRequest)
    }
}

const knownAuthorities = []
knownAuthorities.push(process.env.VUE_APP_MSAL_KNOWN_AUTHORITY.replace(/['"]+/g, ''))

const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
    authority: process.env.VUE_APP_MSAL_LOGIN_AUTHORITY,
    knownAuthorities: knownAuthorities,
    redirectUri: process.env.VUE_APP_MSAL_REDIRECT_URI
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false
  },
  system: {
    tokenRenewalOffsetSeconds: 120,
    loggerOptions: {
      logLevel: msal.LogLevel.Error,
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case msal.LogLevel.Error:
            console.error(message)
            return
          case msal.LogLevel.Info:
            console.info(message)
            return
          case msal.LogLevel.Verbose:
            console.debug(message)
            return
          case msal.LogLevel.Warning:
            console.warn(message)
        }
      }
    }
  }
}

export default {
  install () {
    Vue.prototype.$msal = msalPlugin
  }
}
