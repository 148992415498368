import axios from 'axios'

export default {
    namespaced: true,

    state: {
        statusCounts: {},
        resultsInReviewCount: 0
    },

    getters: {
        GetStatusCounts (state) {
            return state.statusCounts
        },
        GetResultsInReviewCount (state) {
            return state.resultsInReviewCount
        }
    },

    mutations: {
        PUSH_STATUS_COUNTS (state, data) {
            state.statusCounts = data
        },
        PUSH_RESULTS_IN_REVIEW_COUNT (state, data) {
            state.resultsInReviewCount = data
        }
    },

    actions: {
        async GetStatusCounts ({ commit }) {
            try {
                const response = await axios.get(`${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/stats/orders/status`)

                commit('PUSH_STATUS_COUNTS', response.data.statusCounts)
                commit('PUSH_RESULTS_IN_REVIEW_COUNT', response.data.resultsReviewCount)
            } catch {
                throw Error()
            }
        }
    }
}
