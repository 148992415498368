<template>
    <div>
        <Breadcrumbs :dynamicTitle="`Add a HCP to ${organisation.name}`" />

        <div v-if="Object.keys(organisation).length && !organisation.dataStoreId" class="row mt-3">
            <div class="col">
                <div v-if="userIsOwner" class="alert alert-info">
                    There is no data store set for this organisation. Please edit your organisation and set a data store.
                </div>
                <div v-else class="alert alert-info">
                    There is no data store set for this organisation. Please contact your system administrator.
                </div>
            </div>
        </div>

        <div v-if="!loading && Object.keys(organisation).length && organisation.dataStoreId && results && !results.results.length">
            <div class="row mt-4">
                <div class="col">
                    <div class="alert alert-info" role="alert">
                        You must create a facility before creating a HCP
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!loading && Object.keys(organisation).length && organisation.dataStoreId && results && results.results.length">
            <div class="row mt-4">
                <div class="col">
                    <div class="card text-sm">
                        <div class="card-body">
                            <h5 class="mb-0">HCP details</h5>

                            <div class="row justify-content-between mt-4">
                                <div class="col-xl-5">
                                    <div class="form-group row">
                                        <label for="title" class="col-sm-4 col-form-label">Title <span class="text-danger">*</span></label>
                                        <div class="col-sm-8">
                                            <select type="text" class="custom-select" v-model="form.title">
                                                <option :value="Salutation.MR">Mr</option>
                                                <option :value="Salutation.MRS">Mrs</option>
                                                <option :value="Salutation.MISS">Miss</option>
                                                <option :value="Salutation.MS">Ms</option>
                                                <option :value="Salutation.DR">Dr</option>
                                                <option :value="Salutation.SIR">Sir</option>
                                                <option :value="Salutation.PROFESSOR">Professor</option>
                                            </select>
                                            <small v-if="errors && errors.title" class="text-danger">
                                                {{ errors.title[0] }}
                                            </small>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="first-name" class="col-sm-4 col-form-label">First Name <span class="text-danger">*</span></label>
                                        <div class="col-sm-8">
                                            <input type="text" class="form-control" v-model="form.firstName">
                                            <small v-if="errors && errors.firstName" class="text-danger">
                                                {{ errors.firstName[0] }}
                                            </small>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="surname" class="col-sm-4 col-form-label">Middle names</label>
                                        <div class="col-sm-8">
                                            <input type="text" class="form-control" v-model="form.middleNames">
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="surname" class="col-sm-4 col-form-label">Surname <span class="text-danger">*</span></label>
                                        <div class="col-sm-8">
                                            <input type="text" class="form-control" v-model="form.surname">
                                            <small v-if="errors && errors.surname" class="text-danger">
                                                {{ errors.surname[0] }}
                                            </small>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="email" class="col-sm-4 col-form-label">Email address <span class="text-danger">*</span></label>
                                        <div class="col-sm-8">
                                            <input type="email" class="form-control" v-model="form.email">
                                            <small v-if="errors && errors.email" class="text-danger">
                                                {{ errors.email[0] }}
                                            </small>
                                        </div>
                                    </div>

                                    <div class="form-group row align-items-center">
                                        <label for="fulfilment-provider" class="col-sm-4 col-form-label">Status <span class="text-danger">*</span></label>
                                        <div class="col-sm-8">
                                            <b-form-checkbox v-model="form.statusChecked" name="check-button" switch class="custom-switch-md">
                                                {{ form.statusChecked ? 'Active' : 'Inactive' }}
                                            </b-form-checkbox>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="title" class="col-sm-4 col-form-label">Speciality <span class="text-danger">*</span></label>
                                        <div class="col-sm-8">
                                            <select type="text" class="custom-select" v-model="form.speciality">
                                                <option :value="SpecialityType.OBSTETRICIAN">Obstetrician</option>
                                                <option :value="SpecialityType.MIDWIFE">Midwife</option>
                                                <option :value="SpecialityType.HCA">HCA</option>
                                                <option :value="SpecialityType.NURSE">Nurse</option>
                                                <option :value="SpecialityType.GP">GP</option>
                                                <option :value="SpecialityType.SCC">SCC</option>
                                                <option :value="SpecialityType.OTHER">Other</option>
                                            </select>
                                            <small v-if="errors && errors.speciality" class="text-danger">
                                                {{ errors.speciality[0] }}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-5">
                <h5>Facilities <span class="font-italic">({{ results.results.length }})</span></h5>
                <p class="text-sm">Select at least one facility to assign to your HCP</p>
                <b-table
                    ref="table"
                    id="table"
                    class="custom-table padding-lg bg-white mt-4"
                    responsive
                    no-sort-reset
                    :items="results.results"
                    :fields="filteredFields"
                >
                    <template #cell(name)="data">
                        <router-link class="font-weight-bold underlined text-capitalize" :to="{name: 'ViewFacility', params: {oId : data.item.organisationId, fId : data.item.id}}">{{ data.item.name }}</router-link>
                    </template>

                    <template #cell(action)="data">
                        <button v-if="!form.facilityIds.includes(data.item.id)" @click="selectFacility(data.item.id)" class="btn btn-primary sm-shadow text-nowrap">Select</button>
                        <button v-if="form.facilityIds.includes(data.item.id)" @click="removeFacility(data.item.id)" class="btn btn-danger sm-shadow text-nowrap">Remove</button>
                    </template>
                </b-table>

                <div v-if="errors && errors.facilities" class="mt-4 alert alert-danger text-pre-formatted">{{ errors.facilities[0] }}</div>
            </div>

            <div class="mt-5"></div>

            <div class="fixed-bottom bg-white text-right center-mobile p-3">
                <button :disabled="saving" @click="cancel()" class="btn btn-cancel btn-lg lg-shadow" :class="{disabled : saving}">Cancel</button>
                <button v-if="results.results.length" :disabled="saving" @click.stop.prevent="create()" class="btn btn-success btn-lg lg-shadow pl-55 pr-55 ml-2" :class="{disabled : saving}">Create <span v-if="saving" class="btn-loading"></span></button>
                <span v-if="Object.keys(errors).length" class="text-sm d-block text-danger mt-2">There are errors on the page. Please review them and try again</span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import Breadcrumbs from '@/partials/site-structure/BreadcrumbsComponent'
import Salutation from '@/enums/Salutation'
import SpecialityType from '@/enums/SpecialityType'
export default {
    name: 'CreateHCP',

    components: {
        Breadcrumbs
    },

    props: {
        organisation: Object
    },

    data () {
        return {
            loading: true,
            saving: false,
            results: {},
            form: {
                facilityIds: [],
                statusChecked: true
            },
            errors: {},
            Salutation,
            SpecialityType
        }
    },

    async mounted () {
        if (!this.organisation) return this.$router.push({ name: 'ViewOrganisation', params: { id: this.$route.params.oId } })

        try {
            const facilities = await axios.get(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/facilities/${this.$route.params.oId}/100/0/NAME/ASCENDING`)

            this.results = facilities.data
        } catch {
            this.setNotification({
                type: 'error',
                title: 'Error',
                message: 'Something went wrong while fetching the organisation facilities. Please try again.'
            })
        } finally {
            this.loading = false
        }
    },

    computed: {
        ...mapGetters({
            userIsOwner: 'UserStore/GetIsUserOwner'
        }),
        filteredFields () {
            return [
                {
                    key: 'name',
                    label: 'Name',
                    sortable: true
                },
                {
                    key: 'action',
                    label: ''
                }
            ]
        }
    },

    methods: {
        ...mapActions({
            setNotification: 'NotificationStore/SetNotification'
        }),

        cancel () {
            return this.$router.push({ name: 'ViewOrganisation', params: { id: this.$route.params.oId } })
        },

        selectFacility (id) {
            if (!this.form.facilityIds.includes(id)) {
                this.form.facilityIds.push(id)
            }
        },

        removeFacility (id) {
            if (this.form.facilityIds.includes(id)) {
                const index = this.form.facilityIds.indexOf(id)

                this.form.facilityIds.splice(index, 1)
            }
        },

        async create () {
            this.checkForm()

            if (Object.keys(this.errors).length === 1 && this.errors.facilities[0]) {
                setTimeout(() => {
                    // Give the HCP error alert time to show before scrolling
                    var scrollable = document.getElementsByTagName('main')[0]
                    scrollable.scroll(0, scrollable.scrollHeight)
                }, 100)
                document.activeElement.blur()
                return
            }

            if (!Object.keys(this.errors).length) {
                this.saving = true

                const practitionerModel = {
                    title: this.form.title,
                    firstName: this.form.firstName,
                    middleNames: this.form.middleNames,
                    surname: this.form.surname,
                    email: this.form.email,
                    speciality: this.form.speciality,
                    isActive: this.form.isActive,
                    facilityIds: this.form.facilityIds
                }

                try {
                    await axios.post(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/practitioners/${this.$route.params.oId}`, practitionerModel)

                    this.setNotification({
                        type: 'success',
                        title: 'Success',
                        message: 'The practitioner was added.'
                    })

                    return this.$router.push({ name: 'ViewOrganisation', params: { id: this.$route.params.oId } })
                } catch (err) {
                    this.setNotification({
                        type: 'error',
                        title: 'Error',
                        message: err.data.detail ?? 'Something went wrong. The practitioner was not added.'
                    })
                } finally {
                    this.saving = false
                }
            }
        },

        checkForm () {
            this.errors = {}

            if (this.form.title == null || parseInt(this.form.title) < 0) {
                this.errors.title = ['The title field is required']
            }

            if (!this.form.firstName) {
                this.errors.firstName = ['The first name field is required']
            }

            if (!this.form.surname) {
                this.errors.surname = ['The surname field is required']
            }

            if (!this.form.email) {
                this.errors.email = ['The user email field is required']
            }

            if (this.form.email != null && !this.form.email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                this.errors.email = ['This not a valid email address']
            }

            if (this.form.speciality == null || parseInt(this.form.speciality) < 0) {
                this.errors.speciality = ['The speciality field is required']
            }

            if (!this.form.facilityIds.length) {
                this.errors.facilities = ['You must select at least one facility']
            }
        }
    }
}
</script>
