export default {
    namespaced: true,

    state: {
        advancedSearchParams: {
            hasAdvancedSearchBeenExecuted: false,
            queryText: '',
            addressText: '',
            organisationId: '',
            facilityId: '',
            practitionerId: '',
            deviceNumber: null,
            orderNumber: null,
            testNumber: null,
            issueDateFrom: null,
            issueDateTo: null,
            returnDateFrom: null,
            returnDateTo: null,
            dueDateFrom: null,
            dueDateTo: null,
            issueMethod: '',
            issueReason: '',
            status: 'ALL_ACTIVE',
            testingFor: null,
            hasNotes: false,
            searchType: 'ACTIVE',
            archived: false,
            pageSize: process.env.VUE_APP_PAGINATION_PAGE_SIZE
        },
        results: {
            results: []
        }
    },

    getters: {
        GetResults (state) {
            return state.results
        },

        GetAdvancedSearchParams (state) {
            return state.advancedSearchParams
        },

        GetHasSearchBeenExecuted (state) {
            return state.searchHasBeenExecuted
        }
    },

    mutations: {
        CLEAR_SEARCH_STORE (state) {
            state.advancedSearchParams = {
                hasAdvancedSearchBeenExecuted: false,
                queryText: '',
                addressText: '',
                organisationId: '',
                facilityId: '',
                practitionerId: '',
                deviceNumber: null,
                orderNumber: null,
                testNumber: null,
                issueDateFrom: null,
                issueDateTo: null,
                returnDateFrom: null,
                returnDateTo: null,
                dueDateFrom: null,
                dueDateTo: null,
                issueMethod: '',
                issueReason: '',
                status: 'ALL_ACTIVE',
                testingFor: null,
                hasNotes: false,
                searchType: 'ACTIVE',
                archived: false,
                pageSize: process.env.VUE_APP_PAGINATION_PAGE_SIZE
            }

            state.results = {
                results: []
            }
        },

        PUSH_SEARCH_RESULTS (state, data) {
            state.results = data
        },

        SET_HAS_SEARCH_BEEN_EXECUTED (state, data) {
            state.searchParams.searchHasBeenExecuted = data
        },

        SET_HAS_ADVANCED_SEARCH_BEEN_EXECUTED (state, data) {
            state.advancedSearchParams.searchHasBeenExecuted = data
        },

        SET_CHOSEN_ORGANISATION_ID (state, data) {
            state.searchParams.chosenOrganisationId = data
        },

        SET_CHOSEN_FACILITY_ID (state, data) {
            state.searchParams.chosenFacilityId = data
        },

        SET_CHOSEN_PRACTITIONER_ID (state, data) {
            state.searchParams.chosenPractitionerId = data
        },

        CLEAR_CHOSEN_FACILITY_AND_CHOSEN_PRACITIONER (state) {
            state.searchParams.chosenFacilityId = ''
            state.searchParams.chosenPractitionerId = ''
        },

        CLEAR_ADVANCED_FACILITY_AND_ADVANCED_PRACITIONER (state) {
            state.advancedSearchParams.facilityId = ''
            state.advancedSearchParams.practitionerId = ''
        }
    },

    actions: {
        SetSearchResults ({ commit }, data) {
            commit('PUSH_SEARCH_RESULTS', data)
        },

        SetHasSearchBeenExecuted ({ commit }, data) {
            commit('SET_HAS_SEARCH_BEEN_EXECUTED', data)
        },

        SetHasAdvancedSearchBeenExecuted ({ commit }, data) {
            commit('SET_HAS_ADVANCED_SEARCH_BEEN_EXECUTED', data)
        },

        async SetChosenOrganisationId ({ commit }, data) {
            commit('SET_CHOSEN_ORGANISATION_ID', data)
        },

        async SetChosenFacilityId ({ commit }, data) {
            commit('SET_CHOSEN_FACILITY_ID', data)
        },

        async SetChosenPractitionerId ({ commit }, data) {
            commit('SET_CHOSEN_PRACTITIONER_ID', data)
        },

        async ClearChosenFacilityAndChosenPractitioner ({ commit }) {
            commit('CLEAR_CHOSEN_FACILITY_AND_CHOSEN_PRACITIONER')
        },

        async ClearAdvancedFacilityIdAndAdvancedPractitionerId ({ commit }) {
            commit('CLEAR_ADVANCED_FACILITY_AND_ADVANCED_PRACITIONER')
        },

        async ClearSearchStore ({ commit }) {
            commit('CLEAR_SEARCH_STORE')
        }
    }
}
