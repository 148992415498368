import axios from 'axios'
export default {
    namespaced: true,

    state: {
        domains: []
    },

    getters: {
        GetDomains (state) {
            return state.domains
        }
    },

    mutations: {
        PUSH_DOMAINS (state, data) {
            state.domains = data
        }
    },

    actions: {
        async PopulateDomains ({ commit, state }) {
            try {
                // Don't populate if already populated
                if (state.Domains) {
                    return state.Domains
                }

                const response = await axios.get(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/domains/me`)

                commit('PUSH_DOMAINS', response.data)

                return response.data
            } catch (err) {
                throw new Error(err)
            }
        }
    }
}
