<template>
    <div>
        <Loader v-if="loading" />

        <div v-if="!loading && Object.keys(hcp).length">
            <div class="row align-items-center">
                <div class="col-md-6">
                    <Breadcrumbs
                        :dynamicTitle="`${formatTitle(hcp.title)} ${hcp.firstName} ${hcp.surname}`"
                    />
                </div>

                <div class="col-md-6 text-right mt-2 mt-md-0">
                    <div class="d-flex flex-column flex-md-row justify-content-end">
                        <button @click="viewResults()" class="btn btn-yellow btn-lg lg-shadow pl-55 pr-55">View results</button>
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col">
                    <div class="card text-sm">
                        <div class="card-body">
                            <div class="d-flex flex-column flex-md-row justify-content-between">
                                <h5 class="mb-0">HCP details</h5>
                                <div>
                                    <button :disabled="loading" @click="setTraining()" class="btn btn-success sm-shadow mt-4 mt-md-0 px-4" :class="{disabled : loading}">Set Training</button>
                                    <router-link :to="{name: 'EditHcp', params: {id: $route.params.id}}" class="btn btn-primary sm-shadow mt-4 mt-md-0 px-4 ml-md-3">Edit</router-link>
                                </div>
                            </div>

                            <div class="row mt-4">
                                <div class="col-lg-6">
                                    <div class="row">
                                        <div class="col-sm-4 font-weight-medium">Name</div>
                                        <div class="col-sm-8">{{ `${formatTitle(hcp.title)} ${hcp.firstName} ${hcp.surname}` }}</div>
                                    </div>

                                    <hr/>

                                    <div v-if="hcp.phone">
                                        <div class="row">
                                            <div class="col-sm-4 font-weight-medium">Phone number</div>
                                            <div class="col-sm-8">{{ hcp.phone }}</div>
                                        </div>

                                        <hr/>
                                    </div>

                                    <div class="row">
                                        <div class="col-sm-4 font-weight-medium">Email</div>
                                        <div class="col-sm-8">{{ hcp.email }}</div>
                                    </div>

                                    <hr/>

                                    <div class="row">
                                        <div class="col-sm-4 font-weight-medium">Status</div>
                                        <div class="col-sm-8" :class="{'text-success' : hcp.isActive, 'text-danger' : !hcp.isActive}">{{ hcp.isActive ? "ACTIVE" : "INACTIVE" }}</div>
                                    </div>

                                    <hr/>

                                    <div class="row">
                                        <div class="col-sm-4 font-weight-medium">Speciality</div>
                                        <div class="col-sm-8">
                                            <span v-if="hcp.speciality == SpecialityType.OBSTETRICIAN">Obstetrician</span>
                                            <span v-if="hcp.speciality == SpecialityType.MIDWIFE">Midwife</span>
                                            <span v-if="hcp.speciality == SpecialityType.HCA">HCA</span>
                                            <span v-if="hcp.speciality == SpecialityType.NURSE">Nurse</span>
                                            <span v-if="hcp.speciality == SpecialityType.GP">GP</span>
                                            <span v-if="hcp.speciality == SpecialityType.SCC">SCC</span>
                                            <span v-if="hcp.speciality == SpecialityType.OTHER">Other</span>
                                        </div>
                                    </div>

                                    <hr/>

                                    <div class="row">
                                        <div class="col-sm-4 font-weight-medium">Notifications</div>
                                        <div class="col-sm-8" :class="{'text-success' : hcp.isNotificationManager, 'text-danger' : !hcp.isNotificationManager}">{{ hcp.isNotificationManager ? "YES" : "NO" }}</div>
                                    </div>

                                    <hr/>

                                    <div class="row">
                                        <div class="col-sm-4 font-weight-medium">Training Version</div>
                                        <div class="col-sm-8">{{ hcp.trainingVersionCompleted }}</div>
                                    </div>

                                    <hr/>

                                    <div class="row">
                                        <div class="col-sm-4 font-weight-medium">Training Completed Date</div>
                                        <div class="col-sm-8">{{ formatDateTime(hcp.trainingCompletedDateTime) }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import Loader from '@/partials/Loader'
import FormatterMixin from '@/mixins/FormatterMixin'
import Breadcrumbs from '@/partials/site-structure/BreadcrumbsComponent'
import SpecialityType from '@/enums/SpecialityType'
import TrainingVersion from '@/enums/TrainingVersion'
export default {
    name: 'ViewHCP',

    components: {
        Loader,
        Breadcrumbs
    },

    mixins: [
        FormatterMixin
    ],

    data () {
        return {
            loading: true,
            hcp: {},
            SpecialityType
        }
    },

    async mounted () {
        try {
            const response = await axios.get(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/practitioners/${this.$route.params.oId}/${this.$route.params.pId}`)

            this.hcp = response.data
        } catch {
            this.setNotification({
                type: 'error',
                title: 'Error',
                message: 'Something went wrong while fetching the practitioner. Please try again.'
            })
        } finally {
            this.loading = false
        }
    },

    computed: {
        ...mapGetters({
            userIsOwner: 'UserStore/GetIsUserOwner'
        })
    },

    methods: {
        ...mapActions({
            clearSearchStore: 'SearchStore/ClearSearchStore',
            setOrganisationIdInSearchStore: 'SearchStore/SetChosenOrganisationId',
            setFacilityIdInSearchStore: 'SearchStore/SetChosenFacilityId',
            setPractitionerIdInSearchStore: 'SearchStore/SetChosenPractitionerId',
            setNotification: 'NotificationStore/SetNotification'
        }),

        async viewResults () {
            await this.clearSearchStore()

            await this.setOrganisationIdInSearchStore(this.$route.params.oId)
            await this.setPractitionerIdInSearchStore(this.$route.params.pId)

            return this.$router.push({ name: 'Results' })
        },

        async setTraining () {
            this.loading = true
            try {
                const response = await axios.patch(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/practitioners/complete-training/${this.$route.params.oId}/${this.$route.params.pId}`, {
                    trainingVersion: TrainingVersion.CURRENT,
                    trainingCompletedDateTime: new Date().toISOString() // UTC
                })

                this.hcp = response.data
            } catch (err) {
                this.loading = false
                this.setNotification({
                    type: 'error',
                    title: 'Error',
                    message: 'Sorry, something went wrong. Please try again.'
                })
            } finally {
                this.loading = false
            }
        }
    }
}
</script>
