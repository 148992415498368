import axios from 'axios'
export default {
    namespaced: true,

    state: {
        languages: []
    },

    getters: {
        GetLanguages (state) {
            return state.languages
        }
    },

    mutations: {
        PUSH_LANGUAGES (state, data) {
            state.languages = data
        }
    },

    actions: {
        async PopulateLanguages ({ commit, state }) {
            try {
                // Don't populate if already populated
                if (state.languages && state.languages.length > 0) {
                    return state.languages
                }

                const response = await axios.get(`${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/languages`)

                commit('PUSH_LANGUAGES', response.data)

                return response.data
            } catch (err) {
                throw new Error(err)
            }
        }
    }
}
