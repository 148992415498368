<template>
    <div class="row mt-4">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <h5 class="mb-0">Notes</h5>

                    <div v-if="notes.length">
                        <div class="note mt-4 border border-danger" v-for="(note, index) in notes" :key="index">
                            <div class="note-header d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
                                <div><p class="mb-0 creator">{{ note.practitionerName }}</p></div>
                                <div><p class="mb-0 text-sm"><span class="font-weight-bold font-italic mr-4">Reported issue note</span> {{ note.dateCreated | moment("DD/MM/YYYY HH:mm")}}</p></div>
                            </div>

                            <div class="note-body text-sm">
                                {{ note.text }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OrderNotesComponent',

    props: {
        notes: Array
    }
}
</script>
