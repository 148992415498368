<template>
    <div>
        <p class="modal-heading">Accept status</p>
        <p class="text-sm">Please confirm that you would like to accept the status of this test</p>
        <div class="modal-status-warning">
            <img class="d-block" src="@/assets/images/icons/warning-icon.svg" width="20" alt="warning"><span>This test will stay as</span><testStatuses :status="test.inReviewStatus " />
        </div>

        <div class="d-flex align-items-center mt-45">
            <button :disabled="loading" @click="close()" class="btn btn-cancel btn-lg lg-shadow d-flex flex-grow-1 justify-content-center align-items-center" :class="{disabled : loading}">Cancel</button>
            <button :disabled="loading" @click="acceptTests()" class="btn btn-success btn-lg lg-shadow d-flex flex-grow-1 ml-3 justify-content-center align-items-center" :class="{disabled : loading}">Accept status <span v-if="loading" class="btn-loading"></span></button>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mapActions } from 'vuex'
import TestStatus from '@/enums/TestStatus'
import TestStatuses from '@/components/TestStatusesComponent.vue'
export default {
    name: 'AcceptTestStatusModalComponent',

    props: {
        test: {}
    },

    components: {
        TestStatuses
    },

    data () {
        return {
            loading: false,
            TestStatus
        }
    },

    methods: {
        ...mapActions({
            setNotification: 'NotificationStore/SetNotification',
            getStatusCountsAction: 'FulfilmentStatusCountsStore/GetStatusCounts'
        }),

        async acceptTests () {
            this.loading = true
            this.isBusy = true
            const postBody = [{
                    organisationId: this.test.organisationId,
                    id: this.test.id
            }]

            try {
                await axios.post(`${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/tests/AcceptTestsStatus`, postBody)

                this.setNotification({
                        type: 'success',
                        title: 'Success',
                        message: 'The test has been accepted'
                })
                await this.getStatusCountsAction()
            } catch (err) {
                this.setNotification({
                    type: 'error',
                    title: 'Error',
                    message: 'Something went wrong. Please try again'
                })
            } finally {
                this.loading = false
                this.isBusy = false
            }

            this.$emit('close')
        },
        close () {
            this.$emit('close')
        }
    }
}
</script>
