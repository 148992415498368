export default {
    namespaced: true,

    state: {
        shouldRefreshViewTestComponent: false,
        shouldRefreshOrder: false,
        verifyOrder: null
    },

    getters: {
        GetShouldRefreshOrder (state) {
            return state.shouldRefreshOrder
        },
        GetShouldRefreshViewTestComponent (state) {
            return state.shouldRefreshViewTestComponent
        },
        GetVerifyOrder (state) {
            return state.verifyOrder
        }
    },

    mutations: {
        SET_SHOULD_REFRESH_ORDER (state, data) {
            state.shouldRefreshOrder = data
        },
        SET_SHOULD_REFRESH_VIEW_TEST_COMPONENT (state, data) {
            state.shouldRefreshViewTestComponent = data
        },
        SET_VERIFY_ORDER (state, data) {
            state.verifyOrder = data
        }
    },

    actions: {
        SetShouldRefreshOrder ({ commit }, data) {
            commit('SET_SHOULD_REFRESH_ORDER', data)
        },
        SetShouldRefreshViewTestComponent ({ commit }, data) {
            commit('SET_SHOULD_REFRESH_VIEW_TEST_COMPONENT', data)
        },
        SetVerifyOrder ({ commit }, data) {
            commit('SET_VERIFY_ORDER', data)
        }
    }
}
