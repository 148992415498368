<template>
    <div class="notifications">
        <b-alert
            v-for="(notification, index) in notifications"
            :key="index"
            show
            dismissible
            fade
            class="alert mb-1 d-flex align-items-center justify-content-start"
            :class="{
                'notification-success' : notification.type == 'success',
                'alert-danger border border-danger' : notification.type == 'error'
            }"
        >
            <div>
                <img v-if="notification.type == 'success'" src="@/assets/images/icons/green-tick.svg" />
                <img v-if="notification.type == 'error'" src="@/assets/images/icons/error.svg" />
            </div>

            <div class="ml-4 mr-5">
                <p v-if="notification.title" class="notification-title">{{ notification.title }}</p>
                <p v-if="notification.message" class="notification-message">{{ notification.message }}</p>
            </div>

            <template v-slot:dismiss>
                <img width="18" src="@/assets/images/icons/close.svg" />
            </template>
        </b-alert>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'NotificationsComponent',

    computed: {
        ...mapGetters({
            notifications: 'NotificationStore/GetNotifications'
        })
    }
}
</script>
