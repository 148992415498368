<template>
    <div>
        <p class="modal-heading">Are you sure you want to change the test status to 'Returned'?</p>
        <p class="text-sm">This change cannot be reversed</p>

        <textarea class="form-control note mt-4" placeholder="Optionally add a note to the test" v-model="note"></textarea>

        <div class="d-flex align-items-center mt-45">
            <button :disabled="loading" @click="changeTestToReturned()" class="btn btn-success btn-lg lg-shadow d-flex flex-grow-1 w-50 justify-content-center align-items-center" :class="{disabled : loading}">Yes <span v-if="loading" class="btn-loading"></span></button>
            <button :disabled="loading" @click="close()" class="btn btn-danger btn-lg lg-shadow d-flex flex-grow-1 w-50 ml-3 justify-content-center align-items-center" :class="{disabled : loading}">No</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mapActions } from 'vuex'
export default {
    name: 'ChangeTestToReturnedModalComponent',

    props: {
        testExists: Boolean(false),
        organisationId: String,
        testId: String
    },

    data () {
        return {
            loading: false,
            note: null
        }
    },

    methods: {
        ...mapActions({
            setNotification: 'NotificationStore/SetNotification',
            getStatusCountsAction: 'FulfilmentStatusCountsStore/GetStatusCounts'
        }),

        async changeTestToReturned () {
            if (this.testExists) {
                this.loading = true

                try {
                    await axios.patch(`${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/tests/${this.organisationId}/${this.testId}/changetoreturned`, {
                        reason: this.note
                    })

                    this.setNotification({
                        type: 'success',
                        title: 'Success',
                        message: 'The test status has been changed to \'Returned\''
                    })
                    await this.getStatusCountsAction()
                } catch {
                    this.setNotification({
                        type: 'error',
                        title: 'Error',
                        message: 'Sorry, something went wrong when changing the test\'s status. Please try again'
                    })
                } finally {
                    this.loading = false
                }
            }

            this.$emit('close')
        },
        close () {
            this.$emit('close')
        }
    }
}
</script>
