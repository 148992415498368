<template>
    <div>
        <div class="row align-items-center">
            <div class="col-md-6">
                <h2 class="mb-0">Collection Points</h2>
            </div>

            <div class="col-md-6 text-right">
                <button @click="$router.push({name: 'CreateCollectionPoint'})" class="btn btn-success btn-block-mobile btn-lg lg-shadow mt-4 mt-md-0">Add a collection point</button>
            </div>
        </div>

        <Loader v-if="loading" />

        <div v-else class="mt-45">
            <div class="row justify-content-between align-items-center">
                <div class="col">
                    <h5 class="mb-0">All <span class="font-italic">({{ results.totalCount }})</span></h5>
                </div>

                <div class="col-md-4 text-right mt-2 mt-md-0">
                    <div class="d-flex flex-column flex-md-row">
                        <input class="form-control searchbar-input" type="text" placeholder="Search for collection point" v-model="query" />
                        <button onclick="this.blur();" :disabled="isBusy" @click="search()" class="btn btn-success btn-form sm-shadow ml-0 ml-md-2 my-2 my-md-0" :class="{disabled : isBusy}">Search</button>
                        <button onclick="this.blur();" @click="resetSearch()" class="btn btn-yellow btn-form sm-shadow ml-0 ml-md-2" :class="{disabled : isBusy}">Reset</button>
                    </div>
                </div>
            </div>

            <b-table
                v-if="results.results.length"
                ref="table"
                id="table"
                :sort-by.sync="sortBySync"
                :sort-desc.sync="sortDesc"
                @sort-changed="sortingChanged"
                :no-local-sorting="true"
                :busy.sync="isBusy"
                class="custom-table bg-white mt-4"
                responsive
                no-sort-reset
                :items="results.results"
                :fields="filteredFields"
            >
                <template #cell(address)="data">
                    <span v-if="data.item.address1">{{ `${data.item.address1}, ` }}</span>
                    <span v-if="data.item.address2">{{ `${data.item.address2}, ` }}</span>
                    <span v-if="data.item.address3">{{ `${data.item.address3}, ` }}</span>
                    <span v-if="data.item.address4">{{ `${data.item.address4}, ` }}</span>
                    <span v-if="data.item.postalCode" class="text-uppercase">{{ `${data.item.postalCode}, ` }}</span>
                    <span v-if="data.item.country">{{ `${data.item.country}` }}</span>
                </template>

                <template #cell(type)="data">
                    <span v-if="data.item.type == CollectionPointType.PHARMACY">Pharmacy</span>
                    <span v-if="data.item.type == CollectionPointType.PLACE_OF_WORSHIP">Place of worship</span>
                    <span v-if="data.item.type == CollectionPointType.RETAIL">Retail</span>
                    <span v-if="data.item.type == CollectionPointType.COMMUNITY_CENTRE">Community centre</span>
                </template>

                <template #cell(contact)="data">
                    {{ data.item.firstName + ' ' + data.item.surname }}
                </template>

                <template #cell(isActive)="data">
                    <span :class="{'text-success' : data.item.isActive, 'text-danger' : !data.item.isActive}">{{ data.item.isActive ? 'ACTIVE' : 'INACTIVE' }}</span>
                </template>

                <template #cell(actions)="data">
                    <a target="_blank" :href="`https://maps.google.com/?q=${encodedAddress(data.item)}`" class="btn btn-yellow btn-view-map sm-shadow text-nowrap">View map</a>
                    <router-link :to="{name: 'EditCollectionPoint', params: {id : data.item.id}}" class="btn btn-primary px-4 sm-shadow text-nowrap ml-3">Edit</router-link>
                </template>
            </b-table>

            <div class="mt-4">
                <b-pagination
                    v-if="results.results.length"
                    v-model="currentPage"
                    :current-page="currentPage"
                    :per-page="results.pageSize"
                    :total-rows="results.totalCount"
                    prev-text="< Previous"
                    next-text="Next >"
                    @change="pageChange"
                    aria-controls="tests-table"
                    hide-goto-end-buttons
                    prev-class="prev-page"
                    next-class="next-page"
                    align="right"
                ></b-pagination>
            </div>
        </div>

        <div v-if="!loading && !results.results.length">
            <div class="row mt-5">
                <div class="col-md-6">
                    <div class="alert alert-info">
                        There are no collection points to show
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import Loader from '@/partials/Loader.vue'
import FormatterMixin from '@/mixins/FormatterMixin'
import CollectionPointType from '@/enums/CollectionPointType'
export default {
    name: 'CollectionPoints',

    components: {
        Loader
    },

    mixins: [
        FormatterMixin
    ],

    data () {
        return {
            loading: true,
            query: '',
            results: {},
            isBusy: false,
            currentPage: 1,
            pageSize: process.env.VUE_APP_PAGINATION_PAGE_SIZE,
            offset: 0,
            sortColumn: 'NAME',
            sortType: 'ASCENDING',
            sortDesc: false,
            sortBySync: 'name',
            CollectionPointType
        }
    },

    async mounted () {
        await this.getCollectionPoints()
    },

    computed: {
        ...mapGetters({
            userIsOwner: 'UserStore/GetIsUserOwner'
        }),

        resultsLengthComputed () {
            if (this.results && this.results.results && this.results.results.length) {
                return this.results.results.length
            }

            return 0
        },

        filteredFields () {
            return [
                {
                    key: 'name',
                    label: 'Name',
                    sortable: true
                },
                {
                    key: 'type',
                    label: 'Type',
                    sortable: true
                },
                {
                    key: 'address',
                    label: 'Address'
                },
                {
                    key: 'contact',
                    label: 'Primary Contact'
                },
                {
                    key: 'phone',
                    label: 'Phone No'
                },
                {
                    key: 'isActive',
                    label: 'Status',
                    sortable: true
                },
                {
                    key: 'actions',
                    label: ''
                }
            ]
        }
    },

    methods: {
        ...mapActions({
            setNotification: 'NotificationStore/SetNotification'
        }),

        async getCollectionPoints () {
            this.isBusy = true

            try {
                var response
                if (this.query) {
                    response = await axios.get(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/collectionpoints/${this.query}/${this.pageSize}/${this.offset}/${this.sortColumn}/${this.sortType}`)
                } else {
                    response = await axios.get(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/collectionpoints/${this.pageSize}/${this.offset}/?sortColumn=${this.sortColumn}&sortType=${this.sortType}`)
                }

                this.results = response.data
            } catch {
                this.setNotification({
                    type: 'error',
                    title: 'Error',
                    message: 'Something went wrong while fetching the collection points. Please try again.'
                })
            } finally {
                this.loading = false
                this.isBusy = false
            }
        },

        encodedAddress (data) {
            return encodeURIComponent(this.formatAddress(data, true))
        },

        async resetSearch () {
            this.query = ''
            await this.search()
        },

        async search () {
            this.currentPage = 1
            this.offset = 0
            await this.getCollectionPoints()
        },

        pageChange (value) {
            this.currentPage = value
            this.offset = (value - 1) * this.pageSize
            this.getCollectionPoints()
        },

        sortingChanged (e) {
            switch (e.sortBy) {
                case 'name':
                    this.sortColumn = 'NAME'
                break

                case 'type':
                    this.sortColumn = 'TYPE'
                break

                case 'isActive':
                    this.sortColumn = 'STATUS'
                break
            }

            this.sortDesc = e.sortDesc
            this.sortBySync = e.sortBy

            if (this.sortDesc) {
                this.sortType = 'DESCENDING'
            } else {
                this.sortType = 'ASCENDING'
            }

            this.getCollectionPoints()
        }
    }
}
</script>
